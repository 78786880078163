import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import Logo from '../assets/logomark.svg'

const navStyles = {
	height: '64px',
	boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
}

export default function NavigationBar({ isAuthenticated }) {
	const { user, logout } = useAuth0()

	let executiveName = user?.name || 'Executive Name'

	const handleLogout = () => {
		logout({
			returnTo: `${window.location.origin}/logout`,
		})
	}

	return (
		<Navbar className="sticky-top" bg="white" expand="lg" style={navStyles}>
			<Container fluid id="navbarContainer">
				<LogoBox className="d-flex justify-content-center align-items-center">
					<img src={Logo} alt="Logo" />
				</LogoBox>
				<BrandHeading>VERAK OPERATIONS</BrandHeading>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />

				{isAuthenticated && (
					<Navbar.Collapse id="basic-navbar-nav">
						<Navbar.Collapse className="w-100 d-flex justify-content-end align-items-center">
							<NavDropdown
								style={{ color: '#03045E !important', fontSize: '14px', fontWeight: '400' }}
								title={executiveName}
								id="basic-nav-dropdown"
							>
								<NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
							</NavDropdown>
						</Navbar.Collapse>
					</Navbar.Collapse>
				)}
			</Container>
		</Navbar>
	)
}

const BrandHeading = styled(Navbar.Brand)`
	color: #03045e !important;
	font-size: 14px;
	font-weight: 400;
	padding-left: 2rem;
`

const LogoBox = styled.div`
	width: 80px;
	height: 64px;
	background: #03045e;
`

const ADD_GROUP = 'ADD_GROUP'
const ADD_RFQS = 'ADD_RFQS'
const APPEND_RFQS = 'APPEND_RFQS'

export const reducer = (state, action) => {
	switch (action.type) {
		case ADD_RFQS:
			return {
				...state,
				rfqList: [...action.data],
			}
		case APPEND_RFQS:
			const newRfqList = [...state.rfqList, ...action.data]
			const uniqueRfqs = newRfqList
				.map(rfq => rfq.id)
				.filter((id, index, self) => self.indexOf(id) === index)
				.map(id => newRfqList.find(rfq => rfq.id === id))
			return {
				...state,
				rfqList: uniqueRfqs,
			}

		case 'SET_CURRENT_QUOTE_ITEM':
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					currentQuoteItem: action.data,
				},
			}
		case 'RESET_CURRENT_RFQ_ITEM':
			return {
				...state,
				currentRfqItem: {
					quoteList: [],
					actionList: [],
					currentQuoteItem: null,
				},
			}

		case 'CLEAR_CURRENT_QUOTE_ITEM':
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					currentQuoteItem: null,
				},
			}
		case 'COMPLETE_ACTION_ITEM':
			let allActions = [...state.currentRfqItem.actionList]
			let targetItem = allActions.find(item => item.id === action.data.actionId)
			targetItem.completed = true
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					actionList: [...allActions],
				},
			}
		case 'SET_ACTION_LIST':
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					actionList: action.data,
				},
			}

		case 'SELECT_RFQ':
			return {
				...state,
				currentRfqItem: {
					...action.data,
					quoteList: null,
				},
			}
			break
		case 'SET_RFQ_QUOTES':
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					quoteList: [...action.data],
				},
			}
			break
		case 'SET_RFQ_SIBREAKDOWN': {
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					SumInsuredBreakdown: [...action.data],
				},
			}
		}
		case 'SET_CUSTOMER_INFO': {
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					CustomerInfo: { ...action.data },
				},
			}
		}
		case 'SET_RFQ_ITEM':
			return {
				...state,
				currentRfqItem: {
					...state?.currentRfqItem,
					...action.data,
				},
			}
		case 'UPDATE_RFQ_FROM_LIST': {
			let rfqList = [...state.rfqList]
			let targetRfqIndex = rfqList.findIndex(rfq => rfq.id === action.data.id)
			rfqList[targetRfqIndex] = { ...rfqList[targetRfqIndex], ...action.data }
			return {
				...state,
				rfqList: [...rfqList],
			}
		}
		case 'UPDATE_RFQ_STATUS':
			{
				const toUpdateRfqId = action.data.id
				const updatedStatus = action.data.Status
				const rfqList = [...state.rfqList]
				const toUpdateIndex = rfqList.findIndex(item => {
					return item.id == toUpdateRfqId
				})

				if (toUpdateIndex > -1) {
					rfqList[toUpdateIndex].Status = updatedStatus
				}

				return {
					...state,
					rfqList: rfqList,
					currentRfqItem: {
						...state.currentRfqItem,
						Status: updatedStatus,
					},
				}
			}

			break
		case 'ADD_NEW_QUOTE':
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					quoteList: [...state.currentRfqItem.quoteList, action.data],
				},
			}
			break
		case 'UPDATE_QUOTE': {
			const updatedQuoted = action.data
			const quoteList = [...state.currentRfqItem.quoteList]

			const toUpdateIndex = quoteList.findIndex(item => {
				return item.id == updatedQuoted.id
			})

			quoteList[toUpdateIndex] = updatedQuoted

			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					quoteList: quoteList,
				},
			}
		}

		case 'CLEAR_RFQS': {
			return {
				...state,
				rfqList: [],
			}
		}

		default:
			return state
	}
}

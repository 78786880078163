import { useHistory, useLocation } from 'react-router'
import QuoteUpdateComponent from '../CommonScreenComponents/QuoteUpdateComponent'
import { TopNav } from '../../components/SharedContainers'
import { useContextApi } from '../../context/Provider'

import { policyTypeStrings } from '../../utils/getPolicyType'

import BackArrow from '../../assets/icons/BackArrow.svg'

const AddQuoteInfo = ({ onNavigate, quote }) => {
	const history = useHistory()
	const [state, dispatch] = useContextApi()
	const currentRfqItem = state?.currentRfqItem
	const policyType = policyTypeStrings(currentRfqItem?.Tag)

	let quoteUpdateCompTitle = ''
	if (Object.keys(quote)?.length) {
		quoteUpdateCompTitle = 'Update quote information'
	} else {
		quoteUpdateCompTitle = 'Add a new quote'
	}

	const goBack = () => history.goBack()

	return (
		<>
			{/* temporary navbar */}
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<div
					className="mx-3"
					style={{ fontSize: '18px', lineHeight: '23px', color: '#02475E', fontWeight: '700' }}
				>
					{policyType}, {currentRfqItem?.id} - {currentRfqItem?.Name}
				</div>
			</TopNav>
			<QuoteUpdateComponent title={quoteUpdateCompTitle} data={quote} onNavigate={onNavigate} />
		</>
	)
}

export default AddQuoteInfo

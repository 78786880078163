import { useAuth0 } from '@auth0/auth0-react'
import { Container, Button } from 'react-bootstrap'
import OpsHome from './OpsHome/Home'
import { useHistory } from 'react-router-dom'
import { Redirect } from 'react-router'
const HomeScreen = () => {
	const { isLoading, isAuthenticated, loginWithPopup } = useAuth0()
	const history = useHistory()
	if (isLoading) {
		return <div className="d-flex flex-column align-items-center m-3">Loading...</div>
	}

	if (!isAuthenticated) {
		return (
			<Container className="d-flex align-items-center justify-content-center m-3">
				<Button onClick={loginWithPopup}>Login to continue</Button>
			</Container>
		)
	} else {
		if (history.location.pathname == '/logout') {
			return <Redirect to="/" />
		}
		return <OpsHome />
	}
}

export default HomeScreen

import styled from 'styled-components'

import { useContextApi } from '../context/Provider'
import profilePic from '../assets/icons/profilePic.svg'
import Home from '../assets/icons/Home.svg'
import addLead from '../assets/icons/addLead.svg'
import { useHistory } from 'react-router-dom'

export default function SidePanel() {
	const [state, dispatch] = useContextApi()
	const history = useHistory()
	const navigateToHome = () => {
		history.replace('/')
	}
	const navigateToLeadTransfer = () => {
		history.push('/lead-transfer')
	}
	const navigateToDataPanel = () => {
		history.push('/data-center')
	}

	return (
		<SidePanelContainer className="d-flex flex-column justify-content-end">
			<Section $bottomborder={true} onClick={navigateToHome}>
				<img className="mb-2" src={Home} alt="Home" />
				<p>home</p>
			</Section>
			<Section $bottomborder={true} onClick={navigateToLeadTransfer}>
				<img className="mb-2" src={addLead} alt="lead" />
				<p>Add Lead</p>
			</Section>
			<Section $bottomborder={true} onClick={navigateToDataPanel}>
				<img className="mb-2" src={addLead} alt="lead" />
				<p>Data</p>
			</Section>
			{state.currentRfqItem?.InitiatedBy && (
				<Section>
					<img className="mb-2" src={profilePic} alt="profilePic" />
					<p>OPS MANAGER</p>
					<p>{state.currentRfqItem.InitiatedBy}</p>
				</Section>
			)}
			<div className="flex-fill" />
			{state.currentRfqItem?.Agent && (
				<Section>
					<img className="mb-2" src={profilePic} alt="profilePic" />
					<p>RFQ BY</p>
					<p>{state.currentRfqItem.Agent.name}</p>
				</Section>
			)}
		</SidePanelContainer>
	)
}

const Section = styled.div`
	padding: 10px 0;
	width: 80px;
	min-height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	border-bottom: ${props => (props.$bottomborder ? 'solid 1px #c4c4c4' : '')};
	p {
		text-align: center;
		padding: 0;
		font-size: 12px;
		font-weight: 700;
		color: #c4c4c4;
		margin-bottom: 0.5rem;
		text-transform: uppercase;
	}
	p:last-child {
		margin-bottom: 0;
	}
`

const SidePanelContainer = styled.div`
	position: fixed;
	left: 0;
	top: 64px;
	width: 80px;
	height: calc(100% - 64px);
	background-color: #fff;
	box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.05);
`

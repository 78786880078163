import {
	Alert,
	Container,
	Row,
	Button,
	FormCheck,
	FormSelect,
	Col,
	FormControl,
} from 'react-bootstrap'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

import WarningExclamation from '../../assets/icons/warningExclamation.svg'
import DownloadSm from '../../assets/icons/downloadSm.svg'
import Checkmark from '../../assets/icons/checkmark.svg'
import { useFetch } from '../../api/apihooks'
import { useContextApi } from '../../context/Provider'
import { useLoadingOverlay } from '../../context/LoadingErrorProvider'
import Swal from 'sweetalert2'
import theme from '../../components/core/theme'
import Text from '../../components/core/Text'
import calculateTotalPremium from '../../utils/calculateTotalPremium'
import { Icon } from 'verak-ui'
import roundOffNum from '../../utils/roundOffNum'

const insurerNames = [
	'Reliance General Insurance',
	'Liberty General Insurance',
	'Oriental Insurance',
	'Digit Insurance',
	'ICICI Lombard General Insurance',
	'Bajaj Allianz General Insurance',
	'Royal Sundaram General Insurance',
	'United India Insurance',
	'Tata AIG General Insurance',
	'National Insurance',
	'The New India Assurance Co. Ltd.',
	'Verak (Test Insurer)',
	'Kotak General Insurance',
	'Chola MS General Insurance',
	'SBI General Insurance',
]

const addOnOptionValue = [
	'Burglary',
	'BURGLARY_THEFT',
	'CIS',
	'CIC',
	'CIT',
	'Plate Glass',
	'Neon glass / sign board',
	'Machinery breakdown & Damage',
	'Fire loss of profit',
	'Machinery loss of profit',
	'Money Insurance',
	'Money Insurance - Cash in Safe',
	'Money Insurance - Cash in Transit',
	'Electronic Equipment Insurance',
	'Fidelity & Larceny',
	'Other',
]

const integratedProducts = ['CCTV_01', 'MEMORY_01']
const feeTypes = ['Convenience', 'Courier']
const editableSafetyPlanDetails = ['Convenience']

const inputTypes = ['TEXT', 'IMAGE', 'VIDEO']

const inputTypesOptions = [
	{ value: 'TEXT', label: 'Free text response' },
	{ value: 'IMAGE', label: 'Image upload' },
	{ value: 'VIDEO', label: 'Video upload' },
]

const InfoCard = ({ children }) => {
	return <div className="bg-white shadow rounded p-3 my-3">{children}</div>
}

const InfoRow = ({ title, rightContent = null, bottomBorder = false, children }) => {
	let renderBottomBorder = null
	if (bottomBorder) {
		renderBottomBorder = <hr className="border-0 border-bottom border-dark my-3" />
	}

	let renderTitle = null
	if (title) {
		renderTitle = (
			<h5 className="mb-3" style={{ fontSize: '18px' }}>
				{title}
			</h5>
		)
	}

	return (
		<Row>
			<div className="d-flex justify-content-between">
				{renderTitle}
				{rightContent}
			</div>

			{children}
			{renderBottomBorder}
		</Row>
	)
}

const AlertComponent = ({ variant, alertStyles = {}, children }) => {
	return (
		<Alert className="p-2 rounded" variant={variant} style={alertStyles}>
			<div className="d-flex">
				<img
					style={{ alignSelf: 'start' }}
					src={WarningExclamation}
					alt="warning"
					className="me-2"
				/>
				{children}
			</div>
		</Alert>
	)
}

const RupeeInput = props => {
	const rupeeSymbol = <RupeeSymbol>₹</RupeeSymbol>
	return (
		<div className="d-flex align-items-center">
			{rupeeSymbol}
			<FormControl {...props} />
		</div>
	)
}

const getPolicyTypeUI = policyType => {
	if (policyType === 'FIRE_ALL_RISK') {
		return (
			<PolicyTypeContainer>
				<PolicyName>Fire All Risk</PolicyName>
				<ul style={{ marginBottom: '0px', fontWeight: 400 }}>
					<li>Fire</li>
					<li>Burglary & Theft</li>
				</ul>
			</PolicyTypeContainer>
		)
	} else {
		return (
			<PolicyTypeContainer>
				<PolicyName>Fire & Allied Perils</PolicyName>
			</PolicyTypeContainer>
		)
	}
}

const QuoteUpdateComponent = ({
	title,
	submitBtnLink,
	data,
	quoteActionType,
	onNavigate,
	submitQuote,
}) => {
	const history = useHistory()
	const [state, dispatch] = useContextApi()
	const currentRfqItem = state?.currentRfqItem
	const quoteList = state?.currentRfqItem?.quoteList

	const [insurer, setInsurer] = useState(data?.Insurer || '')
	const [policyType, setPolicyType] = useState(currentRfqItem?.Tag || 'FIRE')
	const policyTypeUI = getPolicyTypeUI(currentRfqItem?.Tag)

	const isNewQuote = data?._id ? false : true

	const [proposalForm, setProposalForm] = useState(!!data.ReceivedProposalForm)

	const [discrepancies, setDiscrepancies] = useState(() => {
		if (typeof data?.Discrepancy === 'boolean') {
			return data.Discrepancy
		}
		return ''
	})

	const [rfqStatus, setRfqStatus] = useState(data?.Status || '')

	const { createQuote, updateQuote, downloadRFQArchive, updateRFQStatus } = useFetch()

	const { loading, setLoading } = useLoadingOverlay()

	const [premiumBreakdown, setPremiumBreakdown] = useState(
		data?.PremiumBreakdown || {
			Available: null, // either true, false or null
			TotalPremium: '',
			BasePremium: '',
			IGST: '',
		}
	)

	const [addonsPresent, setAddonsPresent] = useState(() => {
		if (data?.Addons) {
			return data?.Addons?.length > 0
		}
		return null
	})

	const [addons, setAddons] = useState(() => {
		if (data?.Addons?.length) {
			return data.Addons.map(data => {
				const isOther = addOnOptionValue.indexOf(data.Name) == -1
				return {
					...data,
					type: isOther ? 'Other' : 'Predefined',
				}
			})
		} else {
			return [{ Name: '', Amount: '', type: 'Predefined' }]
		}
	})

	const [integratedCosts, setIntegratedCosts] = useState(() => {
		if (data?.IntegratedCosts?.length) {
			return data.IntegratedCosts
		} else {
			return [{ Key: '', Type: '', Amount: '' }]
		}
	})

	const [fees, setFees] = useState(() => {
		if (data?.Fees?.length) {
			return data.Fees
		} else {
			return [{ Type: '', Amount: '' }]
		}
	})

	const [discountedQuotePresent, setDiscountedQuote] = useState(data?.isPremiumDiscounted)
	const [budgetedQuotePresent, setBudgetQuote] = useState(data?.isPremiumWithinBudget)

	const [insurerQuoteId, setInsurerQuoteId] = useState(data?.ExternalId || '')

	const [reducedSumInsuredPresent, setReducedSumInsuredPresent] = useState(() => {
		if (typeof data?.ReducedSumInsured === 'number') {
			return data?.ReducedSumInsured > 0
		}
		return null
	})
	const [reducedSumInsuredData, setReducedSumInsuredData] = useState(data?.ReducedSumInsured || '')

	const [termsAndConditionsPresent, setTermsAndConditionsPresent] = useState(() => {
		if (typeof data?.TermsAndConditions === 'string') {
			return data?.TermsAndConditions?.length > 0
		}
		return null
	})

	const [termsAndConditions, setTermsAndConditions] = useState(data?.TermsAndConditions || '')

	const [quoteExpiry, setQuoteExpiry] = useState(data?.Expiry || '')

	const [reasonForRejection, setReasonForRejection] = useState(data?.RejectionReason || '')

	const [furtherRequirements, setFurtherRequirements] = useState(() => {
		if (data?.FurtherRequirements?.length) {
			return data.FurtherRequirements
		}
		return [{ Question: '', AnswerType: '' }]
	})

	const proceedDisabled = !insurer || proposalForm === '' || !rfqStatus

	const handlePremiumsDataChange = (key, value) => {
		let updatedValue = value
		if (key !== 'Available' && isNaN(value)) {
			updatedValue = ''
		}
		setPremiumBreakdown(prev => {
			const updatedPremiumBreakdown = {
				...prev,
				[key]: updatedValue,
			}
			if (key === 'BasePremium') {
				updatedPremiumBreakdown.IGST = roundOffNum(Number(updatedValue) * 0.18)
			}
			if (key === 'TotalPremium') {
				const igst = roundOffNum(Number(updatedValue) * 0.18)
				updatedPremiumBreakdown.BasePremium = updatedValue - igst
				updatedPremiumBreakdown.IGST = igst
			}
			return updatedPremiumBreakdown
		})
	}

	const calculateBurglarySI = () => {
		const burglarySiItems = [
			'Stock',
			'PlantMachinery',
			'Electronics',
			'FurnitureFixture',
			'OfficeEquipment',
			'ValuableContents',
			'Other',
		]

		let burglarySumInsured = 0

		currentRfqItem?.SumInsuredBreakdown.forEach(({ Name, Amount }) => {
			// burglary sum insured
			if (burglarySiItems.includes(Name)) {
				burglarySumInsured += Amount || 0
			}
		})

		return burglarySumInsured
	}

	const handleAddonsListNameDropdownChange = (value, index, addonItem) => {
		let alteredAddonsList = [...addons] || []
		if (value == 'Other') {
			alteredAddonsList[index]['Name'] = ''
			alteredAddonsList[index]['type'] = 'Other'
		} else {
			alteredAddonsList[index]['Name'] = value
			alteredAddonsList[index]['type'] = 'Predefined'

			// prefill sum insured for Burglary addons
			const isBurglaryAddon = value === 'Burglary' || value === 'BURGLARY_THEFT'
			if (isBurglaryAddon) {
				alteredAddonsList[index]['SumInsured'] = calculateBurglarySI()
			} else {
				alteredAddonsList[index]['SumInsured'] = 0
			}

			alteredAddonsList[index]['Amount'] = 0
			alteredAddonsList[index]['BaseAmount'] = 0
			alteredAddonsList[index]['IGST'] = 0
		}
		setAddons(alteredAddonsList)
	}

	const handleAddonsListNameInputChange = (value, index) => {
		let alteredAddonsList = [...addons] || []
		alteredAddonsList[index]['Name'] = value
		setAddons(alteredAddonsList)
	}

	const handleAddonsListDataChange = (key, value, index, type, addonName) => {
		let updatedValue = value
		if (isNaN(value)) {
			updatedValue = ''
		}

		let alteredAddonsList = [...addons] || []
		if (key === 'BaseAmount') {
			alteredAddonsList[index]['IGST'] = roundOffNum(Number(updatedValue) * 0.18)
			alteredAddonsList[index]['BaseAmount'] = updatedValue
		} else {
			alteredAddonsList[index][key] = updatedValue
		}

		if (key === 'BaseAmount' || key === 'IGST') {
			alteredAddonsList[index]['Amount'] =
				alteredAddonsList[index]['BaseAmount'] + alteredAddonsList[index]['IGST'] || 0
		}
		setAddons(alteredAddonsList)
	}

	const handleOtherInfoRequiredChange = (index, key, value) => {
		let alteredOtherInfo = [...furtherRequirements]
		alteredOtherInfo[index][key] = value

		setFurtherRequirements(alteredOtherInfo)
	}

	const handleReducedSumInsuredChange = value => {
		let updatedValue = value
		if (isNaN(updatedValue)) {
			updatedValue = ''
		}
		setReducedSumInsuredData(updatedValue)
	}

	const handleQuoteExpiryChange = value => {
		let updatedValue = value
		if (isNaN(updatedValue)) {
			updatedValue = ''
		}
		setQuoteExpiry(updatedValue)
	}

	const addNewAddon = () => {
		setAddons(prev => [...prev, { Name: '', Amount: '', IGST: '', BaseAmount: '' }])
	}

	const removeAddon = index => {
		let alteredAddonsList = [...addons]
		alteredAddonsList.splice(index, 1)
		setAddons(alteredAddonsList)
	}

	const addNewRequirement = () => {
		setFurtherRequirements(prev => [...prev, { Question: '', AnswerType: '' }])
	}

	const removeRequirement = index => {
		let alteredFurtherRequirements = [...furtherRequirements]
		alteredFurtherRequirements.splice(index, 1)
		setFurtherRequirements(alteredFurtherRequirements)
	}

	const checkIfSubmitBtnDisabled = () => {
		const disabledDueToCompulsoryQuestions = !insurer || proposalForm === '' || !rfqStatus

		// check based on the status -> [ACCEPTED, REJECTED, FURTHER_REQUIREMENTS]
		let disabledOnStatusLevel = false

		if (rfqStatus === 'ACCEPTED') {
			// check for premium breakdown
			let disabledDueToPremiumBreakdown = false
			// initially when the quote is created, the premium breakdown available is null
			if (premiumBreakdown.Available === null) {
				disabledDueToPremiumBreakdown = true
			} else if (premiumBreakdown.Available) {
				disabledDueToPremiumBreakdown = premiumBreakdown.BasePremium === ''
			} else {
				disabledDueToPremiumBreakdown = premiumBreakdown.TotalPremium === ''
			}

			// check for addons
			let disabledDueToAddons = false
			// initially when the quote is created, the addons present is null
			if (addonsPresent === null) {
				disabledDueToAddons = true
			} else if (addonsPresent) {
				addons.forEach(addonItem => {
					// if premium breakdown is not available, addon amount isn't compulsory
					if (premiumBreakdown.Available === false && !addonItem.Name) {
						disabledDueToAddons = true
					} else if (premiumBreakdown.Available && (!addonItem.Name || addonItem.Amount === '')) {
						disabledDueToAddons = true
					}
				})
			}

			// check for reduced sum insured
			let disabledDueToReducedSumInsured = false
			// initially when the quote is created, the reduced sum insured present is null
			if (reducedSumInsuredPresent === null) {
				disabledDueToReducedSumInsured = true
			} else if (reducedSumInsuredPresent) {
				disabledDueToReducedSumInsured = reducedSumInsuredData === ''
			}

			// check for terms and conditions [special warranties]
			let disabledDueToTermsAndConditions = false
			// initially when the quote is created, the terms and conditions present is null
			if (termsAndConditionsPresent === null) {
				disabledDueToTermsAndConditions = true
			} else if (termsAndConditionsPresent) {
				disabledDueToTermsAndConditions = !termsAndConditions
			}

			disabledOnStatusLevel =
				disabledDueToPremiumBreakdown ||
				disabledDueToAddons ||
				disabledDueToReducedSumInsured ||
				disabledDueToTermsAndConditions
		} else if (rfqStatus === 'REJECTED') {
			// check for rejection of reason
			let disabledDueToRejectionReason = !reasonForRejection

			disabledOnStatusLevel = disabledDueToRejectionReason
		} else if (rfqStatus === 'FURTHER_REQUIREMENTS') {
			// check for terms and conditions [special warranties]
			let disabledDueToTermsAndConditions = false
			// initially when the quote is created, the terms and conditions present is null
			if (termsAndConditionsPresent === null) {
				disabledDueToTermsAndConditions = true
			} else if (termsAndConditionsPresent) {
				disabledDueToTermsAndConditions = !termsAndConditions
			}

			// check for addons
			let disabledDueToAddons = false
			// initially when the quote is created, the addons present is null
			if (addonsPresent === null) {
				disabledDueToAddons = true
			} else if (addonsPresent) {
				addons.forEach(addonItem => {
					if (!addonItem.Name || addonItem.Amount === '') {
						disabledDueToAddons = true
					}
				})
				if (!addons?.length) {
					disabledDueToAddons = true
				}
			}

			// check for further requirements
			let disabledDueToFurtherRequirements = false

			furtherRequirements.forEach(requirementItem => {
				if (!requirementItem.Question || !requirementItem.AnswerType) {
					disabledDueToFurtherRequirements = true
				}
			})
			if (!furtherRequirements?.length) {
				disabledDueToFurtherRequirements = true
			}

			disabledOnStatusLevel =
				disabledDueToTermsAndConditions || disabledDueToAddons || disabledDueToFurtherRequirements
		}

		return disabledDueToCompulsoryQuestions || disabledOnStatusLevel
	}

	const submitDisabled = checkIfSubmitBtnDisabled()

	const generateQuoteObject = () => {
		const requiredValues = {
			Insurer: insurer,
			PolicyType: policyType,
			ReceivedProposalForm: proposalForm,
			Status: rfqStatus,
		}

		const optionalValues = {}

		if (typeof discrepancies === 'boolean' && proposalForm) {
			optionalValues.Discrepancy = discrepancies
		}

		if (typeof discountedQuotePresent === 'boolean') {
			optionalValues.isPremiumDiscounted = discountedQuotePresent
			if (typeof budgetedQuotePresent === 'boolean' && discountedQuotePresent) {
				optionalValues.isPremiumWithinBudget = budgetedQuotePresent
			} else {
				optionalValues.isPremiumWithinBudget = false
			}
		}

		if (rfqStatus === 'ACCEPTED' || rfqStatus === 'FURTHER_REQUIREMENTS') {
			// add fields common between both the statuses here...

			// add fields from terms and conditions
			if (typeof termsAndConditionsPresent === 'boolean') {
				if (termsAndConditionsPresent) {
					optionalValues.TermsAndConditions = termsAndConditions
				} else {
					optionalValues.TermsAndConditions = ''
				}
			}

			// add fields from addons
			if (typeof addonsPresent === 'boolean') {
				if (addonsPresent && addons.length > 0) {
					const addonsValues = []

					// iterate through addons list and add values
					addons.forEach(addon => {
						const { Name, IGST, BaseAmount, Rejected = false } = addon
						let addonValue = {
							Name,
							Amount: (parseInt(BaseAmount) || 0) + (parseInt(IGST) || 0),
							SumInsured: parseInt(addon?.SumInsured || 0),
							IGST: IGST || 0,
							BaseAmount: BaseAmount || 0,
							Rejected,
						}
						addonsValues.push(addonValue)
					})

					if (addonsValues.length) {
						optionalValues.Addons = addonsValues
					}
				} else {
					optionalValues.Addons = []
				}
			}

			if (policyType === 'SAFETY_PLAN') {
				// add fields from IntegratedCost
				const ICValues = []
				integratedCosts?.forEach(x => {
					const { Key, IGST, BaseAmount, Units, Type } = x
					let value = {
						Key,
						Units,
						Type,
						Amount: (parseInt(BaseAmount) || 0) + (parseInt(IGST) || 0),
						IGST: IGST || 0,
						BaseAmount: BaseAmount || 0,
					}
					ICValues.push(value)
				})
				optionalValues.IntegratedCosts = ICValues

				// add fields from Fees
				const FeesValues = []
				fees?.forEach(x => {
					const { IGST, BaseAmount, Type } = x
					let value = {
						Type,
						Amount: (parseInt(BaseAmount) || 0) + (parseInt(IGST) || 0),
						IGST: IGST || 0,
						BaseAmount: BaseAmount || 0,
					}
					FeesValues.push(value)
				})
				optionalValues.Fees = FeesValues
			}

			// now add the fields specific to the status...
			if (rfqStatus === 'ACCEPTED') {
				// add fields from premium breakdown
				let premiumBreakdownValues = {}
				const { Available, TotalPremium, BasePremium, IGST } = premiumBreakdown

				if (typeof Available === 'boolean') {
					premiumBreakdownValues.Available = Available

					if (Available) {
						let calculatedTotalPremium = 0

						if (typeof BasePremium === 'number') {
							premiumBreakdownValues.BasePremium = BasePremium
							calculatedTotalPremium += BasePremium
						}

						if (typeof IGST === 'number') {
							premiumBreakdownValues.IGST = IGST
							calculatedTotalPremium += IGST
						}

						if (calculatedTotalPremium) {
							premiumBreakdownValues.TotalPremium = calculatedTotalPremium
						}
					} else {
						if (typeof TotalPremium === 'number') {
							premiumBreakdownValues.TotalPremium = TotalPremium
						}
					}

					if (Object.keys(premiumBreakdownValues).length) {
						optionalValues.PremiumBreakdown = premiumBreakdownValues
					}
				}

				// add fields from insurer quote id
				optionalValues.ExternalId = insurerQuoteId

				// add fields from reduced sum insured
				if (typeof reducedSumInsuredPresent === 'boolean') {
					if (reducedSumInsuredPresent) {
						if (typeof reducedSumInsuredData === 'number') {
							optionalValues.ReducedSumInsured = reducedSumInsuredData
						}
					} else {
						optionalValues.ReducedSumInsured = 0
					}
				}

				// add fields from quote expiry
				if (typeof quoteExpiry === 'number') {
					optionalValues.Expiry = quoteExpiry
				}
			} else if (rfqStatus === 'FURTHER_REQUIREMENTS') {
				optionalValues.Emi = false
				// add fields from further requirements
				if (furtherRequirements.length) {
					const furtherRequirementsValues = []

					// iterate through further requirements list and add values
					furtherRequirements.forEach(requirement => {
						const { Question, AnswerType } = requirement
						let requirementValue = {}
						if (Question && inputTypes.includes(AnswerType)) {
							requirementValue = {
								Question,
								AnswerType,
							}
							furtherRequirementsValues.push(requirementValue)
						}
					})

					if (furtherRequirementsValues.length) {
						optionalValues.FurtherRequirements = furtherRequirementsValues
					}
				}
			}
		} else if (rfqStatus === 'REJECTED') {
			optionalValues.Emi = false
			// add fields from reason for rejection
			optionalValues.RejectionReason = reasonForRejection
		}

		const quote = {
			...requiredValues,
			...optionalValues,
			Meta: {
				...data?.Meta,
				Resubmitted: false,
			},
		}

		return quote
	}

	const resubmissionStatusChanges = () => {
		let filteredList = quoteList.filter(quote => quote.id !== data._id)
		let updatedStatus = ''

		if (filteredList.some(quote => quote?.Status === 'REQUIREMENTS_ANSWERED')) {
			// might have to include the case of even the current quote for this if we do go with the updation approach
			updatedStatus = 'OPS:RFQ_RESUBMISSION:PENDING'
		} else {
			updatedStatus = 'AGENT:QUOTES:PENDING'
			if (
				filteredList.every(
					quote => quote?.Status === 'FURTHER_REQUIREMENTS' && quote?.Meta?.Resubmitted === true
				)
			) {
				updatedStatus = 'OPS:QUOTES:PENDING'
			}
		}

		return updatedStatus
	}

	// handle submitting of quote
	const handleSubmitQuote = async () => {
		const quote = generateQuoteObject()

		if (data && data._id) {
			// update quote

			setLoading({ status: true, loadingText: 'updating quote' })
			const result = await updateQuote(data._id, quote)
			if (result) {
				await new Promise((resolve, reject) => {
					dispatch({
						type: 'UPDATE_QUOTE',
						data: {
							id: data?._id,
							Discrepancy: quote?.Discrepancy,
							Insurer: quote?.Insurer,
							Status: quote?.Status,
							TotalPremium: quote?.PremiumBreakdown?.TotalPremium,
							// think through all possible scenarios again for this
							Meta: {
								...quote?.Meta,
								Resubmitted: false,
							},
						},
					})
					resolve()
				})

				// resubmission flow
				if (currentRfqItem?.Status === 'OPS:RFQ_RESUBMISSION:PENDING') {
					let updatedStatus = resubmissionStatusChanges()
					await updateRFQStatus(currentRfqItem?.id, updatedStatus)
				}
				onNavigate()
			}
		} else {
			// create new quote
			setLoading({ status: true, loadingText: 'create new quote' })
			const result = await createQuote(state.currentRfqItem.id, quote)
			if (result) {
				const updatedStatus = 'AGENT:QUOTES:PENDING'
				await updateRFQStatus(currentRfqItem?.id, updatedStatus)

				onNavigate()
			}
		}
	}

	const handleSubmitButtonClick = () => {
		handleSubmitQuote()
	}

	const downloadRfqAttachments = async () => {
		const id = state?.currentRfqItem?.id || ''

		if (id) {
			const response = await downloadRFQArchive(id)
			const url = response?.Archive || ''
			if (url) {
				const link = document.createElement('a')
				link.href = url
				// link.setAttribute('download', 'RFQ_Attachments.zip')
				link.setAttribute('target', '_blank')
				document.body.appendChild(link)
				link.click()
				link.remove()

				Swal.fire({
					text: 'Original RFQ & Attachments were downloaded successfully',
					icon: 'success',
				})
			}
		}
	}

	const renderAttachmentsDownloadButton = (
		<Button
			onClick={downloadRfqAttachments}
			variant="primary"
			style={{ background: '#C8D6DB', color: '#02475E', border: 'none' }}
		>
			Original RFQ & attachments
			<img src={DownloadSm} alt="Download" className="ms-2" />
		</Button>
	)

	const existingInsurers = quoteList.map(item => item?.Insurer)
	let renderInsurerNameOptions = []
	insurerNames.forEach(insurerName => {
		let filteredInsurers = existingInsurers?.filter(insurerName => insurerName !== data?.Insurer)
		if (!filteredInsurers?.includes(insurerName)) {
			renderInsurerNameOptions.push(
				<option key={insurerName} value={insurerName}>
					{insurerName}
				</option>
			)
		}
	})

	const renderInputTypeOptions = inputTypesOptions.map((inputType, index) => {
		return (
			<option key={inputType.label} value={inputType.value}>
				{inputType.label}
			</option>
		)
	})

	const renderIfRfqRejected = (
		<InfoCard>
			{/* rejection reason card */}
			<InfoRow title="Reason for rejection?">
				<div>
					<FormControl
						type="text"
						as="textarea"
						rows={3}
						placeholder="Enter in details here"
						value={reasonForRejection}
						onChange={e => setReasonForRejection(e.target.value)}
					/>
				</div>
			</InfoRow>
		</InfoCard>
	)

	// Safety Plan fns
	const handleICChange = (value, key, index) => {
		let copy = [...integratedCosts]
		if (key === 'BaseAmount') {
			copy[index]['IGST'] = roundOffNum(Number(value) * 0.18)
		}
		copy[index][key] = value

		if (key === 'BaseAmount' || key === 'IGST') {
			copy[index]['Amount'] = copy[index]['BaseAmount'] + copy[index]['IGST'] || 0
		}
		setIntegratedCosts(copy)
	}
	const handleFeeChange = (value, key, index) => {
		let copy = [...fees]
		if (key === 'BaseAmount') {
			copy[index]['IGST'] = roundOffNum(Number(value) * 0.18)
		}
		copy[index][key] = value

		if (key === 'BaseAmount' || key === 'IGST') {
			copy[index]['Amount'] = copy[index]['BaseAmount'] + copy[index]['IGST'] || 0
		}
		setFees(copy)
	}
	// Safety Plan UI
	const integratedCostsOptions = integratedProducts.map(key => (
		<option key={key} value={key}>
			{key}
		</option>
	))
	const ICEle = integratedCosts?.map((x, index) => (
		<div key={x?.Key} style={{ background: '#FFF4F1', borderRadius: '4px' }} className="mb-3 p-3">
			<Row className="mb-3">
				<Col>
					<Text>Product</Text>
				</Col>
				<Col className="ms-4">
					<FormSelect
						placeholder="Please state the product name"
						value={x?.Key || ''}
						disabled={!editableSafetyPlanDetails.includes(x?.Key)}
					>
						<option value="" disabled hidden>
							Please state the product name
						</option>
						{integratedCostsOptions}
					</FormSelect>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Text>Base Premium</Text>
				</Col>
				<Col>
					<RupeeInput
						type="number"
						placeholder="Enter value here"
						value={x?.BaseAmount}
						disabled={!editableSafetyPlanDetails.includes(x?.Key)}
						onChange={e => handleICChange(e.target.valueAsNumber, 'BaseAmount', index)}
					/>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Text>IGST</Text>
				</Col>
				<Col>
					<RupeeInput
						type="number"
						placeholder="Enter value here"
						value={x?.IGST}
						disabled={!editableSafetyPlanDetails.includes(x?.Key)}
						onChange={e => handleICChange(e.target.valueAsNumber, 'IGST', index)}
					/>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Text>Units</Text>
				</Col>
				<Col>
					<FormControl
						type="text"
						placeholder="Enter Quote ID here"
						value={x?.Units}
						disabled={!editableSafetyPlanDetails.includes(x?.Key)}
						onChange={e => handleICChange(e.target.valueAsNumber, 'Units', index)}
					/>
				</Col>
			</Row>
		</div>
	))
	const feeOptions = feeTypes.map(key => (
		<option key={key} value={key}>
			{key}
		</option>
	))
	const FeeEle = fees?.map((x, index) => (
		<div key={x?.Type} style={{ background: '#FFF4F1', borderRadius: '4px' }} className="mb-3 p-3">
			<Row className="mb-3">
				<Col>
					<Text>Type</Text>
				</Col>
				<Col className="ms-4">
					<FormSelect
						placeholder="Please state the product name"
						value={x?.Type || ''}
						disabled={true}
					>
						<option value="" disabled hidden>
							Please state the fee type
						</option>
						{feeOptions}
					</FormSelect>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Text>Base Premium</Text>
				</Col>
				<Col>
					<RupeeInput
						type="number"
						placeholder="Enter value here"
						value={x?.BaseAmount}
						disabled={!editableSafetyPlanDetails.includes(x?.Type)}
						onChange={e => handleFeeChange(e.target.valueAsNumber, 'BaseAmount', index)}
					/>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Text>IGST</Text>
				</Col>
				<Col>
					<RupeeInput
						type="number"
						placeholder="Enter value here"
						value={x?.IGST}
						disabled={!editableSafetyPlanDetails.includes(x?.Type)}
						onChange={e => handleFeeChange(e.target.valueAsNumber, 'IGST', index)}
					/>
				</Col>
			</Row>
		</div>
	))
	const isSafetyPlan = policyType === 'SAFETY_PLAN'
	const safetyPlanDetails = isSafetyPlan ? (
		<>
			<InfoRow title="Is this a Safety Plan RFQ?">
				<div className="d-flex">
					<FormCheck className="me-3">
						<FormCheck.Input
							type="radio"
							name="safety-plan"
							id="safety-plan-yes"
							checked={isSafetyPlan}
							disabled
						/>
						<FormCheck.Label htmlFor="safety-plan-yes" className="ms-2">
							Yes
						</FormCheck.Label>
					</FormCheck>
					<FormCheck>
						<FormCheck.Input
							type="radio"
							name="safety-plan"
							id="safety-plan-no"
							checked={!isSafetyPlan}
							disabled
						/>
						<FormCheck.Label htmlFor="safety-plan-no" className="ms-2">
							No
						</FormCheck.Label>
					</FormCheck>
				</div>
			</InfoRow>
			<h5 className="mb-3" style={{ fontSize: '18px', marginTop: '1rem' }}>
				Hardware details -{' '}
				<a
					target="blank"
					href="https://docs.google.com/spreadsheets/d/1DzIUTxPnxaHP4Xo0jal7zKe5_5ecUKvBF1TAxV9K2Iw/edit#gid=0"
				>
					Gsheet
				</a>
			</h5>
			{ICEle}
			<h5 className="mb-3" style={{ fontSize: '18px' }}>
				Fee details -{' '}
			</h5>
			{FeeEle}
			<hr className="border-0 border-bottom border-dark my-3" />
		</>
	) : null

	const isAddPresnetQuestion = (
		<InfoRow title="Are there any add-ons?" bottomBorder={true}>
			<div className="d-flex">
				<FormCheck className="me-3">
					<FormCheck.Input
						type="radio"
						name="addons"
						id="addons-yes"
						checked={addonsPresent === true}
						onChange={() => setAddonsPresent(true)}
					/>
					<FormCheck.Label htmlFor="addons-yes" className="ms-2">
						Yes
					</FormCheck.Label>
				</FormCheck>
				<FormCheck>
					<FormCheck.Input
						type="radio"
						name="addons"
						id="addons-no"
						checked={addonsPresent === false}
						onChange={() => setAddonsPresent(false)}
					/>
					<FormCheck.Label htmlFor="addons-no" className="ms-2">
						No
					</FormCheck.Label>
				</FormCheck>
			</div>
		</InfoRow>
	)

	const addOnOptions = addOnOptionValue.map(key => (
		<option key={key} value={key}>
			{key}
		</option>
	))

	const addOnCreationEle = () =>
		addonsPresent && (
			<div>
				<h5 className="mb-3" style={{ fontSize: '18px' }}>
					Please enter the add-on name and premium
				</h5>
				{addons?.map((addonItem, index) => {
					// const isOther = addonItem?.Name !== "" && (addonItem?.Name == "Other" || optionValue.indexOf(addonItem?.Name) == -1);
					const isOther = addonItem.type === 'Other'
					const isBurglaryAddon =
						addonItem?.Name === 'Burglary' || addonItem?.Name === 'BURGLARY_THEFT'
					return (
						<div
							key={`addons-list-item-${index}`}
							style={{ background: '#FFF4F1', borderRadius: '4px' }}
							className="mb-3 p-3"
						>
							{addons?.length > 1 && (
								<Row className="mb-2">
									<Col className="d-flex justify-content-end">
										<Icon
											name="minus"
											size={'24px'}
											color={theme.colors.red}
											onClick={() => removeAddon(index)}
										/>
									</Col>
								</Row>
							)}
							<Row className="mb-3">
								<Col>
									<Text>Please state the name of the add on</Text>
								</Col>
								<Col className="ms-4">
									<FormSelect
										placeholder="Please state the add-on name"
										value={isOther ? 'Other' : addonItem?.Name || ''}
										onChange={e =>
											handleAddonsListNameDropdownChange(e.target.value, index, addonItem)
										}
									>
										<option value="" disabled hidden>
											Please state the add-on name
										</option>
										{addOnOptions}
									</FormSelect>
								</Col>
							</Row>
							{isOther ? (
								<Row className="mb-4">
									<Col></Col>
									<Col>
										<FormCheck.Label className="mb-2 d-flex me-1">
											<Text fontSize="14px" color={theme.colors.gray2}>
												Specify - Other
											</Text>
											<Text color={theme.colors.red}>*</Text>
										</FormCheck.Label>
										<FormControl
											type="text"
											placeholder="Please state the add-on name"
											value={addonItem?.Name || ''}
											onChange={e => handleAddonsListNameInputChange(e.target.value, index)}
										/>
									</Col>
								</Row>
							) : null}

							<Row className="mb-3">
								<Col>
									<Text>Sum Insured</Text>
								</Col>
								<Col>
									<RupeeInput
										type="number"
										placeholder="Enter value here"
										value={addonItem?.SumInsured}
										onChange={e =>
											handleAddonsListDataChange(
												'SumInsured',
												e.target.valueAsNumber,
												index,
												addonItem.type
											)
										}
										disabled={isBurglaryAddon}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Text>Premium</Text>
								</Col>
								<Col>
									<RupeeInput
										type="number"
										placeholder="Enter value here"
										value={addonItem?.BaseAmount}
										onChange={e =>
											handleAddonsListDataChange(
												'BaseAmount',
												e.target.valueAsNumber,
												index,
												addonItem.type
											)
										}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col className="d-flex">
									<Text className="me-1">IGST</Text>
									<Text color={theme.colors.secondary} fontSize="12px">
										Optional
									</Text>
								</Col>
								<Col>
									<RupeeInput
										type="number"
										placeholder="Enter value here"
										value={addonItem?.IGST}
										onChange={e =>
											handleAddonsListDataChange(
												'IGST',
												e.target.valueAsNumber,
												index,
												addonItem.type
											)
										}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col className="d-flex">
									<Text className="me-1">Addon Status</Text>
								</Col>
								<Col className="ms-4">
									<FormSelect
										placeholder="Please state the addon status"
										value={addonItem?.Rejected ? 'REJECTED' : 'ACCEPTED'}
										onChange={e => {
											const value = e.target.value
											const isRejected = value === 'ACCEPTED' ? false : true
											handleAddonsListDataChange('Rejected', isRejected, index, addonItem.type)
										}}
									>
										<option value="" disabled hidden>
											Please state the add-on status
										</option>
										<option value="ACCEPTED">ACCEPTED</option>
										<option value="REJECTED">REJECTED</option>
									</FormSelect>
								</Col>
							</Row>
						</div>
					)
				})}
				<Row>
					<Col>
						<Button
							onClick={addNewAddon}
							className="bg-white font-weight-bold"
							style={{ color: 'rgb(2, 71, 94)', border: 'none' }}
							icon="add"
						>
							Add another add-on
						</Button>
					</Col>
				</Row>
				<hr className="border-0 border-bottom border-dark my-3" />
			</div>
		)

	const isPremiumDiscountedQues = (
		<InfoRow title="Is this a Discounted Quote?" bottomBorder={discountedQuotePresent}>
			<div className="d-flex">
				<FormCheck className="me-3">
					<FormCheck.Input
						type="radio"
						name="discounted"
						id="discounted-yes"
						checked={discountedQuotePresent === true}
						onChange={() => setDiscountedQuote(true)}
					/>
					<FormCheck.Label htmlFor="discounted-yes" className="ms-2">
						Yes
					</FormCheck.Label>
				</FormCheck>
				<FormCheck>
					<FormCheck.Input
						type="radio"
						name="discounted"
						id="discounted-no"
						checked={discountedQuotePresent === false}
						onChange={() => setDiscountedQuote(false)}
					/>
					<FormCheck.Label htmlFor="discounted-no" className="ms-2">
						No
					</FormCheck.Label>
				</FormCheck>
			</div>
		</InfoRow>
	)

	const isPremiumWithinBudgetQues = discountedQuotePresent ? (
		<InfoRow title="Is this Discounted Quote within the target budget of the customer?">
			<div className="d-flex">
				<FormCheck className="me-3">
					<FormCheck.Input
						type="radio"
						name="budgeted"
						id="within-budget-yes"
						checked={budgetedQuotePresent === true}
						onChange={() => setBudgetQuote(true)}
					/>
					<FormCheck.Label htmlFor="within-budget-yes" className="ms-2">
						Yes
					</FormCheck.Label>
				</FormCheck>
				<FormCheck>
					<FormCheck.Input
						type="radio"
						name="budgeted"
						id="within-budget-no"
						checked={budgetedQuotePresent === false}
						onChange={() => setBudgetQuote(false)}
					/>
					<FormCheck.Label htmlFor="within-budget-no" className="ms-2">
						No
					</FormCheck.Label>
				</FormCheck>
			</div>
		</InfoRow>
	) : null

	let previewTotalPremium
	if (!premiumBreakdown.Available) {
		previewTotalPremium = premiumBreakdown.TotalPremium
	} else {
		// add up BasePremium + IGST + add-ons
		previewTotalPremium = calculateTotalPremium({
			HasPremiumBreakdown: premiumBreakdown.Available,
			TotalPremium: (premiumBreakdown.BasePremium || 0) + (premiumBreakdown.IGST || 0),
			Addons: [...addons],
			IntegratedCosts: [...integratedCosts],
			Fees: [...fees],
		})
	}

	const renderIfRfqAccepted = (
		<>
			<InfoCard>
				<InfoRow title="Is there a premium breakdown available?" bottomBorder={true}>
					<div className="d-flex">
						<FormCheck className="me-3">
							<FormCheck.Input
								type="radio"
								name="premium-breakdown"
								id="premium-breakdown-yes"
								checked={premiumBreakdown?.Available === true}
								onChange={() => handlePremiumsDataChange('Available', true)}
							/>
							<FormCheck.Label htmlFor="premium-breakdown-yes" className="ms-2">
								Yes
							</FormCheck.Label>
						</FormCheck>
						<FormCheck>
							<FormCheck.Input
								type="radio"
								name="premium-breakdown"
								id="premium-breakdown-no"
								checked={premiumBreakdown?.Available === false}
								onChange={() => handlePremiumsDataChange('Available', false)}
							/>
							<FormCheck.Label htmlFor="premium-breakdown-no" className="ms-2">
								No
							</FormCheck.Label>
						</FormCheck>
					</div>
				</InfoRow>

				{premiumBreakdown?.Available ? (
					<>
						<InfoRow>
							<Col className="mb-3">
								<p className="font-weight-bold" style={{ fontSize: '18px' }}>
									Base Premium
								</p>
							</Col>
							<Col>
								<RupeeInput
									type="number"
									placeholder="Enter value here"
									value={premiumBreakdown?.BasePremium}
									onChange={e => handlePremiumsDataChange('BasePremium', e.target.valueAsNumber)}
								/>
							</Col>
						</InfoRow>

						<InfoRow bottomBorder={true}>
							<Col>
								<p className="font-weight-bold" style={{ fontSize: '18px' }}>
									IGST <i style={{ color: '#F78670', fontSize: '12px' }}>Optional</i>
								</p>
							</Col>
							<Col className="mb-3">
								<RupeeInput
									type="number"
									placeholder="Enter value here"
									value={premiumBreakdown?.IGST}
									onChange={e => handlePremiumsDataChange('IGST', e.target.valueAsNumber)}
								/>
							</Col>
						</InfoRow>
					</>
				) : premiumBreakdown?.Available === false ? (
					<InfoRow bottomBorder={true}>
						{/* NOTE: This explicit check was required to differentiate between cases where `premiumBreakdown.Available` is `null` */}
						<Col className="mb-3">
							<p className="font-weight-bold" style={{ fontSize: '18px' }}>
								Total Premium
							</p>
						</Col>
						<Col>
							<RupeeInput
								type="number"
								placeholder="Enter value here"
								value={premiumBreakdown?.TotalPremium}
								onChange={e => handlePremiumsDataChange('TotalPremium', e.target.valueAsNumber)}
							/>
						</Col>
					</InfoRow>
				) : null}

				{safetyPlanDetails}

				{isAddPresnetQuestion}
				{addOnCreationEle()}
				<InfoRow bottomBorder={true}>
					<Row>
						<Col>
							<Text fontSize={'18px'} fontWeight="700" color={theme.colors.gray7}>
								Total Premium to be displayed
							</Text>
						</Col>
						<Col>
							<RupeeInput type="number" value={previewTotalPremium} disabled={true} />
						</Col>
					</Row>
				</InfoRow>
				{isPremiumDiscountedQues}
				{isPremiumWithinBudgetQues}
			</InfoCard>

			<InfoCard>
				<InfoRow
					title={
						<>
							What is the Quote ID specified by the insurer?
							<i style={{ marginLeft: '4px', fontSize: '12px', color: '#F78670' }}>Optional</i>
						</>
					}
				>
					<Col>
						<FormControl
							type="text"
							placeholder="Enter Quote ID here"
							value={insurerQuoteId}
							onChange={e => setInsurerQuoteId(e.target.value)}
						/>
					</Col>
				</InfoRow>
			</InfoCard>

			<InfoCard>
				<InfoRow title="Has the Sum Insured been reduced?" bottomBorder={true}>
					<div className="d-flex">
						<FormCheck className="me-3">
							<FormCheck.Input
								type="radio"
								name="sum-insured-reduced"
								id="sum-insured-reduced-yes"
								checked={reducedSumInsuredPresent === true}
								onChange={() => setReducedSumInsuredPresent(true)}
							/>
							<FormCheck.Label htmlFor="sum-insured-reduced-yes" className="ms-2">
								Yes
							</FormCheck.Label>
						</FormCheck>
						<FormCheck>
							<FormCheck.Input
								type="radio"
								name="sum-insured-reduced"
								id="sum-insured-reduced-no"
								checked={reducedSumInsuredPresent === false}
								onChange={() => setReducedSumInsuredPresent(false)}
							/>
							<FormCheck.Label htmlFor="sum-insured-reduced-no" className="ms-2">
								No
							</FormCheck.Label>
						</FormCheck>
					</div>
				</InfoRow>

				{reducedSumInsuredPresent && (
					<InfoRow title="What is the new Sum Insured?">
						<Col>
							<RupeeInput
								type="number"
								placeholder="Enter value here"
								value={reducedSumInsuredData}
								onChange={e => handleReducedSumInsuredChange(e.target.valueAsNumber)}
							/>
						</Col>
					</InfoRow>
				)}
			</InfoCard>

			<InfoCard>
				<InfoRow title="Are there any special conditions/warranties added?" bottomBorder={true}>
					<div className="d-flex">
						<FormCheck className="me-3">
							<FormCheck.Input
								type="radio"
								name="special-cases-warranties"
								id="special-cases-warranties-yes"
								checked={termsAndConditionsPresent === true}
								onChange={() => setTermsAndConditionsPresent(true)}
							/>
							<FormCheck.Label htmlFor="special-cases-warranties-yes" className="ms-2">
								Yes
							</FormCheck.Label>
						</FormCheck>
						<FormCheck>
							<FormCheck.Input
								type="radio"
								name="special-cases-warranties"
								id="special-cases-warranties-no"
								checked={termsAndConditionsPresent === false}
								onChange={() => setTermsAndConditionsPresent(false)}
							/>
							<FormCheck.Label htmlFor="special-cases-warranties-no" className="ms-2">
								No
							</FormCheck.Label>
						</FormCheck>
					</div>
				</InfoRow>

				{termsAndConditionsPresent && (
					<InfoRow title="Please specify the terms">
						<Col>
							<FormControl
								type="text"
								as="textarea"
								rows={3}
								placeholder="Enter in details here"
								value={termsAndConditions || ''}
								onChange={e => setTermsAndConditions(e.target.value)}
							/>
						</Col>
					</InfoRow>
				)}
			</InfoCard>

			<InfoCard>
				<InfoRow
					title={
						<>
							For how long is the quote active? (in days)
							<i style={{ marginLeft: '4px', fontSize: '12px', color: '#F78670' }}>Optional</i>
						</>
					}
				>
					<Col>
						<FormControl
							type="number"
							placeholder="Enter in number of days"
							value={quoteExpiry}
							onChange={e => handleQuoteExpiryChange(e.target?.valueAsNumber)}
						/>
					</Col>
				</InfoRow>
			</InfoCard>
		</>
	)

	const renderIfRfqOtherRequirements = (
		<>
			<InfoCard>
				<InfoRow title="Are there any special conditions/warranties added?" bottomBorder={true}>
					<div className="d-flex">
						<FormCheck className="me-3">
							<FormCheck.Input
								type="radio"
								name="special-cases-warranties"
								id="special-cases-warranties-yes"
								checked={termsAndConditionsPresent === true}
								onChange={() => setTermsAndConditionsPresent(true)}
							/>
							<FormCheck.Label htmlFor="special-cases-warranties-yes" className="ms-2">
								Yes
							</FormCheck.Label>
						</FormCheck>
						<FormCheck>
							<FormCheck.Input
								type="radio"
								name="special-cases-warranties"
								id="special-cases-warranties-no"
								checked={termsAndConditionsPresent === false}
								onChange={() => setTermsAndConditionsPresent(false)}
							/>
							<FormCheck.Label htmlFor="special-cases-warranties-no" className="ms-2">
								No
							</FormCheck.Label>
						</FormCheck>
					</div>
				</InfoRow>

				{termsAndConditionsPresent && (
					<InfoRow title="Please specify the terms">
						<Col>
							<FormControl
								type="text"
								as="textarea"
								rows={3}
								placeholder="Enter in details here"
								value={termsAndConditions || ''}
								onChange={e => setTermsAndConditions(e.target.value)}
							/>
						</Col>
					</InfoRow>
				)}
			</InfoCard>

			<InfoCard>
				{safetyPlanDetails}
				{isAddPresnetQuestion}
				{addOnCreationEle()}
			</InfoCard>

			<InfoCard>
				<>
					<InfoRow title="What else is required by the Insurer?">
						{furtherRequirements?.map((otherInfo, index) => (
							<Row key={`other-info-item-${index}`}>
								<Col>
									<FormControl
										type="text"
										placeholder="Enter question in detail here"
										value={otherInfo?.Question || ''}
										onChange={e => handleOtherInfoRequiredChange(index, 'Question', e.target.value)}
									/>
								</Col>
								<Col className="mb-3">
									<FormSelect
										placeholder="Select the type of input the sales executive needs to enter"
										value={otherInfo?.AnswerType || ''}
										onChange={e =>
											handleOtherInfoRequiredChange(index, 'AnswerType', e.target.value)
										}
									>
										<option value="" disabled hidden>
											Select the type of input the sales executive needs to enter
										</option>
										{renderInputTypeOptions}
									</FormSelect>
								</Col>
								{furtherRequirements?.length > 1 && (
									<Col style={{ flex: '0' }}>
										<Button variant="danger" onClick={() => removeRequirement(index)}>
											-
										</Button>
									</Col>
								)}
							</Row>
						))}
					</InfoRow>
					<Button
						onClick={addNewRequirement}
						className="bg-white font-weight-bold"
						style={{ color: 'rgb(2, 71, 94)', border: 'none' }}
					>
						<span className="mr-2">+</span> Add another requirement
					</Button>
				</>
				{/* TODO: add a "Add another requirement button here: " */}
			</InfoCard>
		</>
	)

	let renderRfqResponseItems = null

	if (rfqStatus === 'ACCEPTED') {
		renderRfqResponseItems = renderIfRfqAccepted
	} else if (rfqStatus === 'REJECTED') {
		renderRfqResponseItems = renderIfRfqRejected
	} else if (rfqStatus === 'FURTHER_REQUIREMENTS') {
		renderRfqResponseItems = renderIfRfqOtherRequirements
	}

	return (
		<Container>
			<h5 className="my-3" style={{ fontSize: '24px', color: '#02475E' }}>
				{title}
			</h5>

			{/* Insurance company selection card */}
			<InfoCard>
				<InfoRow title="Select the insurance company">
					<div>
						<FormSelect value={insurer} onChange={e => setInsurer(e.target.value)}>
							<option value="" disabled hidden>
								Select
							</option>
							{renderInsurerNameOptions}
						</FormSelect>
					</div>
				</InfoRow>
			</InfoCard>

			{/* Type of policy selection card */}
			<InfoCard>
				<InfoRow title="Type of policy">
					<Col>{policyTypeUI}</Col>
				</InfoRow>
			</InfoCard>

			{/* proposal form section */}
			<InfoCard>
				<InfoRow
					bottomBorder={true}
					title="Has this Insurer sent across a proposal form along with the quote containing client details?"
				>
					<div className="d-flex">
						<FormCheck className="me-3">
							<FormCheck.Input
								type="radio"
								id="proposal-form-yes"
								name="proposal-form"
								checked={isNewQuote ? undefined : proposalForm}
								onChange={() => setProposalForm(true)}
							/>
							<FormCheck.Label className="ms-2" htmlFor="proposal-form-yes">
								Yes
							</FormCheck.Label>
						</FormCheck>
						<FormCheck>
							<FormCheck.Input
								type="radio"
								id="proposal-form-no"
								name="proposal-form"
								checked={isNewQuote ? undefined : !proposalForm}
								onChange={() => setProposalForm(false)}
							/>
							<FormCheck.Label className="ms-2" htmlFor="proposal-form-no">
								No
							</FormCheck.Label>
						</FormCheck>
					</div>

					{proposalForm && (
						<div className="mt-3">
							<AlertComponent variant="danger">
								<p className="text-dark m-0">
									Please verify the client details in the RFQ and the proposal form sent across by
									the Insurer!
									<br />
									If there are any discrepancies, please raise it for this Insurer and follow up
									with them.
								</p>
							</AlertComponent>
						</div>
					)}
				</InfoRow>

				{proposalForm ? (
					<InfoRow
						title="Any discrepancies found in the RFQ?"
						rightContent={renderAttachmentsDownloadButton}
					>
						<div className="d-flex">
							<FormCheck className="me-3">
								<FormCheck.Input
									type="radio"
									id="discrepancies-found-yes"
									name="discrepancies-found"
									checked={discrepancies === true}
									onChange={() => setDiscrepancies(true)}
								/>
								<FormCheck.Label className="ms-2" htmlFor="discrepancies-found-yes">
									Yes
								</FormCheck.Label>
							</FormCheck>
							<FormCheck>
								<FormCheck.Input
									type="radio"
									id="discrepancies-found-no"
									name="discrepancies-found"
									checked={discrepancies === false}
									onChange={() => setDiscrepancies(false)}
								/>
								<FormCheck.Label className="ms-2" htmlFor="discrepancies-found-no">
									No
								</FormCheck.Label>
							</FormCheck>
						</div>

						{discrepancies && (
							<div className="mt-3">
								<AlertComponent variant="warning" alertStyles={{ background: '#F2C94C' }}>
									<p className="text-dark m-0">
										Contact the Insurance company to clear out the discrepancies found in the RFQ
									</p>
								</AlertComponent>
							</div>
						)}
					</InfoRow>
				) : null}
			</InfoCard>

			{/* RFQ response card */}
			<InfoCard>
				<InfoRow title="What is the RFQ response?">
					<div className="d-flex">
						<FormCheck className="me-3">
							<FormCheck.Input
								type="radio"
								id="rfq-response-accepted"
								name="rfq-response"
								checked={rfqStatus === 'ACCEPTED'}
								onChange={() => setRfqStatus('ACCEPTED')}
							/>
							<FormCheck.Label className="ms-2" htmlFor="rfq-response-accepted">
								Accepted
							</FormCheck.Label>
						</FormCheck>
						<FormCheck className="me-3">
							<FormCheck.Input
								type="radio"
								id="rfq-response-rejected"
								name="rfq-response"
								checked={rfqStatus === 'REJECTED'}
								onChange={() => setRfqStatus('REJECTED')}
							/>
							<FormCheck.Label className="ms-2" htmlFor="rfq-response-rejected">
								Rejected
							</FormCheck.Label>
						</FormCheck>
						<FormCheck className="form-check me-3">
							<FormCheck.Input
								type="radio"
								id="rfq-response-further-reqs"
								name="rfq-response"
								checked={rfqStatus === 'FURTHER_REQUIREMENTS'}
								onChange={() => setRfqStatus('FURTHER_REQUIREMENTS')}
							/>
							<FormCheck.Label className="ms-2" htmlFor="rfq-response-further-reqs">
								Further Requirements
							</FormCheck.Label>
						</FormCheck>
						<FormCheck className="form-check">
							<FormCheck.Input
								type="radio"
								id="rfq-response-outdated"
								name="rfq-response"
								checked={rfqStatus === 'OUTDATED'}
								onChange={() => setRfqStatus('OUTDATED')}
							/>
							<FormCheck.Label className="ms-2" htmlFor="rfq-response-outdated">
								Outdated Quote
							</FormCheck.Label>
						</FormCheck>
					</div>
				</InfoRow>
			</InfoCard>

			{renderRfqResponseItems}

			<Button
				className="my-3 mx-auto rounded p-2"
				variant="primary"
				style={{ color: '#fff', background: '#02475E', border: 'none' }}
				onClick={handleSubmitButtonClick}
				disabled={submitDisabled}
			>
				<img src={Checkmark} alt="arrow right" className="me-2" />
				Submit details
			</Button>
		</Container>
	)
}

export default QuoteUpdateComponent

const RupeeSymbol = styled.span`
	color: #02475e;
	font-size: 18px;
	line-height: 20px;
	font-weight: 600;
	margin-right: 0.25rem; /* Replace this with Space in the 'if' condition */
`

const PolicyTypeContainer = styled.div`
	background: #e9effe;
	border-radius: 4px;
	padding: 10px;
`

const PolicyName = styled.p`
	font-size: 14px;
	font-weight: 700;
	color: #333333;
	margin: 0px;
`

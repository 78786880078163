const parseLinkFromSignedUrl = url => {
	const link = new URL(url)
	return `${link.origin}${link.pathname}`
}

const imageDeleteConfirmOptions = {
	title: 'Do you want to delete this image?',
	icon: 'warning',
	showCancelButton: true,
	confirmButtonColor: '#d33',
	cancelButtonColor: '#3085d6',
	confirmButtonText: 'Delete',
}

// convert base64 to blob helper func
const convertBase64ToBlob = async string => {
	const res = await fetch(string)
	const blob = await res.blob()
	return blob
}

// convert File to base64 string
const toBase64 = file => {
	return new Promise((resolve, reject) => {
		try {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = error => reject(error)
		} catch (err) {
			console.log('[ERR] Failed to process image')
		}
	})
}

export { convertBase64ToBlob, toBase64, parseLinkFromSignedUrl, imageDeleteConfirmOptions }

import { Spinner } from 'react-bootstrap'
import styled, { keyframes } from 'styled-components'
import { useEffect, useState, createContext, useContext } from 'react'
import theme from '../components/core/theme'
import Text from '../components/core/Text'
import WarningIcon from '../assets/icons/warningTriangleSm.svg'

export const LoadingOverlayContext = createContext()

const initLoadingState = {
	status: false,
	loadingText: '',
}

const initError = {
	status: false,
	errorMessage: '',
	errorCode: '',
}

export function LoadingOverlayProvider({ children, ...props }) {
	const [loading, setLoading] = useState(initLoadingState)
	const [error, setError] = useState(initError)

	const resetError = () => setError(initError)

	const value = {
		loading,
		setLoading,
		error,
		setError,
		resetError,
	}

	return (
		<LoadingOverlayContext.Provider {...props} value={value}>
			{children}
		</LoadingOverlayContext.Provider>
	)
}

export const useLoadingOverlay = () => {
	const context = useContext(LoadingOverlayContext)
	if (!context) {
		throw new Error('useLoadingOverlay must be used within LoadingOverlayProvider')
	}
	return context
}

const LoadingOverlay = () => {
	const { loading, error, resetError } = useLoadingOverlay()

	useEffect(() => {
		if (loading.status) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}

		return () => (document.body.style.overflow = 'auto')
	}, [loading.status])

	let loaderContent = null

	if (loading.status) {
		loaderContent = (
			<LoaderContainer>
				<Spinner className="mt-2" animation="border" variant="secondary" />

				<Text
					className="mt-3 text-center"
					color={theme.colors.gray2}
					fontSize="16px"
					style={{ maxWidth: '300px' }}
				>
					{loading.loadingText}
				</Text>
			</LoaderContainer>
		)
	} else if (error?.status) {
		loaderContent = (
			<LoaderContainer className="">
				<img src={WarningIcon} height="50px" width="50px" alt="error" />

				<Text className="mt-2" color={theme.colors.red} fontWeight="700" fontSize="18px">
					{error?.errorMessage ?? 'An error has occurred'}
				</Text>

				{error?.errorCode && (
					<Text className="mt-1 mb-2" color={theme.colors.gray2} fontSize="16px">
						ERROR CODE: {error.errorCode || 500}
					</Text>
				)}

				<Text className="my-1 text-center" color={theme.colors.gray3} style={{ maxWidth: '300px' }}>
					If this issue is recurring, please get in touch with support and mention the error code.
				</Text>

				<OkButton
					className="mt-2"
					color={theme.colors.primary}
					fontSize="14px"
					onClick={() => resetError()}
				>
					OK
				</OkButton>
			</LoaderContainer>
		)
	}

	if (loaderContent) {
		return <OverlayContainer>{loaderContent}</OverlayContainer>
	} else {
		return null
	}
}

// animations
const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

const OverlayContainer = styled.div`
	background: rgba(0, 0, 0, 0.4);
	position: fixed;
	z-index: 1080;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: none;
	animation: ${fadeIn} 0.3s ease-in-out;
`

const LoaderContainer = styled.div`
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;
	padding: 1rem;
	width: min(300px, 100%);
	border-radius: 8px;
	z-index: 1100;
`
const OkButton = styled(Text)`
	padding: 3px 5px;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background: #cfcfcf;
	}
`

export default LoadingOverlay

import { Button, Container, FormCheck } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { TopNav } from '../../components/SharedContainers'
import { Text, Icon, theme } from 'verak-ui'

import { useFetch } from '../../api/apihooks'
import { useState, useEffect, useRef } from 'react'
import { useLoadingOverlay } from '../../context/LoadingErrorProvider'
import { useContextApi } from '../../context/Provider'
import AddQuoteSuccess from '../QuoteUpdatesPending/AddQuoteSuccess'
import styled from 'styled-components'
import ModalComp from '../../components/core/Modal'
import { getFormattedAmount } from '../../utils/getFormattedAmount'
import { policyTypeStrings } from '../../utils/getPolicyType'
import calculateTotalPremium from '../../utils/calculateTotalPremium'

import EditDetailsModal from './EditDetailsModal'

import bajaj from '../../assets/insurers/bajajAllianz.png'
import digit from '../../assets/insurers/DigitInsurance.png'
import icici from '../../assets/insurers/icici.png'
import liberty from '../../assets/insurers/LibertyInsurance.png'
import oriental from '../../assets/insurers/OrientalInsurance.png'
import reliance from '../../assets/insurers/RelianceGeneralInsurance.png'
import royalSundaram from '../../assets/insurers/RoyalSundaram.png'
import unitedIndia from '../../assets/insurers/UnitedIndia.png'
import tataAIG from '../../assets/insurers/TataAIG.png'
import NationalInsurance from '../../assets/insurers/NationalInsurance.png'
import NewIndiaAssurance from '../../assets/insurers/NewIndiaAssurance.png'
import KotakGeneral from '../../assets/insurers/KotakGeneralInsurance.png'
import CholaMS from '../../assets/insurers/CholaMSGeneralInsurance.svg'
import SBIGeneral from '../../assets/insurers/SBIGeneral.svg'
import defaultIcon from '../../assets/insurers/default.png'
import question from '../../assets/icons/questionOrange.svg'
import BackArrow from '../../assets/icons/BackArrow.svg'
import InsurerQuotesList from '../FollowUp/InsurerQuotesList'

const insurerIcons = {
	'Reliance General Insurance': reliance,
	'Liberty General Insurance': liberty,
	'Oriental Insurance': oriental,
	'Digit Insurance': digit,
	'ICICI Lombard General Insurance': icici,
	'Bajaj Allianz General Insurance': bajaj,
	'Royal Sundaram General Insurance': royalSundaram,
	'United India Insurance': unitedIndia,
	'Tata AIG General Insurance': tataAIG,
	'National Insurance': NationalInsurance,
	'The New India Assurance Co. Ltd.': NewIndiaAssurance,
	'Kotak General Insurance': KotakGeneral,
	'Chola MS General Insurance': CholaMS,
	'SBI General Insurance': SBIGeneral,
}

let editDetailsBtn = (
	<Button variant="primary" style={{ color: '#F78670', background: '#fff', border: 'none' }}>
		Edit details
	</Button>
)

const GenerateQuoteCardAction = ({ quote, rfqStatus, openModal }) => {
	let link = {
		pathname: '/quote-update',
		state: {
			quote,
		},
	}

	if (quote.Status == 'REQUIREMENTS_ANSWERED' && rfqStatus == 'OPS:RFQ_RESUBMISSION:PENDING') {
		link.pathname = '/rfq-resubmission-pending'

		return (
			<Link to={link}>
				<Button style={{ color: '#fff', background: '#02475E', border: 'none', height: '48px' }}>
					Complete further requirements
				</Button>
			</Link>
		)
	} else {
		return (
			<>
				{quote?.Discrepancy === true ? (
					<div onClick={() => openModal(quote.id)}>{editDetailsBtn}</div>
				) : (
					<Link to={link}>{editDetailsBtn}</Link>
				)}
			</>
		)
	}
}

const QuoteCard = ({ quote, rfqStatus, openModal }) => {
	const totalPremium = calculateTotalPremium(quote)
	const formattedTotalPremium = getFormattedAmount(totalPremium || 0)
	const quoteId = quote?.id || ''

	const icon = insurerIcons[quote?.Insurer] || defaultIcon

	const renderQuoteAction = (
		<GenerateQuoteCardAction quote={quote} rfqStatus={rfqStatus} openModal={openModal} />
	)
	let premiumEle

	let premiumLabel = ''
	if (quote.Status === 'ACCEPTED') {
		premiumLabel = `₹ ${formattedTotalPremium}`
	} else if (quote.Status === 'REJECTED') {
		premiumLabel = (
			<p
				className="p-0 mt-1 mb-0"
				style={{ color: '#DC2F21', fontSize: '24px', fontWeight: 'bold' }}
			>
				REJECTED
			</p>
		)
	} else {
		premiumLabel = '-NA-'
	}

	premiumEle = (
		<div className="d-flex flex-column justify-content-between pe-3 me-3 border-end">
			<p className="font-weight-bold" style={{ color: '#C8D6DB', fontSize: '14px' }}>
				Premium
			</p>
			<p className="font-weight-bold" style={{ fontSize: '24px' }}>
				{premiumLabel}
			</p>
		</div>
	)

	let discrepancyEle,
		furtherRequirementsEle,
		quoteClasses = ''
	if (quote.Discrepancy) {
		discrepancyEle = (
			<QuoteTag>
				<QuoteText>Discrepancies found</QuoteText>
			</QuoteTag>
		)
		quoteClasses = 'border-end pe-3 me-3'
	}

	if (quote?.Status == 'FURTHER_REQUIREMENTS') {
		furtherRequirementsEle = (
			<QuoteTag style={{ background: '#F5DBDB' }}>
				<QuoteText style={{ color: '#DC2F21' }}>Further requirements pending</QuoteText>
			</QuoteTag>
		)
		quoteClasses = 'border-end pe-3 me-3'
	} else if (quote?.Status === 'OUTDATED') {
		furtherRequirementsEle = (
			<QuoteTag style={{ background: '#F0E5FE' }}>
				<QuoteText style={{ color: '#8D3AF6' }}>Outdated quotes</QuoteText>
			</QuoteTag>
		)
		quoteClasses = 'border-end pe-3 me-3'
	}

	const selectedTag = quote?.Selected ? (
		<QuoteTag style={{ marginLeft: '1rem', background: '#F5DBDB' }}>
			<QuoteText style={{ color: '#DC2F21' }}>Selected</QuoteText>
		</QuoteTag>
	) : null

	return (
		<div className="bg-white shadow rounded p-3 my-3 d-flex justify-content-between">
			<div className="d-flex align-items-center">
				<QuoteImg src={icon} alt="insurer" className="me-3" />
				{premiumEle}
				<div className={`d-flex flex-column justify-content-between ${quoteClasses}`}>
					<p className="font-weight-bold" style={{ color: '#C8D6DB', fontSize: '14px' }}>
						Quote ID
					</p>
					<p className="font-weight-bold" style={{ fontSize: '24px' }}>
						{quoteId}
					</p>
				</div>
				{discrepancyEle}
				{furtherRequirementsEle}
				{selectedTag}
			</div>
			<div className="align-self-center">{renderQuoteAction}</div>
		</div>
	)
}

export default function QuotesListHandler() {
	const [state] = useContextApi()
	const Status = state?.currentRfqItem?.Status
	if (Status === 'OPS:POLICY:PENDING' || Status === 'OPS:CLOSURE:PENDING') {
		return <InsurerQuotesList />
	} else {
		return <QuotesList />
	}
}

const QuotesList = () => {
	const history = useHistory()
	const { loading, setLoading } = useLoadingOverlay()
	const [state, dispatch] = useContextApi()

	// discrepancy modal
	const [discrepancyPopUp, setDiscrepancyPopUp] = useState(false)
	const [hasDiscrepancy, setDiscrepancy] = useState(null)
	const [quoteWithDiscrepancy, setQuote] = useState()

	const [showEditDetailsModal, setShowEditDetailsModal] = useState(false)

	const rfqInfoSectionRef = useRef()

	const policyType = ''

	const { getQuoteList, updateRFQStatus, updateQuote, getSumInsuredBreakdown, getCustomerInfo } =
		useFetch()
	const rfqItem = state?.currentRfqItem
	const quoteList = rfqItem.quoteList
	const rfqStatus = rfqItem?.Status || ''

	const [doneAddingQuotes, setDoneAddingQuotes] = useState(false)

	const enableBottomBanner = state?.currentRfqItem?.quoteList?.some(
		quote => quote?.Status !== 'FURTHER_REQUIREMENTS'
	)

	// discrepancy modal
	const openModal = id => {
		const quote = quoteList.find(item => {
			return item.id == id
		})
		setQuote(quote)

		setDiscrepancy(null) // handles the disabling/enabling of the "Continue" button
		setDiscrepancyPopUp(true)
	}

	const updateDiscrepancyStatus = async () => {
		if (hasDiscrepancy === false) {
			const new_quote = {
				...quoteWithDiscrepancy,
				Discrepancy: hasDiscrepancy,
			}
			setDiscrepancyPopUp(false)

			await new Promise((resolve, reject) => {
				dispatch({
					type: 'UPDATE_QUOTE',
					data: new_quote,
				})
				resolve()
			})
			await updateQuote(quoteWithDiscrepancy?.id, new_quote)
		} else {
			setDiscrepancyPopUp(false)
		}

		history.push({
			pathname: '/quote-update',
			state: {
				quote: quoteWithDiscrepancy || {},
			},
		})
	}

	const modal = (
		<ModalComp
			show={discrepancyPopUp}
			handleClose={() => setDiscrepancyPopUp(false)}
			closeButton={true}
			title={
				<p
					style={{
						color: '#EB5757',
						fontSize: '14px',
						letterSpacing: '0.05em',
						fontWeight: 700,
					}}
				>
					DISCREPANCIES
				</p>
			}
			footer={<></>}
			style={{ textAlign: 'center' }}
		>
			<img src={question} alt="discrepancy" style={{ marginBottom: '1.5rem' }} />
			<p style={{ fontSize: '24px', color: '#02475E' }}>
				Have the discrepancies been resolved for {quoteWithDiscrepancy?.Insurer}?
			</p>

			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<FormCheck className="me-3">
					<FormCheck.Input
						type="radio"
						name="discrepancy-resolved"
						id="discrepancy-resolved-yes"
						onChange={() => setDiscrepancy(false)}
					/>
					<FormCheck.Label htmlFor="discrepancy-resolved-yes" className="ms-2">
						Yes
					</FormCheck.Label>
				</FormCheck>
				<div style={{ marginRight: '2rem' }} />
				<FormCheck>
					<FormCheck.Input
						type="radio"
						name="discrepancy-resolved"
						id="discrepancy-resolved-no"
						onChange={() => setDiscrepancy(true)}
					/>
					<FormCheck.Label htmlFor="discrepancy-resolved-no" className="ms-2">
						No
					</FormCheck.Label>
				</FormCheck>
			</div>
			<Button
				variant="primary"
				onClick={() => updateDiscrepancyStatus()}
				style={{
					color: 'white',
					background: '#02475E',
					border: 'none',
					width: '100%',
					height: '56px',
					marginTop: '1.5rem',
				}}
				disabled={hasDiscrepancy === null}
			>
				Continue
			</Button>
		</ModalComp>
	)

	useEffect(() => {
		const fetchData = async () => {
			setLoading({ status: true, loadingText: 'Loading quotes...' })
			const [quotes, siBreakdown, customerInfo] = await Promise.all([
				getQuoteList(rfqItem.id),
				getSumInsuredBreakdown(rfqItem.id),
				getCustomerInfo(rfqItem.id),
			])

			dispatch({
				type: 'SET_RFQ_QUOTES',
				data: quotes,
			})
			dispatch({
				type: 'SET_RFQ_SIBREAKDOWN',
				data: siBreakdown,
			})
			dispatch({
				type: 'SET_CUSTOMER_INFO',
				data: customerInfo,
			})
		}
		fetchData()
	}, [])

	const goBack = () => {
		dispatch({ type: 'RESET_CURRENT_RFQ_ITEM' })
		history.replace('/')
	}
	const quotesPresent = quoteList && quoteList.length > 0

	let title = 'Add quote information'
	if (quotesPresent) {
		title = 'Update quote information'
	}

	const addQuoteLinkPath = '/quote-update'
	const addQuoteDataToPass = {
		quoteActionType: 'add',
		rfqItem: rfqItem,
		quote: null,
		// quoteIndex: null,
	}

	const addQuoteLink = {
		pathname: addQuoteLinkPath,
		state: addQuoteDataToPass,
	}

	const allQuotesReceived = async () => {
		const updatedStatus = 'AGENT:ALL_QUOTES:PENDING'
		await updateRFQStatus(state.currentRfqItem.id, updatedStatus)
		dispatch({
			type: 'UPDATE_RFQ_STATUS',
			data: {
				id: state.currentRfqItem.id,
				Status: updatedStatus,
			},
		})
		setDoneAddingQuotes(true)
	}

	let renderQuotes = null

	if (quotesPresent) {
		renderQuotes = quoteList.map(quote => {
			return <QuoteCard key={quote.id} quote={quote} rfqStatus={rfqStatus} openModal={openModal} />
		})
	} else {
		renderQuotes = (
			<p className="font-weight-bold my-3" style={{ fontSize: '24px', color: '#C4C4C4' }}>
				Looks empty here :(, click on the add a new quote button to add a quote
			</p>
		)
	}
	if (quoteList == null) {
		return null
	}

	if (doneAddingQuotes) {
		const navigateToHome = () => history.replace('/')

		return <AddQuoteSuccess onNavigate={navigateToHome} />
	}

	const scroll = scrollOffset => {
		rfqInfoSectionRef.current.scrollLeft += scrollOffset
	}

	const Account = rfqItem?.CustomerInfo?.Account || {}
	const Contact = rfqItem?.CustomerInfo?.Contact || {}
	const RiskLocations = rfqItem?.CustomerInfo?.RiskLocations || []

	const riskLocationsList = RiskLocations?.map((location, index) => {
		const address = [
			location?.AddressLine1,
			location?.AddressLine2,
			location?.City,
			location?.State,
			location?.PIN,
		]
			.filter(Boolean)
			.join(', ')
		return (
			<InfoSection key={index}>
				<SectionHead>
					<Icon name="mapPinIcon" size="14px" className="mx-1" />
					Risk Location {index + 1}
				</SectionHead>
				<SectionValue>{address}</SectionValue>
			</InfoSection>
		)
	})

	const rfqInfoBar = (
		<div className="w-100 d-flex align-items-start justify-content-between">
			<div
				ref={rfqInfoSectionRef}
				className="d-flex align-items-start justify-content-start"
				style={{ overflowX: 'auto' }}
			>
				<InfoSection>
					<SectionHead>
						<Icon name="userIconOutline" size="14px" className="mx-1" />
						Proposer Name
					</SectionHead>
					<SectionValue>{Contact?.Name}</SectionValue>
				</InfoSection>

				<InfoSection>
					<SectionHead>
						<Icon name="briefCaseIcon" size="14px" className="mx-1" />
						Legal Entity Name
					</SectionHead>
					<SectionValue>{Account?.Name}</SectionValue>
				</InfoSection>

				<InfoSection>
					<SectionHead>
						<Icon name="email" color={theme.colors.gray3} size="14px" className="mx-1" />
						Email
					</SectionHead>
					<SectionValue>{Contact?.Email}</SectionValue>
				</InfoSection>

				<InfoSection>
					<SectionHead>
						<Icon name="phoneIcon" size="14px" className="mx-1" />
						Contact No
					</SectionHead>
					<SectionValue>{Contact?.Phone}</SectionValue>
				</InfoSection>

				<InfoSection>
					<SectionHead>
						<Icon name="phoneIcon" size="14px" className="mx-1" />
						WhatsApp No
					</SectionHead>
					<SectionValue>{Contact?.WaPhone}</SectionValue>
				</InfoSection>

				{riskLocationsList}
			</div>

			<div className="d-flex flex-column mx-2 align-items-center">
				<Icon
					style={{ cursor: 'pointer' }}
					name="editPencilSquare"
					color={theme.colors.secondary}
					onClick={() => setShowEditDetailsModal(true)}
				/>
				{RiskLocations?.length >= 3 && (
					<div className="d-flex align-items-center mt-3">
						<Icon
							style={{ cursor: 'pointer' }}
							className="p-2"
							name="leftArrow"
							color="#000"
							onClick={() => scroll(-100)}
						/>
						<Icon
							style={{ cursor: 'pointer' }}
							className="p-2"
							name="rightArrow"
							color="#000"
							onClick={() => scroll(100)}
						/>
					</div>
				)}
			</div>
		</div>
	)

	return (
		<>
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<div
					className="mx-3"
					style={{ fontSize: '18px', lineHeight: '23px', color: '#02475E', fontWeight: '700' }}
				>
					{policyTypeStrings(rfqItem?.Tag)}, {rfqItem?.id} - {rfqItem?.Name}
				</div>
			</TopNav>
			<TopNav
				style={{ padding: '.8rem', height: 'auto', marginTop: '1.5px' }}
				className="d-flex align-items-center"
			>
				{rfqInfoBar}
			</TopNav>
			<Container style={{ flex: 1 }}>
				<h5 className="my-3" style={{ fontSize: '24px', color: '#02475E' }}>
					{title}
				</h5>
				{renderQuotes}
				<Link to={addQuoteLink}>
					<Button
						variant="primary"
						style={{ color: '#fff', background: '#02475E', border: 'none', height: '48px' }}
					>
						<span className="me-2">+</span> Add a new quote
					</Button>
				</Link>
			</Container>
			<div style={enableBottomBanner ? bannerStyle : bannerDisabledStyle}>
				<p style={{ fontSize: '24px', color: 'white', fontWeight: 700 }}>
					Have all Quotes been received from the Insurers that the proposal was sent to?
				</p>
				<Button
					variant="light"
					disabled={!quoteList || (quoteList && quoteList.length == 0) || !enableBottomBanner}
					onClick={allQuotesReceived}
					style={{ fontSize: '18px', color: '#F78670', padding: '0.5rem' }}
				>
					Yes
				</Button>
			</div>
			{modal}

			{showEditDetailsModal && (
				<EditDetailsModal show={true} handleClose={() => setShowEditDetailsModal(false)} />
			)}
		</>
	)
}

const bannerStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem 1.5rem',
	width: '100%',
	background: 'linear-gradient(180deg, #D55C28 0%, #FF934F 100%)',
	marginTop: '2rem',
}
const bannerDisabledStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem 1.5rem',
	width: '100%',
	background: '#C4C4C4',
	marginTop: '2rem',
}

const QuoteTag = styled.ul`
	background: #fff5d5;
	border-radius: 40px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
	margin-bottom: 0;
	margin-right: 4px;
`
const QuoteText = styled.li`
	color: #ffb800;
	margin: 0px 4px;
	font-weight: bold;
`

const QuoteImg = styled.img`
	height: 60px;
	width: 100px;
	object-fit: contain;
`

export const SectionHead = styled(Text)`
	color: #999999;
	font-size: 14px;
	font-weight: 500;
	display: flex;
	align-items: center;
`

export const SectionValue = styled(Text)`
	color: #000;
	font-size: 14px;
	font-weight: 700;
	margin-top: 0.4rem;
`

export const InfoSection = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 2rem;
	max-width: 200px;
	min-width: 150px;
`

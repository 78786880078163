import { Table, OverlayTrigger, Spinner } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'
import { Button, Input, theme, Text, Icon } from 'verak-ui'
import moment from 'moment'

import FilterButton from './FilterButton'
import { useFetch } from '../../api/apihooks'
import { useLoadingOverlay } from '../../context/LoadingErrorProvider'
import { useContextApi } from '../../context/Provider'

import refresh from '../../assets/icons/refreshRed.svg'

const filterMenuItems = [
	{ text: 'None', value: '' },
	{ text: 'Policy issuance pending', value: 'Policy issuance pending' },
]

const statusStrings = {
	'OPS:RFQ_SUBMISSION:PENDING': 'Pending RFQ submisson',
	'OPS:QUOTES:PENDING': 'Quote updates pending',
	'AGENT:QUOTES:PENDING': 'Quote updates pending',
	'OPS:RFQ_RESUBMISSION:PENDING': 'Pending RFQ resubmission',
	'OPS:POLICY:PENDING': 'Follow up - Post Quote',
	'OPS:CLOSURE:PENDING': 'Follow up - Post Quote',
	'AGENT:ALL_QUOTES:PENDING': 'AGENT:ALL_QUOTES:PENDING',

	'UW:REVIEW:PENDING:AUTO': 'UW:REVIEW:PENDING:AUTO',
	'AGENT:UW_REQUIREMENTS:PENDING': 'AGENT:UW_REQUIREMENTS:PENDING',
	'AGENT:DISPUTES:ACTIVE': 'AGENT:DISPUTES:ACTIVE',
	'CLIENT:DISPUTES:RESOLVED': 'CLIENT:DISPUTES:RESOLVED',
	'AGENT:RFQ:ABANDONED': 'AGENT:RFQ:ABANDONED',
	'CLIENT:QUOTES:AVAILABLE': 'CLIENT:QUOTES:AVAILABLE',
	'CLIENT:PURCHASE:PENDING': 'CLIENT:PURCHASE:PENDING',
	'AGENT:ISSUANCE:PENDING': 'AGENT:ISSUANCE:PENDING',
	'AGENT:RFQ:PENDING': 'AGENT:RFQ:PENDING',
	'UW:REVIEW:PENDING': 'UW:REVIEW:PENDING',
	'CLIENT:VERIFICATION:PENDING': 'CLIENT:VERIFICATION:PENDING',
	'CLIENT:PAYMENT:PENDING': 'CLIENT:PAYMENT:PENDING',
	'AGENT:QUOTE:ABANDONED': 'AGENT:QUOTE:ABANDONED',
	'AGENT:SETTLEMENT:FAILED': 'Quote updates pending',
	'OPS:SETTLEMENT:PENDING': 'Settlement confirmation pending',
}

const getStatusString = item => {
	if (item.Quotes?.Outdated) {
		return 'Outdated quotes'
	}
	return statusStrings[item.Status]
}

const responseItemConfig = {
	ACCEPTED: { color: '#63D37F', icon: '✓' },
	REJECTED: { color: '#DC2F21', icon: 'x' },
	FURTHERREQ: { color: '#E3D345', icon: '+' },
	OUTDATED: { color: '#8D3AF6', icon: '↩' },
}

const getResponseSquare = type => (
	<div
		className="d-flex justify-content-center mx-1"
		style={{
			borderRadius: '2px',
			height: '24px',
			width: '24px',
			color: 'white',
			background: responseItemConfig[type].color,
		}}
	>
		{responseItemConfig[type].icon}
	</div>
)

const ResponseItem = ({ type, count, ...props }) => {
	return (
		<div {...props} className="d-flex align-items-center">
			{getResponseSquare(type)}

			<span
				className="h-100"
				style={{ marginLeft: '.8rem', color: responseItemConfig[type].color }}
			>
				{count}
			</span>
		</div>
	)
}

const getResponsesContainer = quotes => {
	return (
		<div className="d-flex align-items-start">
			<div className="d-flex flex-column">
				<ResponseItem type="ACCEPTED" count={quotes?.Accepted} />
				<ResponseItem style={{ marginTop: '.5rem' }} type="REJECTED" count={quotes?.Rejected} />
			</div>
			<div className="d-flex flex-column">
				<ResponseItem
					style={{ marginLeft: '.5rem' }}
					type="FURTHERREQ"
					count={quotes?.FurtherReq}
				/>
				<ResponseItem
					style={{ marginLeft: '.5rem', marginTop: '.5rem' }}
					type="OUTDATED"
					count={quotes?.Outdated}
				/>
			</div>
		</div>
	)
}

export default function OpsHome() {
	const { user } = useAuth0()
	const { getRfqList, initiateAndAssignRfq, generatePdf, searchRfqByQuery } = useFetch()

	const table = useRef()

	const history = useHistory()
	const [state, dispatch] = useContextApi()
	const [searchQuery, setSearchQuery] = useState('')
	const [searchResults, setSearchResults] = useState(null)

	const [currentCursor, setCurrentCursor] = useState(null) // to keep track of the last RFQ item's timestamp
	const [hasMore, setHasMore] = useState(true) // to indicate whether there are more items to load

	const [filterBy, setFilterBy] = useState('')

	// loading states
	const { loading, setLoading } = useLoadingOverlay()
	const [loadingMore, setLoadingMore] = useState(false) // to show loader at the bottom while loading more data

	const [timer, setTimer] = useState(null)

	const rfqs = state.rfqList

	const activeFilter = filterMenuItems.find(item => item.value === filterBy)?.text

	const fetchRfqList = async () => {
		if (rfqs.length) {
			setLoadingMore(true)
		} else {
			// display full screen spinner when fetching RFQs for the first time
			setLoading({ status: true, loadingText: 'Loading RFQ List' })
		}

		const result = await getRfqList(currentCursor, filterBy, false)
		setHasMore(result.hasMore)

		setLoadingMore(false)
		dispatch({ type: 'APPEND_RFQS', data: result.rfqs })
		setCurrentCursor(result.cursor)
	}

	const handleScroll = () => {
		const totalHeight = table?.current.firstChild.scrollHeight
		const scrolledTill = table?.current.scrollTop + table?.current?.offsetHeight - 16
		if (scrolledTill >= totalHeight && !loadingMore && hasMore) {
			fetchRfqList()
		}
	}

	useEffect(() => {
		fetchRfqList()
	}, [filterBy])

	useEffect(() => {
		table.current.addEventListener('scroll', handleScroll)
		return () => {
			table.current.removeEventListener('scroll', handleScroll)
		}
	}, [rfqs.length, loadingMore, currentCursor]) // eslint-disable-line

	const navigateTo = (route, locationState) => {
		history.push(route, locationState)
	}

	const handleRowClick = async rfqItem => {
		let updatedRfqItem = { ...rfqItem }

		// if the rfq wasn't initiated by any ops agent, then initiate and assign it to the current user
		if (!rfqItem.InitiatedBy) {
			await initiateAndAssignRfq(rfqItem.id)
			updatedRfqItem = { ...rfqItem, InitiatedBy: user.name }

			const rfqList = [...state.rfqList] || []
			// update the rfqList with the new rfqItem
			const toUpdate = rfqList.find(rfq => {
				return rfq.id === updatedRfqItem.id
			})
			toUpdate['InitiatedBy'] = user.name

			dispatch({ type: 'ADD_RFQS', data: rfqList })
		}

		dispatch({
			type: 'SELECT_RFQ',
			data: updatedRfqItem,
		})

		switch (updatedRfqItem.Status) {
			// quote will contain button to add further details
			case 'CLIENT:DISPUTES:RESOLVED':
				navigateTo('/client-disputes-fixed')
				break

			case 'OPS:RFQ_SUBMISSION:PENDING': // skiping rfq submission pending tab
			case 'OPS:RFQ_RESUBMISSION:PENDING':
			case 'AGENT:QUOTES:PENDING':
			case 'OPS:QUOTES:PENDING':
				navigateTo('/quotes')
				break
			case 'OPS:POLICY:PENDING':
			case 'OPS:CLOSURE:PENDING':
			case 'OPS:SETTLEMENT:PENDING':
				navigateTo('/follow-up-policy-bond')
				break
			case 'AGENT:RFQ:PENDING':
				break
			// do nothing
			default:
		}
	}

	const clickToStart = <span style={{ color: theme.colors.red }}>Click to start</span>

	const startDownload = fileUrl => {
		window.open(fileUrl, '_blank')
	}

	const downloadPdf = async (rfqId, template) => {
		// generate pdf and start download
		const pdf = await generatePdf(rfqId, template, `Loading ${template} pdf`)
		if (pdf?.fileUrl?.length > 0) {
			startDownload(pdf?.fileUrl)
		}
	}

	const getSingleTableRow = item => {
		return (
			<TableRow key={item.id} onClick={() => handleRowClick(item)}>
				<td>{item.Name}</td>
				<td>{item.Phone}</td>
				<td>{moment(item.updatedAt).format('D MMM YYYY, h:mm a')}</td>
				<td>{item.InitiatedBy || clickToStart}</td>
				<td>{getResponsesContainer(item.Quotes)}</td>
				<td>
					<Tag status={getStatusString(item)} />
				</td>
				<td>
					<div className="d-flex">
						<div className="action-icon">
							<ActionIcon
								name="documentIcon"
								iconColor="#000"
								className={'me-2 p-2 '}
								onClick={e => {
									downloadPdf(item.id, 'Rfq')
									e.stopPropagation()
								}}
							></ActionIcon>
							<span className="tooltiptext">Download RFQ Details</span>
						</div>

						<div className="action-icon">
							<ActionIcon
								name="userIconOutline"
								iconColor="#000"
								className={'me-2 p-2'}
								onClick={e => {
									downloadPdf(item.id, 'ClientDetails')
									e.stopPropagation()
								}}
							/>
							<span className="tooltiptext">Download Client Details</span>
						</div>
					</div>
				</td>
			</TableRow>
		)
	}

	let tableRows
	if (searchResults) {
		tableRows = searchResults.map(item => getSingleTableRow(item))
	} else {
		tableRows = state.rfqList.map(item => getSingleTableRow(item))
	}

	const handleSearch = event => {
		setSearchQuery(event.target.value)
		if (timer) {
			clearTimeout(timer)
		}
		const toSetTimer = setTimeout(() => {
			searchRfq(event.target.value)
		}, 1000)
		setTimer(toSetTimer)
	}

	const searchRfq = async searchValue => {
		if (searchValue) {
			searchRfqByQuery(searchValue).then(result => {
				if (result?.rfqs) {
					setSearchResults(result.rfqs)
				} else {
					setSearchResults([])
				}
			})
		} else {
			setSearchResults(null)
		}
	}

	const setFilterValue = updatedFilter => {
		if (filterBy !== updatedFilter) {
			setCurrentCursor('')
			setFilterBy(updatedFilter)
			dispatch({ type: 'CLEAR_RFQS' })
		}
	}

	return (
		<div style={{ height: '100%' }}>
			<div className="my-3 mx-2 d-flex align-items-center w-100">
				<StretchWidth>
					<SearchField
						value={searchQuery}
						onChange={handleSearch}
						placeholder="Search by RFQ ID, Name or Number"
						style={{ height: '48px' }}
					/>
				</StretchWidth>
				<FilterButton
					filterText="Filter by"
					disableLeftMargin
					selectedOption={activeFilter}
					setFilterValue={setFilterValue}
					menuItems={filterMenuItems}
				/>
			</div>
			{searchResults?.length === 0 ? (
				<NoResults />
			) : (
				<TableWrapper ref={table}>
					<RFQTable hover borderless>
						<TableHeader>
							<tr>
								<th>CLIENT NAME</th>
								<th>NUMBER</th>
								<th>LAST UPDATED</th>
								<th>INITIATED BY</th>
								<th>RESPONSES</th>
								<th>STATUS</th>
								<th>ACTION</th>
							</tr>
						</TableHeader>
						<tbody>{tableRows}</tbody>
					</RFQTable>
				</TableWrapper>
			)}

			{loadingMore && (
				<div className="d-flex flex-column align-items-center" style={{ height: '120px' }}>
					<Text className="mb-3">Loading more RFQs...</Text>
					<Spinner animation="border" />
				</div>
			)}
		</div>
	)
}

const NoResults = () => {
	return (
		<div style={{ background: '#f2f2f2' }} className="pt-3 w-100 d-flex justify-content-center">
			<Text color={theme.colors.gray3} fontSize="18px">
				No results
			</Text>
		</div>
	)
}

const Tag = ({ status }) => {
	let bgColor, textColor, border, icon

	if (status === 'Quote updates pending') {
		bgColor = '#F2994A'
		textColor = 'white'
		border = 'none'
	} else if (status === 'Pending RFQ resubmission') {
		icon = refresh
	} else if (status === 'Follow up - Post Quote' || status == 'Settlement confirmation pending') {
		bgColor = '#02475E'
		textColor = 'white'
		border = 'none'
	} else if (status === 'Outdated quotes') {
		bgColor = '#F0E5FE'
		textColor = '#8D3AF6'
		border = 'none'
	}
	return (
		<TagUI style={{ background: bgColor, color: textColor, border: border }}>
			{icon ? <img src={icon} alt="icon" style={{ paddingRight: '0.25rem' }} /> : null}
			{status}
		</TagUI>
	)
}

// temporary tag component
const TagUI = styled.span`
	background-color: #fadede;
	border: 1px solid #eb5757;
	border-radius: 8px;
	padding: 0.625rem;
	font-size: 12px;
	color: #eb5757;
	white-space: nowrap;
	display: inline-block;
`

// table styles
const TableDataItem = styled.td`
	color: #02475e !important;
	font-size: 14px;
	font-weight: 700;
	vertical-align: middle;
	padding-left: 16px !important;
`

const TableHeader = styled.thead`
	background: #e5eaec;
	position: sticky;
	top: 0;
	thead {
		vertical-align: middle;
	}
	th {
		color: #8d8d8d !important;
		font-size: 12px;
		font-weight: 700;
		padding: 16px !important;
	}
`

const ActionIcon = styled(Icon)`
	border-radius: 4px;
	&:hover {
		background-color: #c9c9c9;
	}
`

const TableRow = styled.tr`
	height: 55px;
	cursor: pointer;
	border-bottom: 1px solid #e5eaec;
`

const TableWrapper = styled.div`
	height: calc(100% - 48px - 2rem);
	overflow: auto;
`

const RFQTable = styled(Table)`
	background-color: #fff;
	border-radius: 5px;
	td {
		color: #02475e !important;
		font-size: 14px;
		font-weight: 700;
		vertical-align: middle;
	}
	.action-icon {
		position: relative;
	}
	.tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: black;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		/* Position the tooltip */
		position: absolute;
		z-index: 1;
		top: -5px;
		right: 105%;
	}

	.action-icon:hover .tooltiptext {
		visibility: visible;
	}
`

const SearchField = styled(Input)`
	outline: none;
	border: none;
	background: #ffffff;
	padding: 8px;
	width: 100%;
	/* Card-New */
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	&:hover {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	}
	&:focus {
		border: none;
	}
`

const StretchWidth = styled.div`
	width: 65%;
`

import { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, FormControl } from 'react-bootstrap'
import { HiddenFileInput, FileUploaded, Text, theme, Button, Icon } from 'verak-ui'
import styled from 'styled-components'
import Swal from 'sweetalert2'

import { useFetch } from '../../api/apihooks'
import { useContextApi } from '../../context/Provider'
import { policyTypeStrings } from '../../utils/getPolicyType'
import { parseLinkFromSignedUrl, imageDeleteConfirmOptions } from '../../utils/imageFunctions'
import UpdateSuccessComponent from '../CommonScreenComponents/UpdateSuccessComponent'
import EditDetailsModal from '../QuotesList/EditDetailsModal'
import { SectionHead, SectionValue, InfoSection } from '../QuotesList/QuotesList'

import CompletionCheck from '../../assets/icons/CompletionCheck.svg'
import BackArrow from '../../assets/icons/BackArrow.svg'
import envelope from '../../assets/icons/envelope.svg'
import download from '../../assets/icons/download.svg'
import PlusIconOrange from '../../assets/icons/plusIconOrange.svg'
import WhatsappIcon from '../../assets/icons/WhatsappIcon.svg'
import UploadedImage from '../../assets/uploadedImage.png'

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: toast => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})

const initialStepsState = {
	0: true,
	1: false,
}

export default function FollowUpSelectedQuote() {
	const [state, dispatch] = useContextApi()
	let fetch = useFetch()
	const history = useHistory()
	const quote = state?.currentRfqItem?.currentQuoteItem
	const otherData = {
		id: state?.currentRfqItem?.id,
		PolicyType: state?.currentRfqItem?.Tag?.toUpperCase(),
		ClientName: state?.currentRfqItem?.Name,
	}

	const settlementPending = state.currentRfqItem.Status === 'OPS:SETTLEMENT:PENDING'

	const { getQuoteList, settlePayment, getCustomerInfo } = useFetch()
	useEffect(() => {
		const fetchData = async () => {
			const result = await getQuoteList(state?.currentRfqItem?.id)

			if (result) {
				const selectedQuote = result.find(item => {
					return item.Selected
				})

				if (selectedQuote) {
					dispatch({
						type: 'SET_CURRENT_QUOTE_ITEM',
						data: selectedQuote,
					})
				}
			}

			dispatch({
				type: 'SET_RFQ_QUOTES',
				data: result,
			})
		}
		fetchData()
	}, [])

	const saveUTRNo = async () => {
		const result = await settlePayment(state?.currentRfqItem?.id, UTRNo)
		if (!result.error) {
			dispatch({
				type: 'UPDATE_RFQ_STATUS',
				data: {
					id: state?.currentRfqItem?.id,
					Status: 'OPS:POLICY:PENDING',
				},
			})
		}
	}

	const [steps, setSteps] = useState(initialStepsState)
	const [waConfirmationSent, setWaConfirmationSent] = useState(false)
	const [policyBondStartDate, setPolicyBondStartDate] = useState('')
	const [uploadedFile, setUploadedFile] = useState('')
	const [primaryPolicyNum, setPrimaryPolicyNum] = useState('')
	const [UTRNo, setUTRNo] = useState('')
	const [addOnPolicyNum, setAddOnPolicyNum] = useState({})

	const [activeScreen, setActiveScreen] = useState('steps-screen')
	const [showEditDetailsModal, setShowEditDetailsModal] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const customerInfo = await getCustomerInfo(rfqItem.id)
			dispatch({
				type: 'SET_CUSTOMER_INFO',
				data: customerInfo,
			})
		}
		fetchData()
	}, [])

	const rfqInfoSectionRef = useRef(null)

	const scroll = scrollOffset => {
		rfqInfoSectionRef.current.scrollLeft += scrollOffset
	}

	const rfqItem = state?.currentRfqItem
	const Account = rfqItem?.CustomerInfo?.Account || {}
	const Contact = rfqItem?.CustomerInfo?.Contact || {}
	const RiskLocations = rfqItem?.CustomerInfo?.RiskLocations || []

	const riskLocationsList = RiskLocations?.map((location, index) => {
		const address = [
			location?.AddressLine1,
			location?.AddressLine2,
			location?.City,
			location?.State,
			location?.PIN,
		]
			.filter(Boolean)
			.join(', ')
		return (
			<InfoSection key={index}>
				<SectionHead>
					<Icon name="mapPinIcon" size="14px" className="mx-1" />
					Risk Location {index + 1}
				</SectionHead>
				<SectionValue>{address}</SectionValue>
			</InfoSection>
		)
	})

	// getting RFQ files
	const [rfqFiles, setRfqFiles] = useState(null)
	const fetchRFQData = async () => {
		const id = state?.currentRfqItem?.id
		const result = await fetch.getRfqFiles(id)
		if (result) {
			setRfqFiles(result)
			if (result['PaymentProof']?.['ExternalRef']) {
				setUTRNo(result['PaymentProof']?.['ExternalRef'])
			}
		}
	}
	useEffect(() => {
		fetchRFQData()
	}, [])

	useEffect(() => {
		if (policyBondStartDate && uploadedFile) {
			setSteps({ ...steps, 1: true })
		}
	}, [policyBondStartDate, uploadedFile])

	const sendWhatsappConfirmation = async () => {
		await fetch.sendPaymentConfirmation(state?.currentRfqItem?.id)
		Toast.fire({
			icon: 'success',
			title: 'Payment confirmation sent',
			showConfirmButton: false,
			timer: 1500,
			position: 'top-end',
		})
		setSteps({ ...steps, 1: true })
		setWaConfirmationSent(true)
	}

	const bondFileInput = useRef()

	const disableStep2 = settlementPending
	const step2Completed = Boolean(policyBondStartDate && uploadedFile)
	const enableSubmit = step2Completed && primaryPolicyNum

	const isFinancedRFQ = rfqFiles?.PaymentProof?.Type == 'PREMIUM_FINANCING'

	// ref: https://stackoverflow.com/questions/50795042/create-a-copy-button-without-an-input-text-box/50795833
	const copyValue = (event, value, text) => {
		event.stopPropagation()

		const textArea = document.createElement('textarea')
		textArea.value = value
		document.body.appendChild(textArea)
		textArea.select()
		document.execCommand('copy')
		document.body.removeChild(textArea)

		Toast.fire({
			icon: 'success',
			title: `${text} copied to clipboard`,
		})
	}

	const handleSubmit = async () => {
		const date = policyBondStartDate.split('-')
		let formattedDate = `${date[2]}/${date[1]}/${date[0]}`
		const payload = {
			policy: uploadedFile,
			startDate: formattedDate,
			policyNum: {
				Primary: primaryPolicyNum,
				Addons: {
					...addOnPolicyNum,
				},
			},
		}

		const result = await fetch.uploadClientPolicy(otherData?.id, payload)
		if (result) {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'UPDATE_RFQ_STATUS',
					data: {
						id: otherData?.id,
						Status: 'OPS:CLOSURE:PENDING',
					},
				})
				resolve()
			})

			setActiveScreen('success-screen')
		}
	}

	// TODO: fix this***
	const goBack = () => {
		history.goBack()
	}

	const handleFileUpload = async file => {
		if (file) {
			// setLoadingText('Uploading...')
			// setIsLoading(true)

			const uploadUrl = await fetch.getSignedUrl(otherData?.id, 'application/pdf')
			const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file[0])
			const s3Link = parseLinkFromSignedUrl(uploadResult?.url)

			// setIsLoading(false)
			setUploadedFile(s3Link)
		}
	}

	const removeUploadedFile = file => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				setUploadedFile('')

				Swal.fire('Your file has been deleted')
			}
		})
	}

	let doesUTRExist
	if (rfqFiles) {
		doesUTRExist = rfqFiles['PaymentProof']?.['ExternalRef']
	}

	const updateAddonsPolicyNo = (addonName, value) => {
		setAddOnPolicyNum(aValue => {
			return {
				...aValue,
				[addonName]: value,
			}
		})
	}

	let addOnPolicyNo = state?.currentRfqItem.currentQuoteItem?.Addons.map(aItem => {
		return (
			<div key={aItem.Name}>
				<Text color={theme.colors.gray2} fontWeight="400" fontSize={'14px'} className="mb-2">
					{aItem.Name} - Policy Number (Optional)
				</Text>
				<FormControl
					onChange={e => updateAddonsPolicyNo(aItem.Name, e.target.value)}
					className="mb-3"
					style={{ width: '350px', height: '48px' }}
					placeholder="Please enter value"
					disabled={disableStep2}
				/>
			</div>
		)
	})

	const rfqInfoBar = (
		<div className="w-100 d-flex align-items-start justify-content-between">
			<div
				ref={rfqInfoSectionRef}
				className="d-flex align-items-start justify-content-start"
				style={{ overflowX: 'auto' }}
			>
				<InfoSection>
					<SectionHead>
						<Icon name="userIconOutline" size="14px" className="mx-1" />
						Proposer Name
					</SectionHead>
					<SectionValue>{Contact?.Name}</SectionValue>
				</InfoSection>

				<InfoSection>
					<SectionHead>
						<Icon name="briefCaseIcon" size="14px" className="mx-1" />
						Legal Entity Name
					</SectionHead>
					<SectionValue>{Account?.Name}</SectionValue>
				</InfoSection>

				<InfoSection>
					<SectionHead>
						<Icon name="email" color={theme.colors.gray3} size="14px" className="mx-1" />
						Email
					</SectionHead>
					<SectionValue>{Contact?.Email}</SectionValue>
				</InfoSection>

				<InfoSection>
					<SectionHead>
						<Icon name="phoneIcon" size="14px" className="mx-1" />
						Contact No
					</SectionHead>
					<SectionValue>{Contact?.Phone}</SectionValue>
				</InfoSection>

				<InfoSection>
					<SectionHead>
						<Icon name="phoneIcon" size="14px" className="mx-1" />
						WhatsApp No
					</SectionHead>
					<SectionValue>{Contact?.WaPhone}</SectionValue>
				</InfoSection>

				{riskLocationsList}
			</div>

			<div className="d-flex flex-column mx-2 align-items-center">
				<Icon
					style={{ cursor: 'pointer' }}
					name="editPencilSquare"
					color={theme.colors.secondary}
					onClick={() => setShowEditDetailsModal(true)}
				/>
				{RiskLocations?.length >= 3 && (
					<div className="d-flex align-items-center mt-3">
						<Icon
							style={{ cursor: 'pointer' }}
							className="p-2"
							name="leftArrow"
							color="#000"
							onClick={() => scroll(-100)}
						/>
						<Icon
							style={{ cursor: 'pointer' }}
							className="p-2"
							name="rightArrow"
							color="#000"
							onClick={() => scroll(100)}
						/>
					</div>
				)}
			</div>
		</div>
	)

	const stepsScreen = rfqFiles ? (
		<>
			{showEditDetailsModal && (
				<EditDetailsModal show={true} handleClose={() => setShowEditDetailsModal(false)} />
			)}

			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<Text className="mx-3" fontSize={'18px'} color={theme.colors.primary} fontWeight="700">
					{policyTypeStrings(otherData?.PolicyType)}, {otherData?.id} - {otherData?.ClientName}
				</Text>
			</TopNav>

			<TopNav
				style={{ padding: '.8rem', height: 'auto', marginTop: '1.5px' }}
				className="d-flex align-items-center"
			>
				{rfqInfoBar}
			</TopNav>

			<Container>
				<Text color={theme.colors.primary} type="h5" className="my-3">
					Client has paid for {quote?.Insurer}'s {policyTypeStrings(otherData?.PolicyType)} policy
				</Text>

				<Text color={theme.colors.gray} className="mb-3">
					Purchase confirmation proof provided by the client has been sent across to{' '}
					{quote?.Insurer}. Please follow-up for the policy bond.
				</Text>
				<StepCardWrapper>
					<div className="w-100 d-flex justify-content-between">
						<Text color={theme.colors.primary} fontWeight="700" fontSize={'16px'} className="mb-3">
							Supporting documents
						</Text>

						<div className="d-flex flex-column">
							<CTA link={rfqFiles['Zip']} label="RFQ & attachments" />
							<CTA link={rfqFiles['ClientDetails']} label="Client details" />
							<CTA link={rfqFiles['PAN']} label="Client PAN Card copy" />
							{rfqFiles['PaymentProof']?.['Image'] ? (
								<CTA link={rfqFiles['PaymentProof']?.['Image']} label="Payment confirmation" />
							) : null}
							<CTA link={rfqFiles['GSTIN']} label="GST Certificate" />

							<ClipboardCTA copyValue={copyValue} label={quote?.id} toastText="Quote ID" />
							{doesUTRExist && !isFinancedRFQ ? (
								<ClipboardCTA copyValue={copyValue} label={doesUTRExist} toastText="UTR Number" />
							) : null}
						</div>
					</div>
				</StepCardWrapper>

				<StepCard statusComplete={true}>
					<div className="w-100 d-flex justify-content-between">
						<div className="d-flex flex-column">
							<Text color={theme.colors.primary} fontWeight="700" className="mb-3" fontSize="16px">
								Payment Confirmation for customer
							</Text>
						</div>

						<PrimaryButton
							style={{
								justifyContent: 'flex-start',
								background: waConfirmationSent ? '#75CC1F' : theme.colors.primary,
								color: 'white',
							}}
							onClick={sendWhatsappConfirmation}
						>
							<img
								src={waConfirmationSent ? envelope : WhatsappIcon}
								alt="whatsapp-confirmation"
								style={{ marginRight: '0.625rem' }}
							/>
							{waConfirmationSent
								? 'Comfirmation sent on whatsapp!!'
								: 'Send confirmation to client'}
						</PrimaryButton>
					</div>
				</StepCard>

				<StepCard statusComplete={step2Completed}>
					<div className="w-100 d-flex flex-column">
						<Text color={theme.colors.primary} fontWeight="700" className="mb-3" fontSize="18px">
							Upload Policy bond when received from the insurer
						</Text>

						{/* <input
							onChange={uploadPolicyBond}
							accept="application/pdf"
							ref={bondFileInput}
							type="file"
							style={{ display: 'none' }}
						/> */}

						<Text color={theme.colors.gray2} fontWeight="400" fontSize={'14px'} className="mb-2">
							Enter policy start date as mentioned in the policy bond{' '}
							<span style={{ color: theme.colors.red }}>*</span>
						</Text>
						<FormControl
							onChange={e => setPolicyBondStartDate(e.target.value)}
							className="mb-3"
							style={{ width: '350px', height: '48px' }}
							type="date"
							placeholder="Please enter DD/MM/YYYY format"
							disabled={disableStep2}
						/>

						<Text color={theme.colors.gray2} fontWeight="400" fontSize={'14px'} className="mb-2">
							Fire - Policy Number <span style={{ color: theme.colors.red }}>*</span>
						</Text>
						<FormControl
							value={primaryPolicyNum}
							onChange={e => setPrimaryPolicyNum(e.target.value)}
							className="mb-3"
							style={{ width: '350px', height: '48px' }}
							placeholder="Please enter value"
							disabled={disableStep2}
						/>

						{settlementPending ? (
							<>
								<Text
									color={theme.colors.gray2}
									fontWeight="400"
									fontSize={'14px'}
									className="mb-2"
								>
									UTR Number <span style={{ color: theme.colors.red }}>*</span>
								</Text>
								<div className="d-flex align-items-center mb-3">
									<FormControl
										value={UTRNo}
										onChange={e => setUTRNo(e.target.value)}
										className="me-3"
										style={{ width: '350px', height: '48px' }}
										placeholder="Please enter value"
									/>
									<Button
										label="Save UTR Number"
										icon={'save'}
										disabled={!UTRNo}
										onClick={saveUTRNo}
										iconStyles={{ marginRight: '8px' }}
										style={{ height: '48px' }}
									></Button>
								</div>
							</>
						) : null}

						{addOnPolicyNo}

						<HiddenFileInput
							accept="application/pdf"
							ref={bondFileInput}
							onChange={e => handleFileUpload(e.target.files)}
						/>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<CustomUploadButton
								onClick={() => bondFileInput.current.click()}
								disabled={disableStep2}
							>
								<img src={PlusIconOrange} alt="Upload" />
							</CustomUploadButton>
							{uploadedFile ? (
								<FileUploaded
									imageUrl={uploadedFile}
									onDelete={removeUploadedFile}
									style={{ height: '48px' }}
									thumbnail={UploadedImage}
								/>
							) : null}
						</div>
					</div>
				</StepCard>

				<SubmitButton onClick={handleSubmit} disabled={!enableSubmit}>
					Submit details
				</SubmitButton>
			</Container>
		</>
	) : null

	const goToHome = () => history.push('/')

	const successScreen = <UpdateSuccessComponent continueButtonOnclick={goToHome} />

	let activeSection = activeScreen === 'steps-screen' ? stepsScreen : successScreen

	return activeSection
}

const StepCard = ({ statusComplete = false, children }) => {
	return (
		<StepCardWrapper>
			<div className="d-flex flex-column align-items-center justify-content-start">
				<CompletionCircle statusComplete={statusComplete}>
					{statusComplete && <img src={CompletionCheck} alt="check" />}
				</CompletionCircle>
				<CompletionLine statusComplete={statusComplete} />
			</div>
			{children}
		</StepCardWrapper>
	)
}

const CTA = ({ link, label }) => {
	return (
		<a href={link} target="_blank">
			<PrimaryButton>
				{label}
				<img src={download} alt="download" />
			</PrimaryButton>
		</a>
	)
}
const ClipboardCTA = ({ copyValue, label, toastText }) => {
	return (
		<PrimaryButton
			onClick={e => {
				copyValue(e, label, toastText)
			}}
			style={clipboardStyle}
			className="d-flex justify-content-between"
		>
			{label}
			<span style={{ marginLeft: '4px', fontSize: '10px' }}>Copy</span>
		</PrimaryButton>
	)
}

// styles
const CustomUploadButton = styled.button`
	background-color: #fff;
	width: 350px;
	height: 48px;
	margin-top: 1rem;
	border: 2px dashed #8d8d8d;
	border-radius: 8px;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const PrimaryButton = styled.button`
	background: #c8d6db;
	border-radius: 8px;
	border: none;
	padding: 1rem;
	margin: 0.25rem 0;
	font-size: 15px;
	color: #02475e;
	font-weight: 700;
	min-height: 40px;
	min-width: 320px;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	:hover {
		background-color: #0d6efd;
		color: white;
	}
`
const SubmitButton = styled(PrimaryButton)`
	background: ${({ disabled }) => (disabled ? '#C4C4C4' : '#02475e')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	color: white;
	margin-bottom: 4rem;
	:hover {
		background: ${({ disabled }) => (disabled ? '#C4C4C4' : '#02475e')};
	}
`

const clipboardStyle = {
	fontSize: '14px',
	background: 'white',
	color: '#02475E',
	border: '1px solid #C8D6DB',
}

// temporary secondary navbar
const TopNav = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
`

// step card
const StepCardWrapper = styled.div`
	display: flex;
	background-color: #fff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 1rem;
	margin-bottom: 2rem;
`

const CompletionCircle = styled.div`
	height: 35px;
	width: 35px;
	border-radius: 50%;
	border: ${({ statusComplete }) => (statusComplete ? 'none' : '4px solid #8d8d8d')};
	margin-right: 1rem;
`

const CompletionLine = styled.div`
	height: 20px;
	border-left: ${({ statusComplete }) =>
		statusComplete ? '4px solid #75CC1F' : '4px solid #ECECEC'};
	margin-right: 1rem;
`

// package imports
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'

// components
import Navbar from './components/Navbar'
import SidePanel from './components/SidePanel'

// screens
import Home from './screens/Home'
import RFQSubmission from './screens/PendingRFQ/RFQSubmission'
import RFQResubmission from './screens/PendingRFQ/RFQResubmission'

import ClientDisputesFixed from './screens/ClientDisputes/ClientDisputesFixed'

import QuotesList from './screens/QuotesList/QuotesList'
import QuoteHandler from './screens/QuoteUpdatesPending/QuoteHandler'

import SendRejectionEmails from './screens/FollowUp/SendRejectionEmails'
import FollowUpSelectedQuote from './screens/FollowUp/FollowUpSelectedQuote'

import LeadTransfer from './screens/LeadTransfer'
import DataCenter from './screens/DataCenter/DataCenter'
import UploadOvr from './screens/DataCenter/UploadOvr'
// auth
import ProtectedRoute from './auth/protected-route'

// context
import LoadingOverlay from './context/LoadingErrorProvider'
import { LoadingOverlayProvider } from './context/LoadingErrorProvider'

function App() {
	const { isAuthenticated } = useAuth0()
	return (
		<BrowserRouter>
			<LoadingOverlayProvider>
				<SidePanel />
				<Navbar isAuthenticated={isAuthenticated} />
				<AppContainer>
					<Switch>
						<Route path="/" exact={true} component={Home} />
						<Route path="/logout" exact={true} component={Home} />
						<ProtectedRoute path="/rfq-submission-pending" exact={true} component={RFQSubmission} />
						<ProtectedRoute
							path="/rfq-resubmission-pending"
							exact={true}
							component={RFQResubmission}
						/>
						<ProtectedRoute
							path="/client-disputes-fixed"
							exact={true}
							component={ClientDisputesFixed}
						/>
						<ProtectedRoute
							path="/send-rejection-emails"
							exact={true}
							component={SendRejectionEmails}
						/>
						<ProtectedRoute
							path="/follow-up-policy-bond"
							exact={true}
							component={FollowUpSelectedQuote}
						/>
						<ProtectedRoute path="/quotes" component={QuotesList} />
						<ProtectedRoute path="/quote-update" component={QuoteHandler} />
						<ProtectedRoute path="/lead-transfer" component={LeadTransfer} />
						<ProtectedRoute path="/data-center" component={DataCenter} />
						<ProtectedRoute path="/upload-ovr" exact={true} component={UploadOvr} />
					</Switch>
				</AppContainer>
				<LoadingOverlay />
			</LoadingOverlayProvider>
		</BrowserRouter>
	)
}
export default App

const AppContainer = styled.div`
	padding: 0 0 0 80px;
	height: Calc(100% - 64px);
	display: flex;
	flex-direction: column;
`

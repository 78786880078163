import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router'
import { Button, Container } from 'react-bootstrap'
import { useState } from 'react'
import Swal from 'sweetalert2'
import { useContextApi } from '../../context/Provider'
import { useFetch } from '../../api/apihooks'
import { policyTypeStrings } from '../../utils/getPolicyType'

import BackArrow from '../../assets/icons/BackArrow.svg'

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: toast => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})

const initialStepsState = {
	0: false,
	1: false,
	2: false,
	3: false,
}

export default function ClientDisputesResolved() {
	const history = useHistory()

	const [state, dispatch] = useContextApi()
	const { updateRFQStatus, downloadRFQArchive, downloadRfqPdf } = useFetch()

	const rfqItem = state?.currentRfqItem
	const [steps, setSteps] = useState(initialStepsState)

	let downloadsComplete = {
		0: false,
		1: false,
	}

	const goBack = () => history.goBack()

	const completeStep = stepIndex => {
		setSteps({ ...steps, [stepIndex]: true })
	}

	const triggerPdfDownload = async () => {
		const result = await downloadRfqPdf(rfqItem.id)
		if (result?.Pdf) {
			const downloadLink = document.createElement('a')
			document.body.appendChild(downloadLink)
			downloadLink.setAttribute('href', result.Pdf)
			downloadLink.setAttribute('target', '_blank')
			downloadLink.setAttribute('download', 'RFQ.pdf')
			downloadLink.click()
			downloadLink.remove()
		}
	}

	const downloadItem = async index => {
		if (index == 0) {
			await downloadRFQArchive(rfqItem.id)
		} else if (index == 1) {
			await triggerPdfDownload()
		}
		downloadsComplete[index] = true

		if (downloadsComplete[0] && downloadsComplete[1]) {
			setSteps({ ...steps, 0: true, 1: true }) // complete step 1 and 2
		}
	}

	const disableStep3 = !steps[0] || !steps[1]
	const disableStep4 = !steps[0] || !steps[1] || !steps[2]

	// TODO: temp values, replace with dynamic values
	const date = '26 Nov'
	const time = '4pm'

	const updateState = async () => {
		const updatedStatus = 'AGENT:QUOTES:PENDING'

		await updateRFQStatus(rfqItem.id, updatedStatus)

		dispatch({
			type: 'UPDATE_RFQ_STATUS',
			data: {
				id: rfqItem.id,
				Status: updatedStatus,
			},
		})
		// navigate to home
		history.goBack()
	}

	return (
		<>
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<div
					className="mx-3"
					style={{ fontSize: '18px', lineHeight: '23px', color: '#02475E', fontWeight: '700' }}
				>
					{policyTypeStrings(rfqItem?.Tag)}, {rfqItem?.id} - {rfqItem?.Name}
				</div>
			</TopNav>

			<Container>
				<h4 className="my-3">
					Changes have been raised, please communicate all changes to the respective insurers by{' '}
					{date},{time} in the previous email thread used.
				</h4>

				<StepCard statusComplete={steps[0]}>
					<div className="w-100 d-flex justify-content-between">
						<div className="d-flex flex-column">
							<h5>Step 1</h5>
							<p>Download all documents</p>
						</div>

						<div className="d-flex flex-column">
							<CustomButton className="mb-2" onClick={() => downloadItem(0)}>
								RFQs and attachments
							</CustomButton>

							<CustomButton onClick={() => downloadItem(1)}>PDF with updated changes</CustomButton>
						</div>
					</div>
				</StepCard>

				<StepCard statusComplete={steps[1]}>
					<div className="w-100 d-flex justify-content-between">
						<div className="d-flex flex-column">
							<h5>Step 2</h5>
							<p style={{ color: '#eb5757' }}>
								Reply with these documents in the previous email thread
							</p>
						</div>
					</div>
				</StepCard>

				<StepCard statusComplete={steps[2]}>
					<div className="w-100 d-flex justify-content-between">
						<div className="d-flex flex-column">
							<h5>Step 3</h5>
							<p>Confirm that the emails have been sent out</p>
						</div>

						<CustomButton disabled={disableStep3} onClick={() => completeStep(2)}>
							Confirm email is sent
						</CustomButton>
					</div>
				</StepCard>

				<StepCard statusComplete={steps[3]}>
					<div className="w-100 d-flex justify-content-between">
						<div className="d-flex flex-column">
							<h5>Step 4</h5>
							<p>Update the quotes received from insurance companies</p>
						</div>

						<CustomButton disabled={disableStep4} onClick={updateState}>
							Update quotes
						</CustomButton>
					</div>
				</StepCard>
			</Container>
		</>
	)
}

const StepCard = ({ statusComplete = false, children }) => {
	return (
		<StepCardWrapper>
			<CompletionCircle statusComplete={statusComplete}>
				{/* show tick icon for completed status */}
			</CompletionCircle>

			{children}
		</StepCardWrapper>
	)
}

// styles
const CustomButton = styled(Button)`
	height: 48px;
	display: flex;
	align-items: center;
	background: ${({ disabled }) => (disabled ? '#C4C4C4 !important' : '#02475E')};
`

// temporary secondary navbar
const TopNav = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
`

// step card
const StepCardWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 1rem;
	margin-bottom: 2rem;
`

const CompletionCircle = styled.div`
	height: 35px;
	width: 35px;
	border-radius: 50%;
	border: ${({ statusComplete }) => (statusComplete ? 'none' : '4px solid #8d8d8d')};
	background: ${({ statusComplete }) => (statusComplete ? '#75CC1F' : '#fff')};
	margin-right: 1rem;
`

import styled from 'styled-components'
import { useHistory } from 'react-router'

import { theme, Button } from 'verak-ui'

const btnIconStyle = {
	marginRight: '.7rem',
}

export default function DataCenter() {
	const history = useHistory()
	const navigateTo = route => {
		history.push(route)
	}
	return (
		<div className="container p-3">
			<CustomFlex className="d-flex">
				<CustomButton
					iconStyles={btnIconStyle}
					icon="newQuote"
					label="Upload OVR"
					onClick={() => navigateTo('/upload-ovr')}
				/>
			</CustomFlex>
		</div>
	)
}

// components
const CustomFlex = styled.div`
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
`

const CustomButton = styled(Button)`
	justify-content: flex-start;
	align-items: center;
	height: 64px;
	padding: 16px;
	background: white;
	color: ${theme.colors.primary};
	width: min(328px, 90%);
	margin-right: 24px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	opacity: ${({ disabled }) => (disabled ? '.3' : '1')};
	&:hover {
		color: white;
	}
	@media (max-width: 768px) {
		margin: 0px;
		margin-bottom: 1rem;
	}
`

import { Button, Modal, Text, theme, Input, Accordion } from 'verak-ui'
import styled from 'styled-components'
import { useState } from 'react'
import { useContextApi } from '../../context/Provider'
import { useFetch } from '../../api/apihooks'

const removeCountryCode = phoneNumber => {
	if (phoneNumber?.startsWith('+91')) {
		return phoneNumber.replace('+91', '')
	}
	return phoneNumber
}

export default function EditDetailsModal({ show, handleClose }) {
	const { updateCustomerInfo } = useFetch()
	const [state, dispatch] = useContextApi()

	const rfqItem = state?.currentRfqItem
	const { Account, Contact, RiskLocations: riskLocationAddresses } = rfqItem?.CustomerInfo

	const [ProposerName, setProposerName] = useState(Contact?.Name || '')
	const [LegalEntityName, setLegalEntityName] = useState(Account?.Name || '')
	const [Phone, setPhone] = useState(removeCountryCode(Contact?.Phone) || '')
	const [Email, setEmail] = useState(Contact?.Email || '')
	const [WaPhone, setWaPhone] = useState(removeCountryCode(Contact?.WaPhone) || '')
	const [RiskLocations, setRiskLocations] = useState(() => {
		return [...riskLocationAddresses]
	})
	const [changedAddresses, setChangedAddresses] = useState([])
	const [contactDetailsChanged, setContactDetailsChanged] = useState(false)

	const handleRiskLocationDataChange = (key, value, locationId) => {
		const riskLocationsCopy = [...RiskLocations]
		const locationToUpdate = riskLocationsCopy.find(riskLocation => riskLocation.ID === locationId)
		locationToUpdate[key] = value

		// add to updated risk locations
		if (!changedAddresses.includes(locationId)) {
			setChangedAddresses([...changedAddresses, locationId])
		}
		setRiskLocations([...riskLocationsCopy])
	}

	const riskLocationsList = RiskLocations.map((riskLocation, index) => {
		return {
			id: `risk_location_${riskLocation?.ID}`,
			header: `Risk Location ${index + 1}`,
			content: (
				<div>
					<Input
						className="w-100 mb-3"
						label="Address Line 1"
						value={riskLocation?.AddressLine1}
						onChange={e =>
							handleRiskLocationDataChange('AddressLine1', e.target.value, riskLocation?.ID)
						}
					/>
					<Input
						className="w-100 mb-3"
						label="Address Line 2"
						value={riskLocation?.AddressLine2}
						onChange={e =>
							handleRiskLocationDataChange('AddressLine2', e.target.value, riskLocation?.ID)
						}
					/>
					<Input
						className="w-100 mb-3"
						label="City"
						value={riskLocation?.City}
						onChange={e => handleRiskLocationDataChange('City', e.target.value, riskLocation?.ID)}
					/>
					<Input
						className="w-100"
						label="State"
						value={riskLocation?.State}
						onChange={e => handleRiskLocationDataChange('State', e.target.value, riskLocation?.ID)}
					/>
				</div>
			),
		}
	})

	const getUpdatedDetails = () => {
		let updatedDetails = {}

		if (contactDetailsChanged) updatedDetails.Contact = {}

		if (ProposerName !== Contact?.Name) {
			updatedDetails.Contact.Name = ProposerName
		}
		if (LegalEntityName !== Account?.Name) {
			if (!updatedDetails?.Account) updatedDetails.Account = {}
			updatedDetails.Account.Name = LegalEntityName
		}
		if (Phone !== removeCountryCode(Contact?.Phone) && Phone.length === 10) {
			updatedDetails.Contact.Phone = `+91${Phone}`
		}
		if (WaPhone !== removeCountryCode(Contact?.WaPhone) && WaPhone.length === 10) {
			updatedDetails.Contact.WaPhone = `+91${WaPhone}`
		}
		if (Email !== Contact?.Email) {
			updatedDetails.Contact.Email = Email
		}
		if (changedAddresses.length > 0) {
			updatedDetails['RiskLocations'] = RiskLocations.filter(location =>
				changedAddresses.includes(location?.ID)
			)
		}
		return updatedDetails
	}

	const saveChanges = async () => {
		const updatedDetails = getUpdatedDetails()
		if (Object.keys(updatedDetails).length > 0) {
			const result = await updateCustomerInfo(rfqItem?.id, updatedDetails)

			if (result) {
				let rfqListToUpdate = {}

				// update global state
				await new Promise(resolve => {
					let updatedDataPayload = {
						...rfqItem,
						CustomerInfo: {
							Contact: {
								...rfqItem.CustomerInfo.Contact,
								...updatedDetails.Contact,
							},
							Account: {
								...rfqItem.CustomerInfo.Account,
								...updatedDetails.Account,
							},
						},
					}

					const legalEntityName = updatedDetails?.Account?.Name
					const phoneNumber = updatedDetails?.Contact?.Phone

					// if legal entity name or phone number changed
					if (legalEntityName || phoneNumber) {
						if (legalEntityName) {
							updatedDataPayload = {
								...updatedDataPayload,
								Name: legalEntityName,
							}
							rfqListToUpdate = { Name: legalEntityName }
						}

						if (phoneNumber) {
							updatedDataPayload = {
								...updatedDataPayload,
								Phone: phoneNumber,
							}
							rfqListToUpdate = { ...rfqListToUpdate, Phone: phoneNumber }
						}

						rfqListToUpdate = { ...rfqListToUpdate, id: rfqItem.id }
					}

					if (updatedDetails?.RiskLocations?.length > 0) {
						updatedDataPayload = {
							...updatedDataPayload,
							CustomerInfo: {
								...updatedDataPayload.CustomerInfo,
								RiskLocations: [...RiskLocations],
							},
						}
					}

					dispatch({
						type: 'SET_RFQ_ITEM',
						data: updatedDataPayload,
					})
					resolve()
				})

				// update rfq list
				if (Object.keys(rfqListToUpdate).length > 0) {
					await new Promise(resolve => {
						dispatch({
							type: 'UPDATE_RFQ_FROM_LIST',
							data: rfqListToUpdate,
						})
						resolve()
					})
				}
			}
		}
		handleClose()
	}

	const handleContactDetailsChange = (key, value) => {
		switch (key) {
			case 'ProposerName':
				setProposerName(value)
				break
			case 'Phone':
				setPhone(value)
				break
			case 'WaPhone':
				setWaPhone(value)
				break
			case 'Email':
				setEmail(value)
				break
			default:
				break
		}
		if (!contactDetailsChanged) {
			setContactDetailsChanged(true)
		}
	}

	return (
		<Modal
			style={{ zIndex: '1050' }}
			show={show}
			handleClose={handleClose}
			title={
				<Text color={theme.colors.primary} fontSize="14px" fontWeight="700">
					Edit Details
				</Text>
			}
			footer={<></>}
		>
			<Input
				className="w-100 mb-3"
				label="Proposer Name"
				value={ProposerName}
				onChange={e => handleContactDetailsChange('ProposerName', e.target.value)}
			/>
			<Input
				className="w-100 mb-3"
				label="Legal Entity Name"
				value={LegalEntityName}
				onChange={e => setLegalEntityName(e.target.value)}
			/>
			<Input
				className="w-100 mb-3"
				label="Email"
				value={Email}
				onChange={e => handleContactDetailsChange('Email', e.target.value)}
			/>
			<Input
				className="w-100 mb-3"
				label="Contact Number"
				value={Phone}
				maxLength={10}
				onChange={e => handleContactDetailsChange('Phone', e.target.value)}
			/>
			<Input
				className="w-100 mb-1"
				label="WhatsApp Number"
				value={WaPhone}
				maxLength={10}
				onChange={e => handleContactDetailsChange('WaPhone', e.target.value)}
			/>
			<AccordionWrapper className="mb-3">
				<Accordion accordionList={riskLocationsList} />
			</AccordionWrapper>
			<Button
				onClick={saveChanges}
				primary
				label="Save Changes"
				style={{ width: '100%', height: '48px' }}
			/>
		</Modal>
	)
}

const AccordionWrapper = styled.div`
	.accordion-item,
	.accordion-button {
		background: #f5f5f5 !important;
	}
`

import { addBusinessDays } from 'date-fns/esm'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const prependZero = num => (num < 10 ? `0${num}` : num)

// returns date string like
// 15 Aug 2021, 5:22 pm
const getDateString = ISOString => {
	const date = new Date(ISOString)

	const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours()

	const AMPM = date.getHours() > 12 ? 'pm' : 'am'

	return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}, ${prependZero(
		hours
	)}:${prependZero(date.getMinutes())} ${AMPM}`
}

const getRFQSubmissionDateString = ISOString => {
	const rfqLastUpdatedTime = ISOString
	let dateTimeString = ''
	let submissionDate = ''

	if (rfqLastUpdatedTime) {
		submissionDate = addBusinessDays(new Date(rfqLastUpdatedTime), 1)

		let date = submissionDate.getDate()
		let time = submissionDate.getHours()
		let month = submissionDate.toLocaleString('default', { month: 'short' })

		// convert to 12 hour format
		let timeSuffix = 'am'
		// 12 am
		if (time === 24) {
			time = 12
			timeSuffix = 'am'
			// 12 pm
		} else if (time === 12) {
			timeSuffix = 'pm'
		} else if (time >= 12) {
			timeSuffix = 'pm'
			time = time - 12
		}
		dateTimeString = `${time}${timeSuffix}, ${date} ${month} `
	}
	return dateTimeString
}

export { months, prependZero, getDateString, getRFQSubmissionDateString }

import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router'
import { Button, Container } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useContextApi } from '../../context/Provider'
import { useFetch } from '../../api/apihooks'

import Text from '../../components/core/Text'
import theme from '../../components/core/theme'

import BackArrow from '../../assets/icons/BackArrow.svg'
import ChevronRight from '../../assets/icons/ChevronRight.svg'
import CheckCircle from '../../assets/icons/CheckCircle.svg'
import DownloadIcon from '../../assets/icons/DownloadIcon.svg'
import CompletionCheck from '../../assets/icons/CompletionCheck.svg'
import { getRFQSubmissionDateString } from '../../utils/dateUtils'
import { policyTypeStrings } from '../../utils/getPolicyType'

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: toast => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})

const initialStepsState = {
	0: false,
	1: false,
	2: false,
	3: false,
}

export default function PendingRFQResubmission() {
	const history = useHistory()
	const { state: locationState } = useLocation()

	const [state, dispatch] = useContextApi()
	const { downloadRFQArchive, getQuote, updateQuote } = useFetch()

	const [steps, setSteps] = useState(initialStepsState)
	const [currentQuote, setCurrentQuote] = useState(null)

	const insurerName = locationState?.quote?.Insurer || ''

	const [rfqAttachmentsDownloaded, setRFQAttachmentsDownloaded] = useState(false)
	const [additionalRequirementsDownloaded, setAdditionalRequirementsDownloaded] = useState(true)

	const quoteId = locationState?.quote?.id || ''

	useEffect(() => {
		const fetchData = async () => {
			const quote = await getQuote(quoteId)
			const resubmitted = quote?.Meta?.Resubmitted || null
			if (resubmitted) {
				// since this updated quote was already emailed again, we mark the first 3 steps as complete
				setSteps({
					0: true,
					1: true,
					2: true,
					3: false,
				})
			}
			setCurrentQuote(quote)
		}
		if (quoteId && !currentQuote) {
			fetchData()
		}
	}, [quoteId]) // eslint-disable-line react-hooks/exhaustive-deps

	const goBack = () => {
		dispatch({ type: 'RESET_CURRENT_RFQ_ITEM' })
		history.goBack()
	}

	const completeStep = async stepIndex => {
		setSteps({ ...steps, [stepIndex]: true })
		if (stepIndex === 2) {
			const new_quote = {
				...locationState?.quote,
				Meta: {
					...locationState?.quote?.Meta,
					Resubmitted: true,
				},
			}

			await new Promise((resolve, reject) => {
				dispatch({
					type: 'UPDATE_QUOTE',
					data: new_quote,
				})
				resolve()
			})
			await updateQuote(locationState?.quote?.id, new_quote)
		}
	}

	const navigateToQuote = () => {
		history.push({
			pathname: '/quote-update',
			state: {
				quote: locationState?.quote || {},
			},
		})
	}

	const downloadRFQAttachments = async () => {
		let allDownloadsComplete = false

		const rfqId = state?.currentRfqItem?.id || ''
		if (rfqId) {
			const response = await downloadRFQArchive(rfqId)
			const archiveUrl = response?.Archive || ''
			if (archiveUrl) {
				const link = document.createElement('a')
				link.setAttribute('href', archiveUrl)
				link.setAttribute('target', '_blank')
				link.setAttribute('download', 'RFQ.zip')
				document.body.appendChild(link)
				link.click()
				link.remove()

				Toast.fire({
					text: 'Original RFQ & Attachments were downloaded successfully',
					icon: 'success',
				})

				setRFQAttachmentsDownloaded(true)
				// set allDownloadsComplete based on the additional requirements download now
				allDownloadsComplete = additionalRequirementsDownloaded

				if (allDownloadsComplete) {
					setSteps(prev => ({ ...prev, 0: true, 1: true })) // complete step 1 and 2
				}
			}
		}
	}

	const downloadAdditionalRequirements = () => {
		let allDownloadsComplete = false

		if (currentQuote?.Files?.AdditionalReq) {
			const link = document.createElement('a')
			link.setAttribute('href', currentQuote?.Files?.AdditionalReq)
			link.setAttribute('target', '_blank')
			link.setAttribute('download', 'Additional_requirements.pdf')
			document.body.appendChild(link)
			link.click()
			link.remove()
			setAdditionalRequirementsDownloaded(true)

			Toast.fire({
				icon: 'success',
				title: 'Download successful',
			})
		}

		// set allDownloadsComplete based on the additional requirements download now
		allDownloadsComplete = rfqAttachmentsDownloaded

		if (allDownloadsComplete) {
			setSteps(prev => ({ ...prev, 0: true, 1: true })) // complete step 1 and 2
		}
	}

	const handleEmailSentClick = async () => {
		// set the 'Resubmitted' Meta field to 'true' here
		const updatedQuote = {
			...currentQuote,
			Meta: {
				...currentQuote?.Meta,
				Resubmitted: true,
			},
		}
		await updateQuote(updatedQuote?._id, updatedQuote)
		completeStep(2)
	}

	const disableStep3 = !steps[0] || !steps[1]
	const disableStep4 = !steps[0] || !steps[1] || !steps[2]

	const rfqLastUpdatedTime = state?.currentRfqItem?.updatedAt || ''

	let dateTimeString = ''
	if (rfqLastUpdatedTime) {
		dateTimeString = getRFQSubmissionDateString(rfqLastUpdatedTime)
	}

	const { Tag, id: uid, Name: clientName } = state?.currentRfqItem || {}

	const policyType = policyTypeStrings(Tag) || ''

	const stepsFlowScreen = (
		<>
			<Text className="my-3" type="h5" fontWeight="bold" color={theme.colors.primary}>
				The RFQ for {policyType} must be sent to Insurance companies by {dateTimeString}
			</Text>

			<Text className="mb-3" color={theme.colors.red} type="body1" fontWeight="bold">
				Please reply to {insurerName} with the updated documents to the in the previous email thread
			</Text>

			<StepCard statusComplete={steps[0]}>
				<div className="w-100 d-flex justify-content-between">
					<div className="d-flex flex-column">
						<Text
							color={theme.colors.primary}
							fontWeight="bold"
							fontSize="1rem"
							style={{ textTransform: 'uppercase', opacity: '0.4' }}
						>
							Step 1
						</Text>
						<Text color={theme.colors.primary} fontWeight="bold" type="body1" className="my-2">
							Download the RFQ & attachments{' '}
						</Text>
					</div>

					<div className="d-flex flex-column">
						<CustomButton className="mb-2" onClick={downloadRFQAttachments}>
							RFQs and attachments
							<img className="ms-4" src={DownloadIcon} alt="download icon" />
						</CustomButton>

						<CustomButton onClick={downloadAdditionalRequirements}>
							Additional requirements PDF
							<img className="ms-4" src={DownloadIcon} alt="download icon" />
						</CustomButton>
					</div>
				</div>
			</StepCard>

			<StepCard statusComplete={steps[1]}>
				<div className="w-100 d-flex justify-content-between">
					<div className="d-flex flex-column">
						<Text
							color={theme.colors.primary}
							fontWeight="bold"
							fontSize="1rem"
							style={{ textTransform: 'uppercase', opacity: '0.4' }}
						>
							Step 2
						</Text>
						<Text className="my-2" color={theme.colors.red} type="body1" fontWeight="bold">
							Reply with these documents in the previous email thread
						</Text>
					</div>
				</div>
			</StepCard>

			<StepCard statusComplete={steps[2]}>
				<div className="w-100 d-flex justify-content-between align-items-center">
					<div className="d-flex flex-column">
						<Text
							color={theme.colors.primary}
							fontWeight="bold"
							fontSize="1rem"
							style={{ textTransform: 'uppercase', opacity: '0.4' }}
						>
							Step 3
						</Text>
						<Text color={theme.colors.primary} fontWeight="bold" type="body1" className="my-2">
							Confirm that the emails have been sent out
						</Text>
					</div>

					<CustomButton disabled={disableStep3} onClick={handleEmailSentClick}>
						Confirm email is sent
						<img className="ms-4" src={CheckCircle} alt="check circle" />
					</CustomButton>
				</div>
			</StepCard>

			<StepCard statusComplete={steps[3]}>
				<div className="w-100 d-flex justify-content-between align-items-center">
					<div className="d-flex flex-column">
						<Text
							color={theme.colors.primary}
							fontWeight="bold"
							fontSize="1rem"
							style={{ textTransform: 'uppercase', opacity: '0.4' }}
						>
							Step 4
						</Text>
						<Text color={theme.colors.primary} fontWeight="bold" type="body1" className="my-2">
							Update the quotes received from insurance companies
						</Text>
					</div>

					<CustomButton disabled={disableStep4} onClick={navigateToQuote}>
						Update quote
						<img className="ms-4" src={ChevronRight} alt="right arrow" />
					</CustomButton>
				</div>
			</StepCard>
		</>
	)

	return (
		<>
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="Back Arrow" />
				</span>
				<div className="mx-3">
					{policyType}, {uid || ''} - {clientName || ''}
				</div>
			</TopNav>

			<Container>{stepsFlowScreen}</Container>
		</>
	)
}

const StepCard = ({ statusComplete = false, children }) => {
	return (
		<CardWrapper>
			<div className="d-flex flex-column align-items-center">
				<CompletionCircle statusComplete={statusComplete}>
					{statusComplete && <img src={CompletionCheck} alt="check" />}
				</CompletionCircle>
				<CompletionLine statusComplete={statusComplete} />
			</div>
			{children}
		</CardWrapper>
	)
}

// styles
const CustomButton = styled(Button)`
	height: 48px;
	width: 20rem;
	font-size: 1.125rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${({ disabled }) => (disabled ? '#C4C4C4 !important' : '#C8D6DB')};
	color: #02475e;
	border: none;
	font-weight: 700;
`

// temporary secondary navbar
const TopNav = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
	font-size: 18px;
	font-weight: 700;
	line-height: 23px;
	color: #02475e;
`

// step card
const CardWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 1rem;
	margin-bottom: 2rem;
`

const CompletionCircle = styled.div`
	height: 36px;
	width: 36px;
	border-radius: 50%;
	border: ${({ statusComplete }) => (statusComplete ? 'none' : '4px solid #B3B3B3')};
	background: #fff;
	margin-right: 1rem;
`

const CompletionLine = styled.div`
	height: 20px;
	border-left: ${({ statusComplete }) =>
		statusComplete ? '4px solid #75CC1F' : '4px solid #ECECEC'};
	margin-right: 1rem;
`

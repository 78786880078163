import roundOffNum from './roundOffNum'

const sum = (data = []) => {
	return data?.reduce((total, current) => total + current?.Amount, 0)
}

const calculateTotalPremium = quoteItem => {
	// total premium amount changes if premium breakdown is available
	let totalPremium = roundOffNum(quoteItem?.TotalPremium) || 0
	const hasPremiumBreakdown = quoteItem?.HasPremiumBreakdown

	let totalAddonPremiums = 0
	if (hasPremiumBreakdown) {
		totalAddonPremiums = quoteItem?.Addons?.filter(a => !a?.Rejected)?.reduce(
			(previous, current) => {
				return previous + roundOffNum(current?.Amount || 0)
			},
			0
		)
	}
	const safetyPlanCost = parseFloat(sum(quoteItem?.IntegratedCosts) + sum(quoteItem?.Fees))
	totalPremium = roundOffNum(totalPremium + totalAddonPremiums + safetyPlanCost)

	return totalPremium
}

export default calculateTotalPremium

// import Button from '@restart/ui/esm/Button'
import { Button } from 'verak-ui'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

import ConfirmationLottie from '../../assets/confirmationLottie.svg'
import ChevronRight from '../../assets/icons/ChevronRight.svg'

const UpdateSuccessComponent = ({
	children,
	continueButtonLink,
	continueButtonOnclick,
	ctaText,
}) => {
	const history = useHistory()

	const handleContinueButtonClick = () => {
		if (continueButtonOnclick) {
			continueButtonOnclick()
		} else if (continueButtonLink) {
			history.push(continueButtonLink)
		}
	}

	const ctaCopy = ctaText ? ctaText : 'Continue to home'

	return (
		<Container className="d-flex flex-column justify-content-center">
			<h5
				className="mt-5 mb-0"
				style={{
					fontSize: '24px',
					textAlign: 'center',
					fontWeight: 'bold',
				}}
			>
				Information submitted successfully
			</h5>
			<ConfirmationSuccessImage
				width="500"
				className="mx-auto"
				src={ConfirmationLottie}
				alt="confirmation-lottie"
				style={{ marginTop: '-2rem' }}
			/>
			<CustomDiv className="d-flex flex-column align-items-center">
				{/* anything children elements to render will appear here */}
				{children}
				<Button
					className="mx-auto py-3 px-4"
					variant="primary"
					style={{
						color: '#fff',
						background: '#02475E',
						borderRadius: '0.5rem',
						border: 'none',
					}}
					onClick={handleContinueButtonClick}
					label={ctaCopy}
				>
					{ctaCopy}
					<img className="ms-4" src={ChevronRight} alt="right arrow" />
				</Button>
			</CustomDiv>
		</Container>
	)
}

export default UpdateSuccessComponent

const lottieAnimation = keyframes`
    from{
        transform: scale(1);
    }
    to{
        transform: scale(.5);
    }
`

const fadeIn = keyframes`
    from{
        opacity: 0.3;
    }
    to{
        opacity: 1;
    }
`

const ConfirmationSuccessImage = styled.img`
	animation-name: ${lottieAnimation};
	animation-duration: 1s;
	animation-iteration-count: 1;
	transform: scale(0.5);
`

const CustomDiv = styled.div`
	animation-name: ${fadeIn};
	animation-duration: 2s;
	animation-iteration-count: 1;
`

import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Button, Container } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useContextApi } from '../../context/Provider'
import { policyTypeStrings } from '../../utils/getPolicyType'

import bajaj from '../../assets/insurers/bajajAllianz.png'
import digit from '../../assets/insurers/DigitInsurance.png'
import icici from '../../assets/insurers/icici.png'
import liberty from '../../assets/insurers/LibertyInsurance.png'
import oriental from '../../assets/insurers/OrientalInsurance.png'
import reliance from '../../assets/insurers/RelianceGeneralInsurance.png'
import royalSundaram from '../../assets/insurers/RoyalSundaram.png'
import unitedIndia from '../../assets/insurers/UnitedIndia.png'
import tataAIG from '../../assets/insurers/TataAIG.png'
import NationalInsurance from '../../assets/insurers/NationalInsurance.png'
import NewIndiaAssurance from '../../assets/insurers/NewIndiaAssurance.png'
import KotakGeneral from '../../assets/insurers/KotakGeneralInsurance.png'
import SBIGeneral from '../../assets/insurers/SBIGeneral.svg'
import defaultIcon from '../../assets/insurers/default.png'

import BackArrow from '../../assets/icons/BackArrow.svg'

import UpdateSuccessComponent from '../CommonScreenComponents/UpdateSuccessComponent'
import { getFormattedAmount } from '../../utils/getFormattedAmount'

const insurerIcons = {
	'Reliance General Insurance': reliance,
	'Liberty General Insurance': liberty,
	'Oriental Insurance': oriental,
	'Digit Insurance': digit,
	'ICICI Lombard General Insurance': icici,
	'Bajaj Allianz General Insurance': bajaj,
	'Royal Sundaram General Insurance': royalSundaram,
	'United India Insurance': unitedIndia,
	'Tata AIG General Insurance': tataAIG,
	'National Insurance': NationalInsurance,
	'The New India Assurance Co. Ltd.': NewIndiaAssurance,
	'Kotak General Insurance': KotakGeneral,
	'SBI General Insurance': SBIGeneral,
}

const dummyQuotes = [
	{
		Insurer: 'ICICI Lombard General Insurance',
		Premium: 200000,
		id: '61bafe983e382aec6073b548',
		Status: 'SELECTED',
	},
	{
		Insurer: 'Oriental Insurance',
		Premium: 200000,
		id: '61bafe983e382ae4a073b548',
		Status: 'REJECTED',
	},
	{
		Insurer: 'Liberty General Insurance',
		Premium: 203000,
		id: '61bafe983e372aeca073b548',
		Status: 'REJECTED',
	},
]

export default function InsurerQuotesList() {
	const history = useHistory()

	const goBack = () => {
		dispatch({ type: 'RESET_CURRENT_RFQ_ITEM' })
		history.push('/')
	}
	// const { getClientQuotes } = useFetch()

	const [state, dispatch] = useContextApi()
	const rfqItem = state.currentRfqItem

	// Screen types
	// list-screen (shows selected and rejected quotes)
	// success-screen (shows success message)
	const [activeScreen, setActiveScreen] = useState('list-screen')

	const initializeActionList = quoteList => {
		let actions = []
		const selectedItem = quoteList.find(item => item.Status === 'SELECTED')
		actions = [{ ...selectedItem, completed: false }]

		const rejectedItems = quoteList
			.filter(item => item.Status === 'REJECTED')
			.map(item => {
				return { ...item, completed: false }
			})
		actions = [...actions, ...rejectedItems]
		return actions
	}

	const fetchClientQuotes = async () => {
		// const result = await getClientQuotes(rfqItem.id)
		// setQuoteList(result)
		// const actionList = initializeActionList(result)
		const actionList = initializeActionList(dummyQuotes)
		dispatch({
			type: 'SET_ACTION_LIST',
			data: actionList,
		})
	}

	useEffect(() => {
		if (!!!state?.currentRfqItem.actionList) {
			fetchClientQuotes()
		}
	}, [])

	const selectedQuote =
		state?.currentRfqItem?.actionList?.find(quote => quote.Status === 'SELECTED') || [] // only 1 quote can be selected
	const rejectedQuotes =
		state?.currentRfqItem?.actionList?.filter(quote => quote.Status === 'REJECTED') || []
	let selectedQuoteSection
	if (!!selectedQuote) {
		const handleButtonClick = () => {
			dispatch({
				type: 'SET_CURRENT_QUOTE_ITEM',
				data: selectedQuote,
			})
			history.push('/follow-up-policy-bond')
		}

		let actionElement

		if (state?.currentRfqItem?.Status === 'OPS:CLOSURE:PENDING') {
			actionElement = <h4 style={{ color: '#F78670' }}>Email sent</h4>
		} else {
			actionElement = (
				<CustomButton style={{ width: '240px', fontWeight: '700' }} onClick={handleButtonClick}>
					Follow up
				</CustomButton>
			)
		}

		selectedQuoteSection = (
			<QuoteCard
				insurerLogo={insurerIcons[selectedQuote.Insurer] || defaultIcon}
				premium={selectedQuote.Premium}
				quoteId={selectedQuote.id}
				rightElement={actionElement}
			/>
		)
	}

	let rejectedQuotesListSection
	if (!!rejectedQuotes) {
		rejectedQuotesListSection = rejectedQuotes.map(quote => {
			const handleButtonClick = () => {
				dispatch({
					type: 'SET_CURRENT_QUOTE_ITEM',
					data: quote,
				})
				history.push('/send-rejection-emails')
			}

			let actionElement
			if (!!quote?.completed) {
				actionElement = <h4 style={{ color: '#F78670' }}>Email sent</h4>
			} else {
				actionElement = (
					<CustomButton style={{ width: '240px', fontWeight: '700' }} onClick={handleButtonClick}>
						Complete information
					</CustomButton>
				)
			}

			return (
				<QuoteCard
					key={quote.id}
					insurerLogo={insurerIcons[quote.Insurer] || defaultIcon}
					premium={quote.Premium}
					quoteId={quote.id}
					rightElement={actionElement}
				/>
			)
		})
	}

	const disableAllEmailsSentButton = state?.currentRfqItem?.actionList?.some(
		item => item.completed === false
	)

	const successScreen = <UpdateSuccessComponent continueButtonLink="/" />

	const insurerQuotesList = (
		<>
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<div
					className="mx-3"
					style={{ fontSize: '18px', lineHeight: '23px', color: '#02475E', fontWeight: '700' }}
				>
					{policyTypeStrings(rfqItem?.Tag?.toUpperCase())}, {rfqItem?.id} - {rfqItem?.Name}
				</div>
			</TopNav>

			<Container>
				<h4 className="mt-3" style={{ color: '#02475E', fontSize: '24px', fontWeight: '700' }}>
					Follow up for payment confirmation and policy bond
				</h4>
				{selectedQuoteSection}

				<h4 className="mt-3" style={{ color: '#02475E', fontSize: '24px', fontWeight: '700' }}>
					Send the rejection emails to insurers that were not accepted
				</h4>
				{rejectedQuotesListSection}

				<button
					disabled={disableAllEmailsSentButton}
					onClick={() => setActiveScreen('success-screen')}
					style={{ height: '48px' }}
					className="btn btn-primary btn-block w-100"
				>
					All emails sent
				</button>
			</Container>
		</>
	)

	let activeSection
	if (activeScreen === 'list-screen') {
		activeSection = insurerQuotesList
	} else {
		activeSection = successScreen
	}
	return activeSection
}

const QuoteCard = ({ insurerLogo, premium, quoteId, rightElement }) => {
	return (
		<CardWrapper className="justify-content-between">
			<div className="d-flex align-items-center">
				<QuoteImg src={insurerLogo} className="mx-2" />

				<div className="d-flex flex-column mx-2 px-3 border-start border-end">
					<span style={{ color: '#C8D6DB' }}>Premium</span>
					<p className="p-0" style={{ color: '#02475E', fontSize: '24px' }}>
						{`₹ ${getFormattedAmount(premium || 0)}`}
					</p>
				</div>

				<div className="d-flex flex-column mx-2">
					<span style={{ color: '#C8D6DB' }}>Quote ID</span>
					<p className="p-0" style={{ color: '#02475E', fontSize: '24px' }}>
						{quoteId}
					</p>
				</div>
			</div>

			{rightElement}
		</CardWrapper>
	)
}

// styles
const QuoteImg = styled.img`
	height: 60px;
	width: 100px;
	object-fit: contain;
`

const CardWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 1rem;
	margin-bottom: 2rem;
`

const CustomButton = styled(Button)`
	height: 48px;
	display: flex;
	align-items: center;
	color: #02475e;
	border: none;
	outline: none;
	background: ${({ disabled }) => (disabled ? '#C4C4C4 !important' : '#C8D6DB')};
`

// temporary secondary navbar
const TopNav = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
`

import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router'
import { Button, Container, FormControl } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useFetch } from '../../api/apihooks'
import { useContextApi } from '../../context/Provider'
import { getRFQSubmissionDateString } from '../../utils/dateUtils'
import Text from '../../components/core/Text'
import theme from '../../components/core/theme'
import BackArrow from '../../assets/icons/BackArrow.svg'
import ChevronRight from '../../assets/icons/ChevronRight.svg'
import CompletionCheck from '../../assets/icons/CompletionCheck.svg'
import CloseMark from '../../assets/icons/CloseMark.svg'
import AutoEmail from '../../assets/icons/email-fast.svg'
import ManualEmail from '../../assets/icons/mailbox.svg'
import CheckCircle from '../../assets/icons/CheckCircle.svg'

import { policyTypeStrings } from '../../utils/getPolicyType'

const initialStepsState = {
	0: false,
	1: false,
}

const insurerListLink =
	'https://www.notion.so/Insurer-Contact-Matrix-0a3b6d62fb5049c8aab6f7a35b521b83'

const validateEmail = email => {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

export default function RFQSubmission() {
	const history = useHistory()

	// const { state: locationState } = useLocation()

	const [steps, setSteps] = useState(initialStepsState)

	const [insurerEmails, setInsurerEmails] = useState([])
	const [inputValue, setInputValue] = useState('')
	const [error, setError] = useState('')
	// const [insurerEmails, setInsurerEmails] = useState('')
	const [emailMethod, setEmailMethod] = useState('') // 'auto' or 'manual'
	const [autoEmailStatus, setAutoEmailStatus] = useState('') // 'sending', 'sent',
  const [manualEmailStatus, setManualEmailStatus] = useState('') // 'sending', 'sent',
  const [addons, setAddons] = useState([])

	const [state, dispatch] = useContextApi()
	const currentRfqItem = state?.currentRfqItem

	const goBack = () => {
		dispatch({ type: 'RESET_CURRENT_RFQ_ITEM' })
		history.goBack()
	}
	const { sendRFQToInsurer, getAddons } = useFetch()
	const completeStep = stepIndex => {
		setSteps({ ...steps, [stepIndex]: true })
	}

	useEffect(() => {
		getAddons(currentRfqItem.id).then(result => {
			setAddons(result['Addons'])
		})
	}, [])

	const policyType = policyTypeStrings(currentRfqItem?.Tag)

	let policyDescription = policyTypeStrings(currentRfqItem?.Tag)
	if (addons?.length > 0) {
		if (currentRfqItem?.Tag === 'FIRE_ALL_RISK') {
			policyDescription = 'FIRE' + ' + ' + addons.join(' + ')
		} else {
			policyDescription = policyDescription + ' + ' + addons.join(' + ')
		}
	}

	// const disableStep2 = !steps[0] || insurerEmails.length === 0 || error
	const disableStep1 = insurerEmails.length === 0 || error

	const disableStep2 = !steps[0]

	const isAutoMethod = emailMethod === 'auto'
	const isManualMethod = emailMethod === 'manual'

	const autoEmailSent = autoEmailStatus === 'sent'
	let autoEmailBtnText = 'Send automated emails'
	if (autoEmailSent) {
		autoEmailBtnText = 'Emails Sent!'
	}

	const manualEmailSent = manualEmailStatus === 'sent'
	let manualEmailBtnText = 'Send manually'
	if (manualEmailSent) {
		manualEmailBtnText = 'Emails sent manually'
	}

	const handleAutoEmail = async () => {
		setEmailMethod('auto')
		setAutoEmailStatus('sending')

		// API call to send emails
		await sendRFQToInsurer(currentRfqItem.id, {
			automated: true,
		})

		setAutoEmailStatus('sent')

		// the backend automatically updates the status to "OPS:QUOTES:PENDING"
		// while performing the email task of sending it to the insurer
		dispatch({
			type: 'UPDATE_RFQ_STATUS',
			data: {
				id: currentRfqItem.id,
				Status: 'OPS:QUOTES:PENDING',
			},
		})

		completeStep(0)
	}

	const handleManualEmail = () => {
		setEmailMethod('manual')
	}

	const sendManualEmails = async () => {
		// const allEmails = parseEmails(insurerEmails)
		if (insurerEmails.length > 0) {
			setManualEmailStatus('sending')
			// api call to send emails
			await sendRFQToInsurer(currentRfqItem.id, {
				emails: insurerEmails,
			})
			setManualEmailStatus('sent')
			// set rfq status to
			const updatedStatus = 'OPS:QUOTES:PENDING'

			// the backend automatically updates the status to "OPS:QUOTES:PENDING"
			// while performing the email task of sending it to the insurer
			dispatch({
				type: 'UPDATE_RFQ_STATUS',
				data: {
					id: currentRfqItem.id,
					Status: updatedStatus,
				},
			})

			completeStep(0)
		}
	}

	const rfqLastUpdatedTime = state?.currentRfqItem?.updatedAt || ''

	let dateTimeString = ''
	if (rfqLastUpdatedTime) {
		dateTimeString = getRFQSubmissionDateString(rfqLastUpdatedTime)
	}

	const navigateToQuoteList = () => {
		history.replace('/quotes')
	}

	const isValid = email => {
		let error = null

		if (insurerEmails.includes(email)) {
			error = `${email} has already been added.`
		}

		if (!validateEmail(email)) {
			error = `${email} is not a valid email address.`
		}

		if (error) {
			setError(error)
			return false
		}
		return true
	}

	const handleKeyDown = event => {
		if ([' ', 'Tab', ';', ','].includes(event.key)) {
			event.preventDefault()

			var value = inputValue.trim()

			if (value && isValid(value)) {
				setInsurerEmails([...insurerEmails, value])
				setInputValue('')
				setError('')
			}
		}
	}

	const handlePaste = event => {
		event.preventDefault()

		var paste = event.clipboardData.getData('text')
		var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]{2,}/g)

		if (emails) {
			var toBeAdded = emails.filter(email => !insurerEmails.includes(email))
			setInsurerEmails([...insurerEmails, ...toBeAdded])
		}
	}

	const handleChange = event => {
		setInputValue(event.target.value)
		if (!event.target.value) {
			setError('')
		}
	}

	const handleDelete = item => {
		const emails = insurerEmails.filter(i => i !== item)
		setInsurerEmails(emails)
	}

	let manualEmailMethodContent = (
		<>
			<Text color="#FF934F" fontWeight="bold" fontSize="1rem" className="mb-4">
				Please enter the emails you have chosen from the above list and click on submit
			</Text>
			<Text color="#4D4D4D" fontSize="0.9rem" className="mb-4">
				Please separate emails using the comma (,)
			</Text>
			<EmailContainer>
				{insurerEmails.map(item => (
					<EmailTag key={item}>
						{item}
						<RemoveButton onClick={() => handleDelete(item)}>
							<img src={CloseMark} alt="close button" />
						</RemoveButton>
					</EmailTag>
				))}
				<EmailInput
					type="text"
					value={inputValue}
					onChange={handleChange}
					placeholder={insurerEmails.length > 0 ? '' : 'Enter emails in comma separated form'}
					onKeyDown={handleKeyDown}
					onPaste={handlePaste}
				/>
			</EmailContainer>
			{error && <Text color={theme.colors.red}>{error}</Text>}
			<div className="d-flex justify-content-end mt-2">
				<CustomButton disabled={disableStep1} onClick={sendManualEmails} background="#FF934F">
					{steps[0] ? 'Submitted' : 'Submit'}
					<img className="ms-4" src={CheckCircle} alt="check circle" />
				</CustomButton>
			</div>
		</>
	)

	if (manualEmailSent) {
		manualEmailMethodContent = (
			<>
				<Text color="#FF934F" className="mb-3" fontSize="18px" fontWeight="700">
					Emails sent to:{' '}
				</Text>
				<Text color="#4D4D4D" fontSize="14px" fontWeight="400">
					{insurerEmails.join(', ')}
				</Text>
			</>
		)
	}

	return (
		<>
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<div
					className="mx-3"
					style={{ fontSize: '18px', lineHeight: '23px', color: '#02475E', fontWeight: '700' }}
				>
					{policyType}, {currentRfqItem?.id} - {currentRfqItem?.Name}
				</div>
			</TopNav>

			<Container>
				<Text className="mt-3" type="h5" fontWeight="bold" color={theme.colors.primary}>
					{steps[0]
						? 'Enter quote information when available'
						: `The RFQ for ${policyDescription} must be sent to Insurance companies by ${dateTimeString}`}
				</Text>
				<p className="mt-3" style={{ color: '#999999' }}>
					This RFQ needs to be sent to our partner insurance companies for quote generation, please
					make sure you send them within the deadline.
				</p>
				<p className="mt-3" style={{ color: '#999999' }}>
					If you are using the manual send option please find & copy the emails of preferred
					insurance partners from the list. &nbsp;
					<a href={insurerListLink} target="_blank" style={{ color: '#FF934F' }}>
						See emails here
					</a>
				</p>

				<StepCard statusComplete={steps[0]}>
					<div className="w-100 d-flex flex-column">
						<div className="w-100 d-flex justify-content-between align-items-center">
							<div>
								<Text
									color={theme.colors.primary}
									fontWeight="bold"
									fontSize="1rem"
									style={{ textTransform: 'uppercase', opacity: '0.4' }}
								>
									Step 1
								</Text>
								<Text color={theme.colors.primary} fontWeight="bold" type="body1" className="my-2">
									Select the method for sending emails
								</Text>
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<CustomButton className="me-2" onClick={handleAutoEmail} $success={autoEmailSent}>
									<div>
										<img className="me-2" src={AutoEmail} alt="auto email" />
										{autoEmailBtnText}
									</div>
									<img className="ms-4" src={ChevronRight} alt="right arrow" />
								</CustomButton>
								<CustomButton
									onClick={handleManualEmail}
									disabled={isAutoMethod}
									$success={manualEmailSent}
								>
									<div>
										<img className="me-2" src={ManualEmail} alt="manual email" />
										{manualEmailBtnText}
									</div>
									<img className="ms-4" src={ChevronRight} alt="right arrow" />
								</CustomButton>
							</div>
						</div>

						{isManualMethod && (
							<div className="p-2" style={{ background: '#FFF8F3' }}>
								{manualEmailMethodContent}
							</div>
						)}
					</div>
				</StepCard>

				<StepCard statusComplete={steps[1]}>
					<div className="w-100 d-flex justify-content-between align-items-center">
						<div className="d-flex flex-column">
							<Text
								color={theme.colors.primary}
								fontWeight="bold"
								fontSize="1rem"
								style={{ textTransform: 'uppercase', opacity: '0.4' }}
							>
								Step 2
							</Text>
							<Text color={theme.colors.primary} fontWeight="bold" type="body1" className="my-2">
								Enter the quotes recieved from insurance companies
							</Text>
						</div>

						<CustomButton disabled={disableStep2} onClick={navigateToQuoteList}>
							Update quotes
							<img className="ms-4" src={ChevronRight} alt="right arrow" />
						</CustomButton>
					</div>
				</StepCard>
			</Container>
		</>
	)
}

const StepCard = ({ statusComplete = false, children }) => {
	return (
		<StepCardWrapper>
			<div className="d-flex flex-column align-items-center">
				<CompletionCircle statusComplete={statusComplete}>
					{statusComplete && <img src={CompletionCheck} alt="check" />}
				</CompletionCircle>
				<CompletionLine statusComplete={statusComplete} />
			</div>
			{children}
		</StepCardWrapper>
	)
}

// styles
const CustomButton = styled(Button)`
	height: 48px;
	width: 20rem;
	display: flex;
	justify-content: space-between;
	font-size: 1.125rem;
	padding: 1rem;
	align-items: center;
	background: ${({ disabled, $success, background }) => {
		if (disabled) {
			return `${theme.colors.gray5} !important`
		} else if ($success) {
			return '#75CC1F'
		} else {
			return background || '#02475E'
		}
	}};
`

const EmailContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: auto;
	min-height: 52px;
	padding: 0.2rem;
	background: transparent;
	border: 1px solid ${theme.colors.secondary4};
	border-radius: 10px;
	box-sizing: border-box;
	flex-wrap: wrap;

	&:focus-within {
		border: 1px solid ${theme.colors.primary};
	}
`

const EmailTag = styled(Text)`
	background-color: ${theme.colors.secondary4};
	height: 36px;
	display: inline-flex;
	margin: 0.1rem 0.2rem;
	border-radius: 8px;
	padding: 1rem 0.3rem 1rem 1rem;
	align-items: center;
	color: ${theme.colors.primary};
	font-weight: 700;
	font-size: 0.875rem;
`

const EmailInput = styled.input`
	padding: 0.5rem 0.75rem;
	flex: 1;
	border-radius: 0.5rem;
	border: none;
	display: block;

	&:focus {
		outline: none;
	}
`
const RemoveButton = styled.button`
	background: transparent;
	width: 22px;
	height: 22px;
	border: none;
	cursor: pointer;
	font: inherit;
	margin-left: 5px;
	font-weight: bold;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

// temporary secondary navbar
const TopNav = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
`

// step card
const StepCardWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 1rem;
	margin-bottom: 2rem;
`

const CompletionCircle = styled.div`
	height: 36px;
	width: 36px;
	border-radius: 50%;
	border: ${({ statusComplete }) => (statusComplete ? 'none' : '4px solid #B3B3B3')};
	background: #fff;
	margin-right: 1rem;
`

const CompletionLine = styled.div`
	height: 20px;
	border-left: ${({ statusComplete }) =>
		statusComplete ? '4px solid #75CC1F' : '4px solid #ECECEC'};
	margin-right: 1rem;
`

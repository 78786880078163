import styled from 'styled-components'
import { Button, Container } from 'react-bootstrap'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useContextApi } from '../../context/Provider'
import bajaj from '../../assets/insurers/bajajAllianz.png'
import digit from '../../assets/insurers/DigitInsurance.png'
import icici from '../../assets/insurers/icici.png'
import liberty from '../../assets/insurers/LibertyInsurance.png'
import oriental from '../../assets/insurers/OrientalInsurance.png'
import royalSundaram from '../../assets/insurers/RoyalSundaram.png'
import unitedIndia from '../../assets/insurers/UnitedIndia.png'
import tataAIG from '../../assets/insurers/TataAIG.png'
import reliance from '../../assets/insurers/RelianceGeneralInsurance.png'
import NationalInsurance from '../../assets/insurers/NationalInsurance.png'
import NewIndiaAssurance from '../../assets/insurers/NewIndiaAssurance.png'
import KotakGeneral from '../../assets/insurers/KotakGeneralInsurance.png'
import SBIGeneral from '../../assets/insurers/SBIGeneral.svg'
import defaultIcon from '../../assets/insurers/default.png'
import CompletionCheck from '../../assets/icons/CompletionCheck.svg'

import BackArrow from '../../assets/icons/BackArrow.svg'

import { getFormattedAmount } from '../../utils/getFormattedAmount'
import { policyTypeStrings } from '../../utils/getPolicyType'

const insurerIcons = {
	'Reliance General Insurance': reliance,
	'Liberty General Insurance': liberty,
	'Oriental Insurance': oriental,
	'Digit Insurance': digit,
	'ICICI Lombard General Insurance': icici,
	'Bajaj Allianz General Insurance': bajaj,
	'Royal Sundaram General Insurance': royalSundaram,
	'United India Insurance': unitedIndia,
	'Tata AIG General Insurance': tataAIG,
	'National Insurance': NationalInsurance,
	'The New India Assurance Co. Ltd.': NewIndiaAssurance,
	'Kotak General Insurance': KotakGeneral,
	'SBI General Insurance': SBIGeneral,
}

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: toast => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})

const initialStepsState = {
	0: false,
	1: false,
}

const getEmailTemplate = ({ rejectionReason, ClientName, PolicyType }) => {
	const otherParty = rejectionReason === WENT_WITH_OTHER_INSURER ? 'insurer' : 'broker'

	const template = `
	Hi Sir/ Ma'am,
	<br/><br/>
	We regret to inform you that your quote for ${PolicyType} insurance policy for ${ClientName} was not accepted. The client chose to go ahead with another ${otherParty}. 
	<br/><br/>
	For any issues or concerns, call us at + 919611344788 or write to us at rajesh@insurefirst.co.in.
	<br/><br/>
	Thank you, <br/>
	Operations Team at Insure First
	<br/><br/>
	Please Note: This is a standard regulatory email containing personal information. If you're not the intended recipient of this email, please delete it from your inbox to avoid any regulatory action.
	<br/><br/>
	Disclaimer: Insurance is a subject matter of solicitation. Insure First Insurance Brokers LLP (IRDAI<br/>
	License No. 673) is a Direct Broker (Life & General). Queries or complainants may be forwarded to <br/>
	Principal Officer: Aparajit Bhattacharya at aparajit.b@insurefirst.co.in
	`
	return template
}

const WENT_WITH_OTHER_INSURER = 'Went with another Insurance company'
const DID_NOT_BUY_WITH_INSUREFIRST = 'Did NOT buy with Insure First Insurance Brokers LLP'

export default function SendRejectionEmails() {
	const [state, dispatch] = useContextApi()

	const quote = state?.currentRfqItem?.currentQuoteItem
	const otherData = {
		id: state?.currentRfqItem?.id,
		PolicyType: state?.currentRfqItem?.Tag?.toUpperCase(),
		ClientName: state?.currentRfqItem?.Name,
	}
	const history = useHistory()
	const goBack = () => {
		dispatch({ type: 'RESET_CURRENT_RFQ_ITEM' })
		history.goBack()
	}

	const [steps, setSteps] = useState(initialStepsState)

	const [activeScreen, setActiveScreen] = useState('reason-selection-screen')
	const [rejectionReason, setRejectionReason] = useState('')

	const completeStep = stepIndex => {
		setSteps({ ...steps, [stepIndex]: true })
	}

	const copyEmailTemplate = templateText => {
		const textArea = document.createElement('textarea')
		textArea.value = templateText.toString().replaceAll('<br/><br/>', '\n').replaceAll('<br/>', '')
		document.body.appendChild(textArea)
		textArea.select()
		document.execCommand('copy')
		document.body.removeChild(textArea)

		Toast.fire({ icon: 'success', text: 'Email template copied to clipboard' })
		completeStep(0)
	}

	const setReasonAndScreen = reason => {
		setRejectionReason(reason)
		setActiveScreen('email-template-screen')
	}

	let templateText = ''
	if (!!rejectionReason) {
		templateText = getEmailTemplate({
			rejectionReason,
			PolicyType: policyTypeStrings(otherData?.PolicyType),
			ClientName: otherData?.ClientName,
		})
	}

	const confirmStep2 = async () => {
		completeStep(2)
		await new Promise(resolve => {
			dispatch({
				type: 'COMPLETE_ACTION_ITEM',
				data: {
					actionId: quote?.id,
				},
			})
			resolve()
		})
		history.push('/quotes')
	}

	const handleCopyTemplateClick = () => copyEmailTemplate(templateText)

	const handleRadioChangeReason1 = () => setReasonAndScreen(WENT_WITH_OTHER_INSURER)
	const handleRadioChangeReason2 = () => setReasonAndScreen(DID_NOT_BUY_WITH_INSUREFIRST)

	const reasonSelectionScreen = (
		<>
			<h4 className="my-3" style={{ color: '#02475E', fontWeight: '700', fontSize: '24px' }}>
				Select the reason for rejection
			</h4>

			<QuoteCard
				insurerLogo={insurerIcons[quote?.Insurer] || defaultIcon}
				premium={quote?.Premium}
				quoteId={quote?.id}
			/>

			<div className="bg-white p-3 d-flex flex-column justify-content-start">
				<h4 className="my-3" style={{ color: '#333333', fontWeight: '700', fontSize: '18px' }}>
					Reason for rejection?
				</h4>

				<div className="d-flex align-items-center p-2 border rounded">
					<input
						checked={rejectionReason === WENT_WITH_OTHER_INSURER}
						type="radio"
						value={WENT_WITH_OTHER_INSURER}
						onChange={handleRadioChangeReason1}
					/>
					<p className="m-0 px-2" style={{ color: '#02475E', fontWeight: '700', fontSize: '18px' }}>
						Went with another Insurance company
					</p>
				</div>
				<div className="mt-3 d-flex align-items-center p-2 border rounded">
					<input
						checked={rejectionReason === DID_NOT_BUY_WITH_INSUREFIRST}
						type="radio"
						value={DID_NOT_BUY_WITH_INSUREFIRST}
						onChange={handleRadioChangeReason2}
					/>
					<p className="m-0 px-2" style={{ color: '#02475E', fontWeight: '700', fontSize: '18px' }}>
						Did NOT buy with Insure First Insurance Brokers LLP
					</p>
				</div>
			</div>
		</>
	)

	const emailTemplateScreen = (
		<>
			<h4 style={{ color: '#02475E', fontSize: '24px', fontWeight: '700' }} className="my-3">
				Reply with the rejection email template in the specific insurer email thread.
			</h4>

			<StepCard statusComplete={steps[0]}>
				<div className="w-100 d-flex justify-content-between">
					<div className="d-flex flex-column">
						<h5 style={{ color: '#02475E', fontWeight: '700', opacity: 0.4, fontSize: '14px' }}>
							STEP 1
						</h5>

						<p style={{ color: '#eb5757', fontWeight: 700 }}>
							Reply to the previous email thread with the rejection email template
						</p>

						<div className="border p-3 rounded cursor-pointer">
							<h5 className="my-3" style={{ color: '#02475E', fontWeight: 700, fontSize: '18px' }}>
								{rejectionReason}
							</h5>
							<div
								style={{ color: '#02475E', fontWeight: 400, fontSize: '14px' }}
								dangerouslySetInnerHTML={{ __html: templateText }}
								onClick={handleCopyTemplateClick}
								className="border p-3 rounded cursor-pointer"
							></div>
						</div>
					</div>
				</div>
			</StepCard>

			<StepCard statusComplete={steps[1]}>
				<div className="w-100 d-flex justify-content-between">
					<div className="d-flex flex-column">
						<h5 style={{ color: '#02475E', fontWeight: '700', opacity: 0.4, fontSize: '14px' }}>
							STEP 2
						</h5>
						<p style={{ color: '#02475E', fontWeight: 700, fontSize: '18px' }}>
							Confirm that the emails have been sent out
						</p>
					</div>

					<CustomButton disabled={steps[1]} onClick={confirmStep2}>
						Confirm email is sent
					</CustomButton>
				</div>
			</StepCard>
		</>
	)

	let activeSection =
		activeScreen === 'reason-selection-screen' ? reasonSelectionScreen : emailTemplateScreen

	return (
		<>
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<div
					className="mx-3"
					style={{ fontSize: '18px', lineHeight: '23px', color: '#02475E', fontWeight: '700' }}
				>
					{policyTypeStrings(otherData?.PolicyType)}, {otherData?.id} - {otherData?.ClientName}
				</div>
			</TopNav>

			<Container>{activeSection}</Container>
		</>
	)
}

const StepCard = ({ statusComplete = false, children }) => {
	return (
		<CardWrapper>
			<div className="d-flex flex-column align-items-center">
				<CompletionCircle statusComplete={statusComplete}>
					{statusComplete && <img src={CompletionCheck} alt="check" />}
				</CompletionCircle>
				<CompletionLine statusComplete={statusComplete} />
			</div>
			{children}
		</CardWrapper>
	)
}

const QuoteCard = ({ insurerLogo, premium, quoteId, rightElement }) => {
	return (
		<CardWrapper className="justify-content-between">
			<div className="d-flex align-items-center">
				<QuoteImg src={insurerLogo} className="mx-2" />

				<div className="d-flex flex-column mx-2 px-3 border-start border-end">
					<span style={{ color: '#C8D6DB' }}>Premium</span>
					<p className="p-0" style={{ color: '#02475E', fontSize: '24px' }}>
						{`₹ ${getFormattedAmount(premium)}`}
					</p>
				</div>

				<div className="d-flex flex-column mx-2">
					<span style={{ color: '#C8D6DB' }}>Quote ID</span>
					<p className="p-0" style={{ color: '#02475E', fontSize: '24px' }}>
						{quoteId}
					</p>
				</div>
			</div>

			{rightElement}
		</CardWrapper>
	)
}

// styles
const CustomButton = styled(Button)`
	height: 48px;
	display: flex;
	align-items: center;
	background: ${({ disabled }) => (disabled ? '#C4C4C4 !important' : '#02475E')};
`

const QuoteImg = styled.img`
	height: 60px;
	width: 100px;
	object-fit: contain;
`

// temporary secondary navbar
const TopNav = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
`

// step card

const CardWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 1rem;
	margin-bottom: 2rem;
`

const CompletionCircle = styled.div`
	height: 35px;
	width: 35px;
	border-radius: 50%;
	border: ${({ statusComplete }) => (statusComplete ? 'none' : '4px solid #8d8d8d')};
	margin-right: 1rem;
`

const CompletionLine = styled.div`
	height: 20px;
	border-left: ${({ statusComplete }) =>
		statusComplete ? '4px solid #75CC1F' : '4px solid #ECECEC'};
	margin-right: 1rem;
`

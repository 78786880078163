import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Input, InputGroup, Space, theme, PhoneNoInput, Button, Text } from 'verak-ui'
import { useState, useEffect } from 'react'

import { TopNav } from '../components/SharedContainers'
import UpdateSuccessComponent from './CommonScreenComponents/UpdateSuccessComponent'
import { useFetch } from '../api/apihooks'

import BackArrow from '../assets/icons/BackArrow.svg'

const LeadTransfer = () => {
	const { uploadLeadData } = useFetch()

	const [currentScreen, setCurrentScreen] = useState('LeadDetails')

	const history = useHistory()
	const goBack = () => {
		history.replace('/')
	}

	const [proposerName, setProposerName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [shopName, setShopName] = useState('')
	const [address, setAddress] = useState('')
	const [leadSource, setLeadSource] = useState('')
	const [disableSubmitBtn, setDisable] = useState(true)

	const handlePhoneChange = e => {
		const number = e.target.value
		if ((Number(number) || number === '') && number.length <= 10) {
			setPhoneNumber(number)
		}
	}
	let validPhoneNumber = true
	if (phoneNumber.length > 0) {
		validPhoneNumber = !isNaN(phoneNumber) && phoneNumber.length === 10
	}

	useEffect(() => {
		const isValid = proposerName && validPhoneNumber && leadSource

		setDisable(!isValid)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [proposerName, phoneNumber, leadSource])

	const handleSubmit = async () => {
		const payload = {
			ProposerName: proposerName,
			CompanyName: shopName || proposerName,
			Phone: `+91${phoneNumber}`,
			Email: email,
			Address: address,
			LeadSource: leadSource,
		}

		setDisable(true)
		const result = await uploadLeadData(payload)
		if (result?.error) {
			setDisable(false)
		} else {
			setCurrentScreen('SuccessScreen')
		}
	}

	return currentScreen === 'LeadDetails' ? (
		<>
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<div
					className="mx-3"
					style={{ fontSize: '18px', lineHeight: '23px', color: '#02475E', fontWeight: '700' }}
				>
					Lead Generation Form
				</div>
			</TopNav>
			<MainContainer>
				<CustomInput
					type="text"
					label="Proposer Name"
					labelStyle={labelStyle}
					placeholder="Enter details here"
					compulsory={true}
					onChange={e => setProposerName(e.target.value)}
				/>

				<CustomInput
					type="text"
					label="Legal Entity Name"
					labelStyle={labelStyle}
					placeholder="Enter details here"
					compulsory={true}
					onChange={e => setShopName(e.target.value)}
				/>

				<CustomInput
					type="text"
					label="Lead Source"
					labelStyle={labelStyle}
					placeholder="Enter details here"
					compulsory={true}
					onChange={e => setLeadSource(e.target.value)}
				/>
				<Text style={noteStyle}>
					Eg. "SELF_SOURCED" OR "Customer_Reference" OR "Customer_Reference_[Lead_Source]"
				</Text>
				<InputGroup
					inputId="mobile"
					label="Phone Number"
					isRequired={true}
					className="mb-0.5"
					labelStyle={labelStyle}
				/>
				<PhoneNoInput
					value={phoneNumber}
					placeholder="Enter"
					onChange={handlePhoneChange}
					showAlert={!validPhoneNumber}
					alertText="Incorrect phone number"
					className="mb-2"
					style={{ width: '440px' }}
				/>
				<Space y={2} />

				<CustomInput
					type="text"
					label="Email ID (Optional)"
					labelStyle={labelStyle}
					placeholder="Enter details here"
					onChange={e => setEmail(e.target.value)}
				/>

				<CustomInput
					type="text"
					label="Address (Optional)"
					labelStyle={labelStyle}
					placeholder="Enter details here"
					onChange={e => setAddress(e.target.value)}
				/>

				<Button
					label="Submit details"
					icon="rightArrow"
					iconAlign="right"
					disabled={disableSubmitBtn}
					onClick={handleSubmit}
				/>
			</MainContainer>
		</>
	) : (
		<UpdateSuccessComponent ctaText={'Generate new Lead'} continueButtonLink={'/lead-transfer'} />
	)
}

export default LeadTransfer

const MainContainer = styled.div`
	margin: 1.5rem;
	background: ${theme.colors.white};
	padding: 1rem;
`

const CustomInput = styled(Input)`
	width: 440px;
	margin-bottom: 2rem;
`

const labelStyle = {
	fontSize: '18px',
	color: theme.colors.gray7,
}

const noteStyle = {
	fontSize: '16px',
	color: theme.colors.gray4,
	paddingBottom: '2rem',
	marginTop: '-1.5rem',
}

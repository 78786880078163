const theme = {
	colors: {
		black: '#000000',
		white: '#FFFFFF',
		primary: '#02475E',
		// primary2: '#5999F1',
		// primary3: '#82B3F4',
		// primary4: '#ACCCF8',
		// primary5: '#D5E6FB',
		secondary: '#F78670',
		secondary2: '#F3BDA1',
		secondary3: '#FCEBE3',
		secondary4: '#C8D6DB',
		secondary5: '#FFE9E2',
		tertiary: '#003E90',
		gray: '#4D4D4D',
		gray2: '#6D6D6D',
		gray3: '#8D8D8D',
		gray4: '#C4C4C4',
		gray5: '#C9C9C9',
		gray6: '#ECECEC',
		gray7: '#333333',
		red: '#EB5757',
		secondaryLightRed: '#FADEDE',
		orange: '#F2C94C',
		green: '#219653',
		green2: '#27AE60',
		green3: '#6FCF97',
		green4: '#75CC1F',
		secondaryLightGreen: '#EBFFD7',
		yellow: '#F2994A',
		secondaryLightYellow: '#FDF7E9',
		google: {
			regular: '#1976D2',
			hover: '#45A3FF',
			disabled: '#B0D7FD',
		},
		twitter: {
			regular: '#1DA1F2',
			hover: '#57BFFF',
			disabled: '#9CD9FF',
		},
		email: {
			regular: '#888888',
			hover: '#EB5757',
			disabled: '#C9C9C9',
		},
		facebook: {
			regular: '#3B5998',
			hover: '#2F80ED',
			disabled: '#8F9EBE',
		},
	},
	type: {
		font: {
			base: 'PlusJakartaSans-Regular,-apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
		},
		weights: {
			light: 300,
			regular: 400,
			medium: 500,
			semi_bold: 600,
			bold: 700,
		},
		styles: {
			h1: {
				'font-size': '6rem',
				'letter-spacing': '-1.5px',
				'line-height': '9rem',
				'font-weight': 500,
			},
			h2: {
				'font-size': '3.75rem',
				'letter-spacing': '-0.5px',
				'line-height': '5.6rem',
				'font-weight': 400,
			},
			h3: {
				'font-size': '3rem',
				'letter-spacing': '0px',
				'line-height': '4.5rem',
				'font-weight': 300,
			},
			h4: {
				'font-size': '2.125rem',
				'letter-spacing': '0.25px',
				'line-height': '3.2rem',
				'font-weight': 400,
			},
			h5: {
				'font-size': '1.5rem',
				'letter-spacing': '0px',
				'line-height': '2.3rem',
				'font-weight': 400,
			},
			h6: {
				'font-size': '1.25rem',
				'letter-spacing': '0.25px',
				'line-height': '1.9rem',
				'font-weight': 500,
			},
			body1: {
				'font-size': '1.125rem',
				'letter-spacing': '0.5px',
				'line-height': '1.5rem',
				'font-weight': 400,
			},
			body2: {
				'font-size': '0.875rem',
				'letter-spacing': '0.25px',
				'line-height': '1.3rem',
				'font-weight': 400,
			},
			subtitle1: {
				'font-size': '1rem',
				'letter-spacing': '0.15px',
				'line-height': '1.5rem',
				'font-weight': 400,
			},
			subtitle2: {
				'font-size': '0.875rem',
				'letter-spacing': '0.1px',
				'line-height': '1.3rem',
				'font-weight': 500,
			},
			caption: {
				'font-size': '0.75rem',
				'letter-spacing': '0.4px',
				'line-height': '1.1rem',
				'font-weight': 400,
			},
			overline: {
				'font-size': '0.75rem',
				'letter-spacing': '2px',
				'line-height': '1.1rem',
				'font-weight': 500,
			},
			primary: {
				'font-size': '24px',
				'line-height': '30px',
				'font-weight': 700,
			},
		},
	},
	elevation: {
		'1dp':
			'0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
		'2dp':
			'0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);',
		'3dp':
			'0px 3px 3px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);',
		'4dp':
			'0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2);',
		'6dp':
			'0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);',
		'8dp':
			'0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2);',
		'9dp':
			'0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 5px 6px rgba(0, 0, 0, 0.2);',
		'12dp':
			'0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);',
		'16dp':
			'0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);',
		'24dp':
			'0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);',
		error: '0px 0px 4px #EB5757',
		success: '0px 0px 4px #27AE60;',
	},
}

export default theme

import styled from 'styled-components'
import PropTypes from 'prop-types'
import theme from './theme'

const StyledText = styled.div`
	color: ${({ color }) => (color ? color : 'inherit')};
	display: ${({ display }) => (display ? display : 'block')};
	font-size: ${({ fontSize, type }) =>
		fontSize ? fontSize : theme.type.styles[type]['font-size']};
	font-weight: ${({ fontWeight, type }) =>
		fontWeight ? fontWeight : theme.type.styles[type]['font-weight']};
	letter-spacing: ${({ type }) => theme.type.styles[type]['letter-spacing']};
	@media (max-width: 768px) {
		font-size: ${({ mobileFontSize, type }) =>
			mobileFontSize ? mobileFontSize : theme.type.styles[type]['font-size']} !important;
	}
`

const Text = ({ children, type, display, color, deco, fontSize, mobileFontSize, ...props }) => {
	return (
		<StyledText
			type={type}
			color={color}
			display={display}
			fontSize={fontSize}
			mobileFontSize={mobileFontSize}
			{...props}
		>
			{children}
		</StyledText>
	)
}

export default Text

Text.propTypes = {
	children: PropTypes.node,
	type: PropTypes.oneOf([
		'h1',
		'h2',
		'h3',
		'h4',
		'h5',
		'h6',
		'body1',
		'body2',
		'subtitle1',
		'subtitle2',
		'caption',
		'overline',
		'primary',
	]),
	display: PropTypes.string,
	color: PropTypes.string,
}

Text.defaultProps = {
	type: 'body2',
}

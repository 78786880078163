import { Modal, Button } from 'react-bootstrap'

const ModalComp = ({ show, handleClose, closeButton, children, title, footer, ...props }) => {
	let footerElement
	if (footer) {
		footerElement = footer
	} else {
		footerElement = (
			<Modal.Footer>
				<Button onClick={handleClose}>Close</Button>
			</Modal.Footer>
		)
	}
	return (
		<Modal show={show} onHide={handleClose} {...props}>
			<Modal.Header closeButton={closeButton}>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			{footerElement}
		</Modal>
	)
}

export default ModalComp

import { Icon, Text, theme, Tooltip } from 'verak-ui'
import styled from 'styled-components'

const FilterButton = ({
	filterText,
	selectedOption,
	setFilterValue,
	menuItems,
	disableLeftMargin,
}) => {
	const menuItemsMap = menuItems.map((menuItem, index) => {
		const isLast = index !== menuItems.length - 1

		const clickHandler = () => {
			if (menuItem && selectedOption !== menuItem.value) {
				setFilterValue(menuItem.value)
			}
		}

		return (
			<MenuItemContainer onClick={clickHandler} isLast={isLast} key={`menu-item-${menuItem.text}`}>
				{menuItem.icon && <Icon name={menuItem.icon} className="mx-1" />}
				{menuItem.text}
			</MenuItemContainer>
		)
	})

	return (
		<>
			<FilterButtonWrapper
				contentStyle={{ padding: '0' }}
				html={<MenuContainer>{menuItemsMap}</MenuContainer>}
				theme="light"
				trigger="click"
				position="bottom"
				disableLeftMargin={disableLeftMargin}
				className="d-flex align-items-center flex-column justify-content-center"
			>
				<div className="d-flex justify-content-between">
					<div className="d-flex flex-column">
						<Text color={theme.colors.gray3} fontSize="14px">
							{filterText}
						</Text>
						<div className="d-flex align-items-center">
							<Text color={theme.colors.black} fontWeight="500" fontSize="16px">
								{selectedOption}
							</Text>
							<Icon
								name="rightArrow"
								color={theme.colors.gray3}
								style={{ transform: 'rotate(90deg)' }}
							/>
						</div>
					</div>
				</div>
			</FilterButtonWrapper>
		</>
	)
}

export default FilterButton

// styles

const FilterButtonWrapper = styled(Tooltip)`
	border-radius: 5px;
	padding: 8px;
	cursor: pointer;
	height: 48px;
	margin-left: 10px;
	@media (max-width: 768px) {
		width: 100%;
		margin-left: ${({ disableLeftMargin }) => (disableLeftMargin ? '0px' : '10px')};
	}
`

const MenuItemContainer = styled.li`
	display: flex;
	align-items: center;
	padding: 0.75rem 0.375rem;
	border-bottom: ${props => (props?.isLast ? '0.5px solid #8d8d8d' : 'none')};
	list-style: none;
	background-color: ${({ disabled }) => (disabled ? '#e3e3e3' : '#fff')};
	opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0rem;
	color: #8d8d8d;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

	&:hover {
		background: ${({ disabled }) => (disabled ? '' : ' #e9e9e9')};
	}
`

const MenuContainer = styled.ul`
	min-width: 200px;
	padding: 0;
`

import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router'
import { useFetch } from '../../api/apihooks'
import AddQuoteInfo from './AddQuoteInfo'
import AddQuoteSuccess from './AddQuoteSuccess'

const QuoteHandler = () => {
	const fetch = useFetch()
	const { state: stateLocation } = useLocation()

	const [quoteInfo, setQuoteInfo] = useState({})
	const [quoteLoading, setQuoteLoading] = useState(true)

	useEffect(() => {
		const quoteId = stateLocation?.quote?.id || ''
		async function getQuoteInfo() {
			setQuoteLoading(true)
			const quote = await fetch.getQuote(quoteId)
			setQuoteInfo(quote)
			setQuoteLoading(false)
		}
		if (quoteId) {
			getQuoteInfo()
		} else {
			setQuoteLoading(false)
		}
	}, [])

	const history = useHistory()

	const [currentPage, setCurrentPage] = useState('AddQuoteInfo')

	const displaySuccess = () => setCurrentPage('AddQuoteSuccess')
	const navigateToHome = () => history.replace('/quotes')
	let renderCurrentPage = null
	if (quoteLoading) {
		renderCurrentPage = (
			<div className="m-auto mt-5 d-flex justify-content-center">
				<Spinner animation="border" role="status" />
			</div>
		)
	} else if (currentPage === 'AddQuoteInfo') {
		renderCurrentPage = <AddQuoteInfo quote={quoteInfo} onNavigate={displaySuccess} />
	} else if (currentPage === 'AddQuoteSuccess') {
		renderCurrentPage = <AddQuoteSuccess onNavigate={navigateToHome} />
	}

	return renderCurrentPage
}

export default QuoteHandler

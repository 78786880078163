import styled from 'styled-components'
import { useRef, useState } from 'react'
import { HiddenFileInput, Button } from 'verak-ui'
import Swal from 'sweetalert2'

import { useFetch } from '../../api/apihooks'

import PlusIconOrange from '../../assets/icons/plusIconOrange.svg'

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: toast => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})

export default function UploadOvr() {
	const [file, setFile] = useState()
	const { uploadOvr } = useFetch()
	const bondFileInput = useRef()

	const handleChange = e => {
		setFile(e.target.files[0])
	}

	const handleSubmit = async e => {
		e.preventDefault()
		if (!file) {
			Toast.fire({
				text: 'Please select a file to submit.',
				icon: 'error',
			})
			return
		}
		const formData = new FormData()
		formData.append('ovr', file)
		const result = await uploadOvr(formData)
		if (result?.error) {
			Toast.fire({
				text: 'Unsuccessful',
				icon: 'error',
			})
		} else {
			Toast.fire({
				text: 'Successfully submitted',
				icon: 'success',
			})
		}
	}

	return (
		<div className="container p-3">
			<h3>Please Upload the OVR</h3>
			<div>
				<HiddenFileInput
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					ref={bondFileInput}
					onChange={handleChange}
				/>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<CustomUploadButton onClick={() => bondFileInput.current.click()}>
						<img src={PlusIconOrange} alt="Upload" />
					</CustomUploadButton>
					<div className="container p-3">{file?.name || ''}</div>
					<Button label="Submit" icon="rightArrow" iconAlign="right" onClick={handleSubmit} />
				</div>
			</div>
		</div>
	)
}

const CustomUploadButton = styled.button`
	background-color: #fff;
	width: 350px;
	height: 48px;
	margin-top: 1rem;
	border: 2px dashed #8d8d8d;
	border-radius: 8px;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

import { useContext, useRef } from 'react'
// import { postReq } from "./API";
import { Context } from '../context/Provider'
import { useAuth0 } from '@auth0/auth0-react'
import { useLoadingOverlay } from '../context/LoadingErrorProvider'
import { toBase64, convertBase64ToBlob } from '../utils/imageFunctions'

const url = process.env.REACT_APP_SERVER_URL
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE

export const useFetch = () => {
	const { getAccessTokenSilently } = useAuth0()
	const { setLoading, setError } = useLoadingOverlay()

	const [state] = useContext(Context)

	const stateRef = useRef()
	stateRef.current = state

	const handleResponse = response => {
		setLoading({ status: false, loadingText: '' })
		const responseJSON = response.json()

		if (!response.ok) {
			setError({
				status: true,
				errorMessage: 'Error: ' + (responseJSON.error ?? response.statusText),
				errorCode: response?.status || '',
			})
		} else {
		}

		return responseJSON
	}

	const sendRequest = async (
		endPoint,
		type = 'POST',
		body = null,
		showLoader = true,
		loadingText = 'Loading ...'
	) => {
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})
		showLoader && setLoading({ status: true, loadingText: loadingText })

		const result = await fetch(`${url}/${endPoint}`, {
			method: type,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: body ? JSON.stringify(body) : null,
		}).then(handleResponse)

		return result
	}

	const getRfqList = async (cursor, filterBy, showLoader = true) => {
		let queryParams = new URLSearchParams()
		if (cursor) queryParams.append('cursor', cursor)
		if (filterBy) queryParams.append('status', filterBy)

		queryParams = queryParams.toString()
		const queryString = queryParams ? `?${queryParams}` : ''

		const result = await sendRequest(`ops/rfqs/${queryString}`, 'GET', null, showLoader)
		return result
	}

	const searchRfqByQuery = async searchQuery => {
		const result = await sendRequest(`ops/rfqs?search=${searchQuery.trim()}`, 'GET', null, true)
		return result
	}

	const getQuoteList = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`ops/quotes/${rfqId}`, 'GET')
		return result
	}

	const getSumInsuredBreakdown = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`ops/rfq/si-breakdown/${rfqId}`, 'GET')
		return result
	}

	const createQuote = async (rfqId, quoteData) => {
		if (!rfqId) {
			return null
		}
		const payload = {
			rfqId: rfqId,
			data: quoteData,
		}
		const result = await sendRequest(`ops/quote`, 'POST', payload)
		return result
	}

	const updateQuote = async (quoteId, quoteData) => {
		if (!quoteId) {
			return null
		}
		const result = await sendRequest(`ops/quote/${quoteId}`, 'PUT', quoteData)
		return result
	}

	const sendRFQToInsurer = async (rfqId, payload) => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`ops/rfq/send/${rfqId}`, 'POST', payload)
		return result
	}

	const updateRFQStatus = async (rfqId, status) => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`ops/rfq/${rfqId}`, 'PUT', {
			Status: status,
		})
		return result
	}

	const getQuote = async quoteId => {
		if (!quoteId) {
			return null
		}
		const result = await sendRequest(`ops/quote/${quoteId}`, 'GET')
		return result
	}

	const downloadRFQArchive = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`ops/rfq/archive/${rfqId}`, 'GET')
		return result
	}

	const downloadRfqPdf = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`sqs/rfq/pdf/${rfqId}`, 'GET')
		return result
	}

	const initiateAndAssignRfq = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`ops/rfq/assign/${rfqId}`, 'PUT', {})
		return result
	}

	const getAddons = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`ops/rfq/addons/${rfqId}`, 'GET')
		return result
	}

	const getRfqFiles = async (rfqId, loadingText) => {
		const result = await sendRequest(`ops/rfq/files/${rfqId}`, 'GET', null, true, loadingText)
		return result
	}

	//  get signed url to upload image,pdf
	const getSignedUrl = async (rfqId, mimeType = 'application/pdf') => {
		const result = await sendRequest(`fire/uploadUrl/${rfqId}?contentType=${mimeType}`, 'GET')
		return result
	}

	//  upload blob to the signed url
	const uploadBlobToSignedUrl = async (link, file) => {
		const base64String = await toBase64(file)
		const blobContent = await convertBase64ToBlob(base64String)

		const result = await fetch(link, {
			method: 'PUT',
			body: blobContent,
		})

		return result
	}

	const uploadClientPolicy = async (rfqId, payload) => {
		const result = await sendRequest(`ops/policy/${rfqId}`, 'POST', payload)
		return result
	}

	const generatePdf = async (rfqId, template, loadingText) => {
		const result = await sendRequest(
			`ops/rfqs/${rfqId}/generate-pdf/${template}`,
			'POST',
			null,
			true,
			loadingText
		)
		return result
	}

	const getCustomerInfo = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`ops/rfqs/${rfqId}/customer-info`, 'GET')
		return result
	}

	const updateCustomerInfo = async (rfqId, updatedDetails) => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`ops/rfqs/${rfqId}/customer-info`, 'PUT', updatedDetails)
		return result
	}

	const settlePayment = async (rfqId, utrNo) => {
		if (!rfqId) {
			return null
		}

		const result = await sendRequest(`ops/rfqs/${rfqId}/settle`, 'POST', {
			utr: utrNo,
		})
		return result
	}

	const uploadLeadData = async payload => {
		const result = await sendRequest(`ops/lead`, 'POST', payload)
		return result
	}

	const sendPaymentConfirmation = async rfqId => {
		const result = await sendRequest(`ops/rfqs/${rfqId}/payment-confirmation`, 'POST')
		return result
	}

	const uploadOvr = async body => {
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})
		setLoading({ status: true, loadingText: 'Loading...' })

		const result = await fetch(`${url}/ops/store-call-logs`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: body,
		}).then(handleResponse)

		return result
	}

	return {
		sendRequest,
		getRfqList,
		searchRfqByQuery,
		getQuoteList,
		createQuote,
		updateQuote,
		getQuote,
		sendRFQToInsurer,
		updateRFQStatus,
		downloadRFQArchive,
		downloadRfqPdf,
		initiateAndAssignRfq,
		getAddons,
		getRfqFiles,
		getSignedUrl,
		uploadBlobToSignedUrl,
		uploadClientPolicy,
		generatePdf,
		getSumInsuredBreakdown,
		uploadLeadData,
		getCustomerInfo,
		updateCustomerInfo,
		settlePayment,
		sendPaymentConfirmation,
		uploadOvr,
	}
}

import styled from 'styled-components'

// temporary secondary navbar
export const TopNav = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
	height: 80px;
`
